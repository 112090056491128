import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import "./Events.css";
import moment from "moment";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import { useSelector } from "react-redux";

const SingleEvent = (props) => {
  const { eventDetail } = useParams();
  const [event, setEvent] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [isOperationMember, setIsOperationMember] = useState(false);
  const [currentUser, setCurrentUser] = useState([]);
  const [currentUserRegistration, setCurrentUserRegistration] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [openDraw, setOpenDraw] = useState(false);
  const [openDrawDetails, setOpenDrawDetails] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [opsAccess, setOpsAccess] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isCertificateAvail, setIsCertificateAvail] = useState(false);
  const [isRegisteredFinal, setIsRegisteredFinal] = useState(false);
  const [isPassportAvailible, setIsPassportAvailible] = useState(false);
  const [isVisaAvailible, setIsVisaAvailible] = useState(false);
  const [Arrival_flight_date, setArrival_flight_date] = useState(null);
  const [Arrival_flight_timeOnly, setArrival_flight_timeOnly] = useState(null);
  const [Departure_flight_date, setDeparture_flight_date] = useState(null);
  const [Departure_flight_timeOnly, setDeparture_flight_timeOnly] =
    useState(null);
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  useEffect(() => {
    if(localStorage.role){
    if(localStorage.role=="Operations Member"){
      setIsOperationMember(true)
    }
  }
  }, []);
  

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        setLoading(true);
        const result = await axios
          .get(
            "https://dash.bestdiplomats.org/api/resource/Events/" + eventDetail
          )
          .then((response) => {
            setEvent(response.data.data);
            response.data.data?.access_role.forEach(element => {
              if(element.role==localStorage.role){
                setOpsAccess(true);
              }
            });
            if(new Date().toISOString().split('T')[0] > response.data.data.end_date){
              isCertificateAvail(true);
            }
            // console.log(response.data.data);
          });
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    fetchEventData();
  }, []);
  // Get Event Registration for Letter and Hotel Pass

  useEffect(() => {
    if (user == true) {
      setIsLoggedIn(true);
    }
  }, [user]);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        setLoading(true);
        const result = await axios
          .get(
            "https://dash.bestdiplomats.org/api/method/get-delegate?email=" +
              userEmail,
            config
          )
          .then((responseUser) => {
            setCurrentUser(responseUser.data);

            const checkRegisteration = async () => {
              try {
                const result = await axios
                  .get(
                    `https://dash.bestdiplomats.org/api/resource/Registration Application?filters=[["event","=","${eventDetail}"],["user","=","${responseUser?.data?.data?.user?.name}"]]`,
                    config
                  )
                  .then((response) => {
                    if (response.data.data.length > 0) {
                      setIsRegistered(true);
                    }
                  });
              } catch (e) {
                console.log(e);
              }
            };
            checkRegisteration();
            const fetchEventRegisteration = async () => {
              try {
                setLoading(true);
                const result = await axios
                  .get(
                    `https://dash.bestdiplomats.org/api/method/get-registration?event=${eventDetail}&delegate=${responseUser?.data?.data?.name}`,
                    config
                  )
                  .then((response) => {
                    // console.log(response.data.data);
                    setCurrentUserRegistration(response.data);
                    if(!!response.data.data.flight_scan){
                      setIsPassportAvailible(true);
                    }
                    if(!!response.data.data.visa_scan){
                      setIsVisaAvailible(true);
                    }
                    
                    if (response.data.data.flight_arrival_time != null) {
                      var splitArray =
                        response.data.data.flight_arrival_time.split(" ");
                      setArrival_flight_date(splitArray[0]);
                      setArrival_flight_timeOnly(splitArray[1]);
                    }

                    if (response.data.data.flight_departure_time != null) {
                      var splitArrayDep =
                        response.data.data.flight_departure_time.split(" ");
                      setDeparture_flight_date(splitArrayDep[0]);
                      setDeparture_flight_timeOnly(splitArrayDep[1]);
                    }
                    if (response.data.error == "Not found") {
                      setIsRegisteredFinal(false);
                    } else {
                      setIsRegisteredFinal(true);
                    }
                  });
                setLoading(false);
              } catch (e) {
                console.log(e);
              }
            };
            fetchEventRegisteration();
          });
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    fetchEventData();
  }, []);
  
  // Flight Ticket Document Upload
  function uploadTicketDocument(e) {
    
    const fileTicket = e.target.files[0];
    const formData = new FormData();
    formData.append("file", fileTicket);
    formData.append("doctype", "Event Registration");
    formData.append("docname", currentUserRegistration?.data?.name);
    const uploadFlightApi = async () => {
      try {
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData,
            config
          )
          .then((responseFlight) => {
            var updateJSONFlight = {
              flight_scan: responseFlight.data.message.file_url,
            };

            const updateUserDataFlight = async () => {
              try {
                setLoading(true);
                await axios
                  .put(
                    "https://dash.bestdiplomats.org/api/resource/Event Registration/" +
                      currentUserRegistration.data.name,
                    updateJSONFlight,
                    config
                  )
                  .then((response) => {
                    // console.log(response.data);
                    setCurrentUserRegistration(response.data);
                  });
                setLoading(false);
              } catch (e) {
                console.log(e);
              }
            };

            updateUserDataFlight();
          });
      } catch (e) {
        console.log(e);
      }
    };

    uploadFlightApi();
  }
  // Visa Document Upload
  function uploadVisaDocument(e) {
    
    const fileVisa = e.target.files[0];
    const formData = new FormData();
    formData.append("file", fileVisa);
    formData.append("doctype", "Event Registration");
    formData.append("docname", currentUserRegistration?.data?.name);
    const uploadFlightApi = async () => {
      try {
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData,
            config
          )
          .then((responseVisa) => {
            var updateJSONVisa = {
              visa_scan: responseVisa.data.message.file_url,
            };

            const updateUserDataVisa = async () => {
              try {
                setLoading(true);
                await axios
                  .put(
                    "https://dash.bestdiplomats.org/api/resource/Event Registration/" +
                      currentUserRegistration.data.name,
                    updateJSONVisa,
                    config
                  )
                  .then((response) => {
                    // console.log(response.data);
                    setCurrentUserRegistration(response.data);
                  });
                setLoading(false);
              } catch (e) {
                console.log(e);
              }
            };

            updateUserDataVisa();
          });
      } catch (e) {
        console.log(e);
      }
    };

    uploadFlightApi();
  }

  //  Formik Configration
  const updateUserInfo = useFormik({
    initialValues: {
      first_name: currentUser?.data?.first_name,
      last_name: currentUser?.data?.last_name,
    },
    onSubmit: (values) => {
      var JSONregisteration = {
        event: eventDetail,
        user: currentUser?.data.name,
        food_preference: values.food_preference,
        other_food_preference: values?.other_food_preference,
        tshirt_size: values.tshirt_size,
        level_of_english_proficiency: values.level_of_english_proficiency,
        how_get_known: values.how_get_known,
        other_how_get_known: values?.other_how_get_known,
        name_of_ambassador: values.name_of_ambassador,
        who_is_paying: values.who_is_paying,
        other_who_is_paying: values?.other_who_is_paying,
        conference_package: values.conference_package,
        past_experience: values.past_experience,
        why_attend: values.why_attend,
        gain_experience: values.gain_experience,
        representing_country: values.representing,
      };
      // console.log(JSONregisteration);
      const registerApplication = async () => {
        try {
          setLoadingRegister(true);
          await axios
            .post(
              "https://dash.bestdiplomats.org/api/resource/Registration Application",
              JSONregisteration,
              config
            )
            .then((response) => {
              // console.log(response.data);
            });
          setLoadingRegister(false);
        } catch (e) {
          console.log(e);
        }
      };

      registerApplication();
    },
    enableReinitialize: true,
  });
  // End Formik Configration

  //  Formik Configration
  const updateDelegateEventDetail = useFormik({
    initialValues: {
      arrival_flight_date: Arrival_flight_date,
      arrival_flight_timeOnly: Arrival_flight_timeOnly,
      arrival_flight_code: currentUserRegistration?.data?.arrival_flight_code,
      departure_flight_date: Departure_flight_date,
      departure_flight_timeOnly: Departure_flight_timeOnly,
      departure_flight_code:
        currentUserRegistration?.data?.departure_flight_code,
      food_preference: currentUserRegistration?.data?.food_preference,
      tshirt_size: currentUserRegistration?.data?.tshirt_size,
    },
    onSubmit: (values) => {
      if (
        values.arrival_flight_date == null ||
        values.arrival_flight_timeOnly == null ||
        values.arrival_flight_date == "" ||
        values.arrival_flight_timeOnly == ""
      ) {
        if (
          values.departure_flight_date == null ||
          values.departure_flight_timeOnly == null ||
          values.departure_flight_date == "" ||
          values.departure_flight_timeOnly == ""
        ) {
          var updateERJSON = {
            flight_arrival_time: null,
            flight_departure_time: null,
            arrival_flight_code: values.arrival_flight_code,
            departure_flight_code: values.departure_flight_code,
            food_preference: values.food_preference,
            tshirt_size: values.tshirt_size,
          };

          const updateRegisteration = async () => {
            try {
              setLoadingRegister(true);
              await axios
                .put(
                  "https://dash.bestdiplomats.org/api/resource/Event Registration/" +
                    currentUserRegistration.data.name,
                  updateERJSON,
                  config
                )
                .then((response) => {
                  setIsSaved(true)
                  setTimeout(() => {
                    setIsSaved(false)
                  }, 2000);
                  // console.log(response.data)
                });
              setLoadingRegister(false);
            } catch (e) {
              console.log(e);
            }
          };

          updateRegisteration();
        } else {
          var updateERJSON = {
            flight_arrival_time: null,
            flight_departure_time:
              values.departure_flight_date +
              " " +
              values.departure_flight_timeOnly,
            arrival_flight_code: values.arrival_flight_code,
            departure_flight_code: values.departure_flight_code,
            food_preference: values.food_preference,
            tshirt_size: values.tshirt_size,
          };

          const updateRegisteration = async () => {
            try {
              setLoadingRegister(true);
              await axios
                .put(
                  "https://dash.bestdiplomats.org/api/resource/Event Registration/" +
                    currentUserRegistration.data.name,
                  updateERJSON,
                  config
                )
                .then((response) => {
                  setIsSaved(true)
                  setTimeout(() => {
                    setIsSaved(false)
                  }, 2000);
                });
              setLoadingRegister(false);
            } catch (e) {
              console.log(e);
            }
          };

          updateRegisteration();
        }
      } else {
        if (
          values.departure_flight_date == null ||
          values.departure_flight_timeOnly == null ||
          values.departure_flight_date == "" ||
          values.departure_flight_timeOnly == ""
        ) {
          if (
            values.arrival_flight_date == null ||
            values.arrival_flight_timeOnly == null ||
            values.arrival_flight_date == "" ||
            values.arrival_flight_timeOnly == ""
          ) {
            var updateERJSON = {
              flight_arrival_time: null,
              flight_departure_time: null,
              arrival_flight_code: values.arrival_flight_code,
              departure_flight_code: values.departure_flight_code,
              food_preference: values.food_preference,
              tshirt_size: values.tshirt_size,
            };

            const updateRegisteration = async () => {
              try {
                setLoadingRegister(true);
                await axios
                  .put(
                    "https://dash.bestdiplomats.org/api/resource/Event Registration/" +
                      currentUserRegistration.data.name,
                    updateERJSON,
                    config
                  )
                  .then((response) => {
                    setIsSaved(true)
                  setTimeout(() => {
                    setIsSaved(false)
                  }, 2000);
                  });
                setLoadingRegister(false);
              } catch (e) {
                console.log(e);
              }
            };

            updateRegisteration();
          } else {
            var updateERJSON = {
              flight_arrival_time:
                values.arrival_flight_date +
                " " +
                values.arrival_flight_timeOnly,
                flight_departure_time: null,
              arrival_flight_code: values.arrival_flight_code,
              departure_flight_code: values.departure_flight_code,
              food_preference: values.food_preference,
              tshirt_size: values.tshirt_size,
            };

            const updateRegisteration = async () => {
              try {
                setLoadingRegister(true);
                await axios
                  .put(
                    "https://dash.bestdiplomats.org/api/resource/Event Registration/" +
                      currentUserRegistration.data.name,
                    updateERJSON,
                    config
                  )
                  .then((response) => {
                    setIsSaved(true)
                  setTimeout(() => {
                    setIsSaved(false)
                  }, 2000);
                  });
                setLoadingRegister(false);
              } catch (e) {
                console.log(e);
              }
            };

            updateRegisteration();
          }
        } else {
          if (
            values.arrival_flight_date == null ||
            values.arrival_flight_timeOnly == null ||
            values.arrival_flight_date == "" ||
            values.arrival_flight_timeOnly == ""
          ) {
            var updateERJSON = {
              flight_arrival_time: null,
              flight_departure_time:
                values.departure_flight_date +
                " " +
                values.departure_flight_timeOnly,
              arrival_flight_code: values.arrival_flight_code,
              departure_flight_code: values.departure_flight_code,
              food_preference: values.food_preference,
              tshirt_size: values.tshirt_size,
            };

            const updateRegisteration = async () => {
              try {
                setLoadingRegister(true);
                await axios
                  .put(
                    "https://dash.bestdiplomats.org/api/resource/Event Registration/" +
                      currentUserRegistration.data.name,
                    updateERJSON,
                    config
                  )
                  .then((response) => {
                    setIsSaved(true)
                  setTimeout(() => {
                    setIsSaved(false)
                  }, 2000);
                  });
                setLoadingRegister(false);
              } catch (e) {
                console.log(e);
              }
            };

            updateRegisteration();
          } else {
            var updateERJSON = {
              flight_arrival_time:
                values.arrival_flight_date +
                " " +
                values.arrival_flight_timeOnly,
                flight_departure_time:
                values.departure_flight_date +
                " " +
                values.departure_flight_timeOnly,
              arrival_flight_code: values.arrival_flight_code,
              departure_flight_code: values.departure_flight_code,
              food_preference: values.food_preference,
              tshirt_size: values.tshirt_size,
            };

            const updateRegisteration = async () => {
              try {
                setLoadingRegister(true);
                await axios
                  .put(
                    "https://dash.bestdiplomats.org/api/resource/Event Registration/" +
                      currentUserRegistration.data.name,
                    updateERJSON,
                    config
                  )
                  .then((response) => {
                    setIsSaved(true)
                  setTimeout(() => {
                    setIsSaved(false)
                  }, 2000);
                  });
                setLoadingRegister(false);
              } catch (e) {
                console.log(e);
              }
            };

            updateRegisteration();
          }
        }
      }
    },
    enableReinitialize: true,
  });

  const [FlightFileAval, setFlightFileAval] = useState(false);
  const [VisaFileAval, setVisaFileAval] = useState(false);
  const handleFlightDownload = () => {
    var flighturl="https://dash.bestdiplomats.org/api/method/frappe.core.doctype.file.file.download_file?file_url="+currentUserRegistration?.data?.flight_scan
    if(flighturl=="https://dash.bestdiplomats.org/api/method/frappe.core.doctype.file.file.download_file?file_url="){
      setFlightFileAval(true)
      setTimeout(() => {
        setFlightFileAval(false)
      }, 2000);
    }else{
      window.open("https://dash.bestdiplomats.org/api/method/frappe.core.doctype.file.file.download_file?file_url="+currentUserRegistration?.data?.flight_scan);
    }
    
  };
  const handleVisaDownload = () => {
    var Visaurl="https://dash.bestdiplomats.org/api/method/frappe.core.doctype.file.file.download_file?file_url="+currentUserRegistration?.data?.visa_scan
    if(Visaurl=="https://dash.bestdiplomats.org/api/method/frappe.core.doctype.file.file.download_file?file_url="){
      setVisaFileAval(true)
      setTimeout(() => {
        setVisaFileAval(false)
      }, 2000);
    }else{
    window.open("https://dash.bestdiplomats.org/api/method/frappe.core.doctype.file.file.download_file?file_url="+currentUserRegistration?.data?.visa_scan);
  }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [open, setOpen] = useState(1);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  const [openAnn, setOpenAnn] = useState(1);

  const handleOpenAnn = (value) => {
    setOpenAnn(openAnn === value ? 0 : value);
  };

  return (
    <>
      <div className="bg-white">
        <div className="pt-0">
          {/* Image gallery */}
          <div className="mx-auto mt-6 sm:px-6 h-auto lg:px-8">
            {loading ? (
              <div
                role="status"
                className="animate-pulse w-full md:w-12/12 mb-6 md:mb-0 md:p-3"
              >
                <div className="flex justify-center items-center mb-4 h-48 bg-gray-100 rounded ">
                  <svg
                    className="w-12 h-12 text-gray-200 dark:text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 640 512"
                  >
                    <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                  </svg>
                </div>
              </div>
            ) : (
              <div className="SingleEventPagePicCenter fill h-96 overflow-hidden rounded-lg lg:block">
                <img
                  src={window.$domain + event.main_picture}
                  alt="Anything"
                  className=""
                />
              </div>
            )}
          </div>

          {/* Event info */}
          <div className="mx-auto max-w-2xl px-4 pt-10 pb-16 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pt-16 lg:pb-24">
            <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
              {/* ---------Status Start--------- */}
              {loading ? (
                <div
                  role="status"
                  className="animate-pulse w-full md:w-4/12 mb-6 md:mb-0 md:p-3"
                >
                  <div className="h-8 w-16 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                  <div className="h-4 mt-6 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                </div>
              ) : (
                <>
                  <span
                    type="button"
                    class="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    {event.status}
                  </span>
                  {/* ---------Status End--------- */}
                  {/* ---------Title Start--------- */}

                  <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl pt-6">
                    {event.title}
                  </h1>
                  {/* ---------Title End--------- */}
                </>
              )}
            </div>

            <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pt-6 lg:pb-16 lg:pr-8">
              {/* Description and details */}
              {loading ? (
                <div
                  role="status"
                  className="animate-pulse w-full md:w-12/12 mb-6 md:mb-0 md:p-3"
                >
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                  <div className="h-2 mt-2 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                  <div className="h-2 mt-2 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                  <div className="h-2 mt-2 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                  {/* Venue Skeleton */}
                  <div className="h-4 mt-8 w-16 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                  <div className="h-2 mt-2 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                  <div className="h-2 mt-2 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                  {/* Timing Skeleton */}
                  <div className="h-4 mt-8 w-16 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                  <div className="h-2 mt-2 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                  <div className="h-2 mt-2 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                </div>
              ) : (
                <>
                  <div>
                    <h3 className="sr-only">Description</h3>

                    <div className="space-y-6">
                      <p className="text-base text-gray-900">
                        {event.description}
                      </p>
                    </div>
                  </div>

                  <div className="mt-10">
                    <h3 className="text-xl font-bold text-black-900">Venue</h3>
                    <h3 className="text-lg pt-2 font-medium text-gray-900">
                      {event.venue}
                    </h3>

                    <div className="mt-4">
                      <p className="text-sm text-gray-600">
                        {event.venue_details}
                      </p>
                    </div>
                  </div>

                  <div className="mt-10">
                    <h2 className="text-xl font-bold text-black-900">Dates</h2>

                    <div className="mt-4 space-y-6">
                      <p className="text-sm text-gray-600">
                        Starts: {moment(event.start_date).format("LL")}
                      </p>
                      <p className="text-sm text-gray-600">
                        Ends: {moment(event.end_date).format("LL")}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* Options */}
            <div className="mt-4 lg:row-span-3 lg:mt-0">
              {loading ? (
                <div
                  role="status"
                  className="animate-pulse w-full md:w-12/12 mb-6 md:mb-0 md:p-3"
                >
                  {/* Rundown Skeleton */}
                  <div className="h-4 mt-8 w-16 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                  <div className="h-2 mt-2 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                  <div className="h-2 mt-2 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                  {/* Announcement Skeleton */}
                  <div className="h-4 mt-8 w-16 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                  <div className="h-2 mt-2 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                  <div className="h-2 mt-2 bg-gray-200 rounded-full dark:bg-gray-300"></div>
                </div>
              ) : (
                <>
                  {isLoggedIn && opsAccess ?  localStorage.role === "Chat Department" || localStorage.role === "Communication Department" || localStorage.role === "Academics Department" ?(<Link to={"/OperationsDashboard/" + eventDetail+"/chat-request/"+eventDetail}>
                          <button
                            style={{ "background-color": "#4591DC" }}
                            className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent color py-3 px-8 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Operations Portal
                          </button>
                        </Link>):(<Link to={"/OperationsDashboard/" + eventDetail+"/registration-navbar/"+eventDetail+"/all-registrations/"+eventDetail}>
                          <button
                            style={{ "background-color": "#4591DC" }}
                            className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent color py-3 px-8 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Operations Portal
                          </button>
                        </Link>):(isRegisteredFinal ? (
                      <>
                        <button
                          onClick={() => {
                            setOpenDrawDetails(true);
                          }}
                          style={{ "background-color": "#4591DC" }}
                          className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent color py-3 px-8 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Fill Required Event Details
                        </button>
                        <Link to={"/RoomSharing/" + eventDetail +"/"+currentUserRegistration.data.delegate_profile.name}>
                          <button className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent color py-3 px-8 text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            Room Sharing
                          </button>
                        </Link>
                        <Link to={"/InvitationLetter/" + eventDetail}>
                          <button className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent color py-3 px-8 text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            Download Invitation Letter
                          </button>
                        </Link>
                        <Link
                          to={
                            "/IdCard/" +
                            currentUser?.data.name +
                            "-" +
                            eventDetail
                          }
                        >
                          <button
                            style={{ "background-color": "#4591DC" }}
                            className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent color py-3 px-8 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Download ID Card
                          </button>
                        </Link>
                        <Link to={"/HotelVoucher/" + eventDetail}>
                          <button
                            style={{ "background-color": "#4591DC" }}
                            className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent color py-3 px-8 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Download Hotel Pass
                          </button>
                        </Link>
                        {isCertificateAvail ? (<Link to={"/Certificate/" + eventDetail+"/"+currentUserRegistration.data.delegate_profile.name}>
                          <button
                            style={{ "background-color": "#4591DC" }}
                            className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent color py-3 px-8 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Download Certificate
                          </button>
                        </Link>):(
                          <button
                            
                            className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-gray-700 py-3 px-8 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                          >
                            Download Certificate (After Event)
                          </button>
                        )}
                        
                      </>
                    ) : localStorage.role=="Best Diplomats Support Team" ? (<Link to={"/ticket-details/" + eventDetail}>
                          <button
                            style={{ "background-color": "#4591DC" }}
                            className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent color py-3 px-8 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Support Dashboard
                          </button>
                        </Link>):localStorage.role === "Self Checkin Hotel Access"?(<Link to={"/OperationsDashboard/" + eventDetail+"/self-checkin-hotel-access/"+eventDetail}>
                          <button
                            style={{ "background-color": "#4591DC" }}
                            className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent color py-3 px-8 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Operations Portal
                          </button>
                        </Link>):localStorage.role === "Bag Allocation"&& event.status==="Active"?<Link to={"/OperationsDashboard/" + eventDetail+"/assign-bag-for-checkin/"+eventDetail}>
                          <button
                            style={{ "background-color": "#4591DC" }}
                            className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent color py-3 px-8 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Operations Portal
                          </button>
                        </Link>:"" )
                    }

                  <h2 className="sr-only">Rundown Information</h2>
                  <p className="text-3xl mt-4 tracking-tight text-gray-900">
                    Rundown
                  </p>
                  {/* Rundown */}

                  <div className="pt-6">
                    <Fragment>
                      <div className="border-blue-gray-50 grid min-h-[120px] w-full scroll-mt-48 overflow-x-scroll rounded-lg border bg-[#f8fafc] p-6 lg:overflow-visible">
                        {event.rundown?.map((run, key) => (
                          <Accordion open={open === key + 1} key={run.idx}>
                            <AccordionHeader
                              onClick={() => handleOpen(key + 1)}
                              className="flex justify-between items-center w-full py-2 border-b border-b-blue-gray-100 antialiased font-sans text-xl font-semibold leading-snug select-none hover:text-blue-gray-900 transition-colors text-blue-gray-900"
                            >
                              {"Day " + (key + 1)}
                            </AccordionHeader>
                            <AccordionBody>
                              <h1 className="font-sans text-xl font-semibold">
                                {run.title}
                              </h1>
                              Activity Date:{" "}
                              {moment(run.date_of_activity).format("LL")}
                              <br></br>
                              <br></br>
                              {run.description}
                            </AccordionBody>
                          </Accordion>
                        ))}
                      </div>
                    </Fragment>
                  </div>
                  {/* Announcements */}
                  <h2 className="sr-only">Announcements</h2>
                  <p className="text-3xl tracking-tight text-gray-900 pt-6">
                    Announcements
                  </p>

                  {/* Announcements */}

                  <div className="pt-6">
                    <Fragment>
                      <div className="border-blue-gray-50 grid min-h-[120px] w-full scroll-mt-48 overflow-x-scroll rounded-lg border bg-[#f8fafc] p-6 lg:overflow-visible">
                        {event.announcements?.map((ann, key) => (
                          <Accordion open={openAnn === key + 1} key={ann.idx}>
                            <AccordionHeader
                              onClick={() => handleOpenAnn(key + 1)}
                              className="flex justify-between items-center w-full py-2 border-b border-b-blue-gray-100 antialiased font-sans text-xl font-semibold leading-snug select-none hover:text-blue-gray-900 transition-colors text-blue-gray-900"
                            >
                              {"Announcement " + (key + 1)}
                            </AccordionHeader>
                            <AccordionBody>
                              <h1 className="font-sans text-xl font-semibold">
                                {ann.title}
                              </h1>
                              Message: {ann.message}
                              <br></br>
                              {ann.description}
                            </AccordionBody>
                          </Accordion>
                        ))}
                      </div>
                    </Fragment>
                  </div>
                  {/* {isLoggedIn ? (
                    isRegistered ? (
                      <button
                        className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-gray-500 py-3 px-8 text-base font-medium text-white hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                      >
                        Already Registered
                      </button>
                    ) : (
                      isRegisteredFinal ? (null):(<button
                        onClick={() => {
                          setOpenDraw(true);
                        }}
                        className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent color py-3 px-8 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Register
                      </button>)
                      
                    )
                  ) : (
                    <>
                      <Link to="/logincomp">
                        <button className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent color py-3 px-8 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          Login/Signup to Register
                        </button>
                      </Link>
                    </>
                  )} */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Drawer to Regiter in an Event */}
      <Transition.Root show={openDraw} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpenDraw}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form
                      className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                      onSubmit={updateUserInfo.handleSubmit}
                    >
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-100 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg font-medium text-gray-900">
                                {"Register for " + event.title}
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                Get started..
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={() => setOpenDraw(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Divider container */}
                        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                          {/* Profile Pic */}
                          <div className="divide-y divide-gray-200">
                            <div className="pb-6">
                              <div className="h-10 bg-gray-100 sm:h-10 lg:h-10" />
                              <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                                <div>
                                  {/* <div className="-m-1 flex">
                                    <div className="inline-flex overflow-hidden rounded-lg border-0 border-white">
                                      {ProfilePictureAvatar ? (
                                        loading ? (
                                          <svg
                                            fill="#87CEEB"
                                            viewBox="0 0 1792 1792"
                                            className="h-8 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48 animate-spin"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z" />
                                          </svg>
                                        ) : (
                                          <img
                                            className="h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"
                                            src={
                                              window.$domain +
                                              "/" +
                                              ProfilePictureAvatar
                                            }
                                            alt=""
                                          />
                                        )
                                      ) : (
                                        <img
                                          className="h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"
                                          src={
                                            window.$domain +
                                            "/" +
                                            profilePicture
                                          }
                                          alt=""
                                        />
                                      )}
                                    </div>
                                  </div> */}
                                </div>
                                <div className="mt-6 sm:ml-6 sm:flex-1">
                                  <div>
                                    <div className="flex items-center">
                                      <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                        {/* {currentUser?.data?.user?.full_name} */}
                                      </h3>
                                    </div>
                                    <p className="text-sm text-gray-500">
                                      {/* {currentUser?.data?.user?.email} */}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* First name */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="first_name"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                First name
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="first_name"
                                id="first_name"
                                onChange={updateUserInfo.handleChange}
                                value={updateUserInfo.values.first_name}
                                disabled
                                className="block w-full rounded-md text-gray-400 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          {/* Last name */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="last_name"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Last name
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="last_name"
                                id="last_name"
                                onChange={updateUserInfo.handleChange}
                                value={updateUserInfo.values.last_name}
                                disabled
                                className="block w-full rounded-md text-gray-400 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          {/* Food Preference */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="food_preference"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Food Preference{" "}
                                <span className="text-red-500">*</span>
                              </label>
                            </div>
                            <div className="flex flex-col gap-2 sm:col-span-2">
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="food_preference"
                                  id="food_preference"
                                  onChange={updateUserInfo.handleChange}
                                  value="Vegetarian"
                                  required
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">
                                  Vegetarian
                                </span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="food_preference"
                                  id="food_preference"
                                  onChange={updateUserInfo.handleChange}
                                  value="Non Vegetarian"
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">
                                  Non Vegetarian
                                </span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="food_preference"
                                  id="food_preference"
                                  onChange={updateUserInfo.handleChange}
                                  value="Seafood"
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">Seafood</span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="food_preference"
                                  id="food_preference"
                                  onChange={updateUserInfo.handleChange}
                                  value="Other"
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">Other</span>
                                <input
                                  type="text"
                                  name="food_preference"
                                  id="food_preference"
                                  onChange={updateUserInfo.handleChange}
                                  value={
                                    updateUserInfo.values.other_food_preference
                                  }
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>
                          {/* T-shirt Size */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="tshirt_size"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                T-Shirt Size{" "}
                                <span className="text-red-500">*</span>
                              </label>
                            </div>
                            <div className="flex flex-col gap-2 sm:col-span-2">
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="tshirt_size"
                                  id="tshirt_size"
                                  onChange={updateUserInfo.handleChange}
                                  value="Small"
                                  required
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">Small</span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="tshirt_size"
                                  id="tshirt_size"
                                  onChange={updateUserInfo.handleChange}
                                  value="Medium"
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">Medium</span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="tshirt_size"
                                  id="tshirt_size"
                                  onChange={updateUserInfo.handleChange}
                                  value="Large"
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">Large</span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="tshirt_size"
                                  id="tshirt_size"
                                  onChange={updateUserInfo.handleChange}
                                  value="Extra Large"
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">
                                  Extra Large
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* Level of English Proficiency */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="level_of_english_proficiency"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Level of English Proficiency{" "}
                                <span className="text-red-500">*</span>
                              </label>
                            </div>
                            <div className="flex flex-col gap-2 sm:col-span-2">
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="level_of_english_proficiency"
                                  id="level_of_english_proficiency"
                                  onChange={updateUserInfo.handleChange}
                                  value="Novice"
                                  required
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">Novice</span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="level_of_english_proficiency"
                                  id="level_of_english_proficiency"
                                  onChange={updateUserInfo.handleChange}
                                  value="Intermediate"
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">
                                  Intermediate
                                </span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="level_of_english_proficiency"
                                  id="level_of_english_proficiency"
                                  onChange={updateUserInfo.handleChange}
                                  value="Advanced"
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">Advanced</span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="level_of_english_proficiency"
                                  id="level_of_english_proficiency"
                                  onChange={updateUserInfo.handleChange}
                                  value="Native"
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">Native</span>
                              </div>
                            </div>
                          </div>
                          {/* How did you get to know about Best Diplomats? */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="how_get_known"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                How did you get to know about Best Diplomats?{" "}
                                <span className="text-red-500">*</span>
                              </label>
                            </div>
                            <div className="flex flex-col gap-2 sm:col-span-2">
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="how_get_known"
                                  id="how_get_known"
                                  onChange={updateUserInfo.handleChange}
                                  value="Facebook"
                                  required
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">Facebook</span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="how_get_known"
                                  id="how_get_known"
                                  onChange={updateUserInfo.handleChange}
                                  value="Instagram"
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">Instagram</span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="how_get_known"
                                  id="how_get_known"
                                  onChange={updateUserInfo.handleChange}
                                  value="Google"
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">Google</span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="how_get_known"
                                  id="how_get_known"
                                  onChange={updateUserInfo.handleChange}
                                  value="Our Website"
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">
                                  Our Website
                                </span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="how_get_known"
                                  id="how_get_known"
                                  onChange={updateUserInfo.handleChange}
                                  value="Ambassador"
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">
                                  Ambassador
                                </span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="how_get_known"
                                  id="how_get_known"
                                  onChange={updateUserInfo.handleChange}
                                  value="Whatsapp/SMS"
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">
                                  Whatsapp/SMS
                                </span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="how_get_known"
                                  id="how_get_known"
                                  onChange={updateUserInfo.handleChange}
                                  value="Email"
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">Email</span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="how_get_known"
                                  id="how_get_known"
                                  onChange={updateUserInfo.handleChange}
                                  value="Other"
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">Other</span>
                                <input
                                  type="text"
                                  name="how_get_known"
                                  id="how_get_known"
                                  onChange={updateUserInfo.handleChange}
                                  value={
                                    updateUserInfo.values.other_how_get_known
                                  }
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>
                          {/* Name of Ambassador */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="name_of_ambassador"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Name of Ambassador
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="name_of_ambassador"
                                id="name_of_ambassador"
                                onChange={updateUserInfo.handleChange}
                                value={updateUserInfo.values.name_of_ambassador}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          {/* who_is_paying */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="who_is_paying"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                This is a Self-Funded Conference. Who will be
                                paying your Particpation Fee?{" "}
                                <span className="text-red-500">*</span>
                              </label>
                            </div>
                            <div className="flex flex-col gap-2 sm:col-span-2">
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="who_is_paying"
                                  id="who_is_paying"
                                  onChange={updateUserInfo.handleChange}
                                  value="Myself"
                                  required
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">Myself</span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="who_is_paying"
                                  id="who_is_paying"
                                  onChange={updateUserInfo.handleChange}
                                  value="My Parents"
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">
                                  My Parents
                                </span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="who_is_paying"
                                  id="who_is_paying"
                                  onChange={updateUserInfo.handleChange}
                                  value="My Sponsor"
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">
                                  My Sponsor
                                </span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="who_is_paying"
                                  id="who_is_paying"
                                  onChange={updateUserInfo.handleChange}
                                  value="Other"
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">Other</span>
                                <input
                                  type="text"
                                  name="other_who_is_paying"
                                  id="other_who_is_paying"
                                  onChange={updateUserInfo.handleChange}
                                  value={
                                    updateUserInfo.values.other_who_is_paying
                                  }
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>
                          {/* Select Your Preferred Conference Package */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="conference_package"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Select Your Preferred Conference Package{" "}
                                <span className="text-red-500">*</span>
                              </label>
                            </div>
                            <div className="flex flex-col gap-2 sm:col-span-2">
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="conference_package"
                                  id="conference_package"
                                  onChange={updateUserInfo.handleChange}
                                  value="Accommodation (Price: $599)"
                                  required
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">
                                  Accommodation (Price: $599)
                                </span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="conference_package"
                                  id="conference_package"
                                  onChange={updateUserInfo.handleChange}
                                  value="Non Accommodation (Price: $379)"
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">
                                  Non Accommodation (Price: $379)
                                </span>
                              </div>
                              <span className="font-thin text-sm">
                                Please refer to
                                www.bestdiplomats.org/conference-fee for more
                                details.
                              </span>
                            </div>
                          </div>
                          {/* Previous Experence Section */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <span className="block text-xl font-medium text-gray-900 sm:mt-px sm:pt-2">
                              Previous Experience
                            </span>
                          </div>
                          {/* Have you attended a Best Diplomats Conference before? */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="past_experience"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Have you attended a Best Diplomats Conference
                                before? <span className="text-red-500">*</span>
                              </label>
                            </div>
                            <div className="flex flex-col gap-2 sm:col-span-2">
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="past_experience"
                                  id="past_experience"
                                  onChange={updateUserInfo.handleChange}
                                  value="Yes"
                                  required
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">Yes</span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <input
                                  type="radio"
                                  name="past_experience"
                                  id="past_experience"
                                  onChange={updateUserInfo.handleChange}
                                  value="No"
                                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />{" "}
                                <span className="text-gray-600">No</span>
                              </div>
                            </div>
                          </div>
                          {/* Why do you want to attend Best Diplomats Conference? */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="why_attend"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Why do you want to attend Best Diplomats
                                Conference?{" "}
                                <span className="text-red-500">*</span>
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <textarea
                                type="text"
                                name="why_attend"
                                id="why_attend"
                                onChange={updateUserInfo.handleChange}
                                value={updateUserInfo.values.why_attend}
                                required
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          {/* What do you hope to gain from the Experience? */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="gain_experience"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                What do you hope to gain from the Experience?{" "}
                                <span className="text-red-500">*</span>
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <textarea
                                type="text"
                                name="gain_experience"
                                id="gain_experience"
                                onChange={updateUserInfo.handleChange}
                                value={updateUserInfo.values.gain_experience}
                                required
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          {/* Committee and Country Preference */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <span className="block text-xl font-medium text-gray-900 sm:mt-px sm:pt-2">
                              Committee and Country Preference
                            </span>
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="representing"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Select the Country you want to represent{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <select
                              id="representing"
                              name="representing"
                              onChange={updateUserInfo.handleChange}
                              value={updateUserInfo.values.representing}
                              required
                              autoComplete="nationality-name"
                              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            >
                              <option value="Not Selected">Select</option>
                              <option value="Afghanistan">Afghanistan</option>
                              <option value="Åland Islands">
                                Åland Islands
                              </option>
                              <option value="Albania">Albania</option>
                              <option value="Algeria">Algeria</option>
                              <option value="American Samoa">
                                American Samoa
                              </option>
                              <option value="Andorra">Andorra</option>
                              <option value="Angola">Angola</option>
                              <option value="Anguilla">Anguilla</option>
                              <option value="Antarctica">Antarctica</option>
                              <option value="Antigua and Barbuda">
                                Antigua and Barbuda
                              </option>
                              <option value="Argentina">Argentina</option>
                              <option value="Armenia">Armenia</option>
                              <option value="Aruba">Aruba</option>
                              <option value="Australia">Australia</option>
                              <option value="Austria">Austria</option>
                              <option value="Azerbaijan">Azerbaijan</option>
                              <option value="Bahamas">Bahamas</option>
                              <option value="Bahrain">Bahrain</option>
                              <option value="Bangladesh">Bangladesh</option>
                              <option value="Barbados">Barbados</option>
                              <option value="Belarus">Belarus</option>
                              <option value="Belgium">Belgium</option>
                              <option value="Belize">Belize</option>
                              <option value="Benin">Benin</option>
                              <option value="Bermuda">Bermuda</option>
                              <option value="Bhutan">Bhutan</option>
                              <option value="Bolivia">Bolivia</option>
                              <option value="Bosnia and Herzegovina">
                                Bosnia and Herzegovina
                              </option>
                              <option value="Botswana">Botswana</option>
                              <option value="Bouvet Island">
                                Bouvet Island
                              </option>
                              <option value="Brazil">Brazil</option>
                              <option value="British Indian Ocean Territory">
                                British Indian Ocean Territory
                              </option>
                              <option value="Brunei Darussalam">
                                Brunei Darussalam
                              </option>
                              <option value="Bulgaria">Bulgaria</option>
                              <option value="Burkina Faso">Burkina Faso</option>
                              <option value="Burundi">Burundi</option>
                              <option value="Cambodia">Cambodia</option>
                              <option value="Cameroon">Cameroon</option>
                              <option value="Canada">Canada</option>
                              <option value="Cape Verde">Cape Verde</option>
                              <option value="Cayman Islands">
                                Cayman Islands
                              </option>
                              <option value="Central African Republic">
                                Central African Republic
                              </option>
                              <option value="Chad">Chad</option>
                              <option value="Chile">Chile</option>
                              <option value="China">China</option>
                              <option value="Christmas Island">
                                Christmas Island
                              </option>
                              <option value="Cocos (Keeling) Islands">
                                Cocos (Keeling) Islands
                              </option>
                              <option value="Colombia">Colombia</option>
                              <option value="Comoros">Comoros</option>
                              <option value="Congo">Congo</option>
                              <option value="Congo, The Democratic Republic of The">
                                Congo, The Democratic Republic of The
                              </option>
                              <option value="Cook Islands">Cook Islands</option>
                              <option value="Costa Rica">Costa Rica</option>
                              <option value="Cote D'ivoire">
                                Cote D'ivoire
                              </option>
                              <option value="Croatia">Croatia</option>
                              <option value="Cuba">Cuba</option>
                              <option value="Cyprus">Cyprus</option>
                              <option value="Czech Republic">
                                Czech Republic
                              </option>
                              <option value="Denmark">Denmark</option>
                              <option value="Djibouti">Djibouti</option>
                              <option value="Dominica">Dominica</option>
                              <option value="Dominican Republic">
                                Dominican Republic
                              </option>
                              <option value="Ecuador">Ecuador</option>
                              <option value="Egypt">Egypt</option>
                              <option value="El Salvador">El Salvador</option>
                              <option value="Equatorial Guinea">
                                Equatorial Guinea
                              </option>
                              <option value="Eritrea">Eritrea</option>
                              <option value="Estonia">Estonia</option>
                              <option value="Ethiopia">Ethiopia</option>
                              <option value="Falkland Islands (Malvinas)">
                                Falkland Islands (Malvinas)
                              </option>
                              <option value="Faroe Islands">
                                Faroe Islands
                              </option>
                              <option value="Fiji">Fiji</option>
                              <option value="Finland">Finland</option>
                              <option value="France">France</option>
                              <option value="French Guiana">
                                French Guiana
                              </option>
                              <option value="French Polynesia">
                                French Polynesia
                              </option>
                              <option value="French Southern Territories">
                                French Southern Territories
                              </option>
                              <option value="Gabon">Gabon</option>
                              <option value="Gambia">Gambia</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Germany">Germany</option>
                              <option value="Ghana">Ghana</option>
                              <option value="Gibraltar">Gibraltar</option>
                              <option value="Greece">Greece</option>
                              <option value="Greenland">Greenland</option>
                              <option value="Grenada">Grenada</option>
                              <option value="Guadeloupe">Guadeloupe</option>
                              <option value="Guam">Guam</option>
                              <option value="Guatemala">Guatemala</option>
                              <option value="Guernsey">Guernsey</option>
                              <option value="Guinea">Guinea</option>
                              <option value="Guinea-bissau">
                                Guinea-bissau
                              </option>
                              <option value="Guyana">Guyana</option>
                              <option value="Haiti">Haiti</option>
                              <option value="Heard Island and Mcdonald Islands">
                                Heard Island and Mcdonald Islands
                              </option>
                              <option value="Holy See (Vatican City State)">
                                Holy See (Vatican City State)
                              </option>
                              <option value="Honduras">Honduras</option>
                              <option value="Hong Kong">Hong Kong</option>
                              <option value="Hungary">Hungary</option>
                              <option value="Iceland">Iceland</option>
                              <option value="India">India</option>
                              <option value="Indonesia">Indonesia</option>
                              <option value="Iran, Islamic Republic of">
                                Iran, Islamic Republic of
                              </option>
                              <option value="Iraq">Iraq</option>
                              <option value="Ireland">Ireland</option>
                              <option value="Isle of Man">Isle of Man</option>
                              <option value="Israel">Israel</option>
                              <option value="Italy">Italy</option>
                              <option value="Jamaica">Jamaica</option>
                              <option value="Japan">Japan</option>
                              <option value="Jersey">Jersey</option>
                              <option value="Jordan">Jordan</option>
                              <option value="Kazakhstan">Kazakhstan</option>
                              <option value="Kenya">Kenya</option>
                              <option value="Kiribati">Kiribati</option>
                              <option value="Korea, Democratic People's Republic of">
                                Korea, Democratic People's Republic of
                              </option>
                              <option value="Korea, Republic of">
                                Korea, Republic of
                              </option>
                              <option value="Kuwait">Kuwait</option>
                              <option value="Kyrgyzstan">Kyrgyzstan</option>
                              <option value="Lao People's Democratic Republic">
                                Lao People's Democratic Republic
                              </option>
                              <option value="Latvia">Latvia</option>
                              <option value="Lebanon">Lebanon</option>
                              <option value="Lesotho">Lesotho</option>
                              <option value="Liberia">Liberia</option>
                              <option value="Libyan Arab Jamahiriya">
                                Libyan Arab Jamahiriya
                              </option>
                              <option value="Liechtenstein">
                                Liechtenstein
                              </option>
                              <option value="Lithuania">Lithuania</option>
                              <option value="Luxembourg">Luxembourg</option>
                              <option value="Macao">Macao</option>
                              <option value="Macedonia, The Former Yugoslav Republic of">
                                Macedonia, The Former Yugoslav Republic of
                              </option>
                              <option value="Madagascar">Madagascar</option>
                              <option value="Malawi">Malawi</option>
                              <option value="Malaysia">Malaysia</option>
                              <option value="Maldives">Maldives</option>
                              <option value="Mali">Mali</option>
                              <option value="Malta">Malta</option>
                              <option value="Marshall Islands">
                                Marshall Islands
                              </option>
                              <option value="Martinique">Martinique</option>
                              <option value="Mauritania">Mauritania</option>
                              <option value="Mauritius">Mauritius</option>
                              <option value="Mayotte">Mayotte</option>
                              <option value="Mexico">Mexico</option>
                              <option value="Micronesia, Federated States of">
                                Micronesia, Federated States of
                              </option>
                              <option value="Moldova, Republic of">
                                Moldova, Republic of
                              </option>
                              <option value="Monaco">Monaco</option>
                              <option value="Mongolia">Mongolia</option>
                              <option value="Montenegro">Montenegro</option>
                              <option value="Montserrat">Montserrat</option>
                              <option value="Morocco">Morocco</option>
                              <option value="Mozambique">Mozambique</option>
                              <option value="Myanmar">Myanmar</option>
                              <option value="Namibia">Namibia</option>
                              <option value="Nauru">Nauru</option>
                              <option value="Nepal">Nepal</option>
                              <option value="Netherlands">Netherlands</option>
                              <option value="Netherlands Antilles">
                                Netherlands Antilles
                              </option>
                              <option value="New Caledonia">
                                New Caledonia
                              </option>
                              <option value="New Zealand">New Zealand</option>
                              <option value="Nicaragua">Nicaragua</option>
                              <option value="Niger">Niger</option>
                              <option value="Nigeria">Nigeria</option>
                              <option value="Niue">Niue</option>
                              <option value="Norfolk Island">
                                Norfolk Island
                              </option>
                              <option value="Northern Mariana Islands">
                                Northern Mariana Islands
                              </option>
                              <option value="Norway">Norway</option>
                              <option value="Oman">Oman</option>
                              <option value="Pakistan">Pakistan</option>
                              <option value="Palau">Palau</option>
                              <option value="Palestinian Territory, Occupied">
                                Palestinian Territory, Occupied
                              </option>
                              <option value="Panama">Panama</option>
                              <option value="Papua New Guinea">
                                Papua New Guinea
                              </option>
                              <option value="Paraguay">Paraguay</option>
                              <option value="Peru">Peru</option>
                              <option value="Philippines">Philippines</option>
                              <option value="Pitcairn">Pitcairn</option>
                              <option value="Poland">Poland</option>
                              <option value="Portugal">Portugal</option>
                              <option value="Puerto Rico">Puerto Rico</option>
                              <option value="Qatar">Qatar</option>
                              <option value="Reunion">Reunion</option>
                              <option value="Republic of Somaliland">Republic of Somaliland</option>
                              <option value="Romania">Romania</option>
                              <option value="Russian Federation">
                                Russian Federation
                              </option>
                              <option value="Rwanda">Rwanda</option>
                              <option value="Saint Helena">Saint Helena</option>
                              <option value="Saint Kitts and Nevis">
                                Saint Kitts and Nevis
                              </option>
                              <option value="Saint Lucia">Saint Lucia</option>
                              <option value="Saint Pierre and Miquelon">
                                Saint Pierre and Miquelon
                              </option>
                              <option value="Saint Vincent and The Grenadines">
                                Saint Vincent and The Grenadines
                              </option>
                              <option value="Samoa">Samoa</option>
                              <option value="San Marino">San Marino</option>
                              <option value="Sao Tome and Principe">
                                Sao Tome and Principe
                              </option>
                              <option value="Saudi Arabia">Saudi Arabia</option>
                              <option value="Senegal">Senegal</option>
                              <option value="Somaliland">Somaliland</option>
                              <option value="Serbia">Serbia</option>
                              <option value="Seychelles">Seychelles</option>
                              <option value="Sierra Leone">Sierra Leone</option>
                              <option value="Singapore">Singapore</option>
                              <option value="Slovakia">Slovakia</option>
                              <option value="Slovenia">Slovenia</option>
                              <option value="Solomon Islands">
                                Solomon Islands
                              </option>
                              <option value="Somalia">Somalia</option>
                              <option value="South Africa">South Africa</option>
                              <option value="South Georgia and The South Sandwich Islands">
                                South Georgia and The South Sandwich Islands
                              </option>
                              <option value="Spain">Spain</option>
                              <option value="Sri Lanka">Sri Lanka</option>
                              <option value="Sudan">Sudan</option>
                              <option value="Suriname">Suriname</option>
                              <option value="Svalbard and Jan Mayen">
                                Svalbard and Jan Mayen
                              </option>
                              <option value="Swaziland">Swaziland</option>
                              <option value="Sweden">Sweden</option>
                              <option value="Switzerland">Switzerland</option>
                              <option value="Syrian Arab Republic">
                                Syrian Arab Republic
                              </option>
                              <option value="Taiwan">Taiwan</option>
                              <option value="Tajikistan">Tajikistan</option>
                              <option value="Tanzania, United Republic of">
                                Tanzania, United Republic of
                              </option>
                              <option value="Thailand">Thailand</option>
                              <option value="Timor-leste">Timor-leste</option>
                              <option value="Togo">Togo</option>
                              <option value="Tokelau">Tokelau</option>
                              <option value="Tonga">Tonga</option>
                              <option value="Trinidad and Tobago">
                                Trinidad and Tobago
                              </option>
                              <option value="Tunisia">Tunisia</option>
                              <option value="Turkey">Turkey</option>
                              <option value="Turkmenistan">Turkmenistan</option>
                              <option value="Turks and Caicos Islands">
                                Turks and Caicos Islands
                              </option>
                              <option value="Tuvalu">Tuvalu</option>
                              <option value="Uganda">Uganda</option>
                              <option value="Ukraine">Ukraine</option>
                              <option value="United Arab Emirates">
                                United Arab Emirates
                              </option>
                              <option value="United Kingdom">
                                United Kingdom
                              </option>
                              <option value="United States">
                                United States
                              </option>
                              <option value="United States Minor Outlying Islands">
                                United States Minor Outlying Islands
                              </option>
                              <option value="Uruguay">Uruguay</option>
                              <option value="Uzbekistan">Uzbekistan</option>
                              <option value="Vanuatu">Vanuatu</option>
                              <option value="Venezuela">Venezuela</option>
                              <option value="Viet Nam">Viet Nam</option>
                              <option value="Virgin Islands, British">
                                Virgin Islands, British
                              </option>
                              <option value="Virgin Islands, U.S.">
                                Virgin Islands, U.S.
                              </option>
                              <option value="Wallis and Futuna">
                                Wallis and Futuna
                              </option>
                              <option value="Western Sahara">
                                Western Sahara
                              </option>
                              <option value="Yemen">Yemen</option>
                              <option value="Zambia">Zambia</option>
                              <option value="Zimbabwe">Zimbabwe</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex justify-end space-x-3">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => setOpenDraw(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="inline-flex color justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Register
                          </button>
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* Drawer to Event Related details */}
      <Transition.Root show={openDrawDetails} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpenDrawDetails}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form
                      className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                      onSubmit={updateDelegateEventDetail.handleSubmit}
                    >
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-100 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg font-medium text-gray-900">
                                Event Details
                              </Dialog.Title>
                              {/* <p className="text-sm text-gray-500">
                                Get started..
                              </p> */}
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={() => setOpenDrawDetails(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Divider container */}
                        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <span className="text-lg font-semibold">
                              Custom Details
                            </span>
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="food_preference"
                              className="blblock text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Food Preference
                            </label>
                            <select
                              type="text"
                              name="food_preference"
                              id="food_preference"
                              onChange={updateDelegateEventDetail.handleChange}
                              value={
                                updateDelegateEventDetail.values.food_preference
                              }
                              autoComplete="address-level1"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            >
                              <option value="Not Selected">Not Selected</option>
                              <option value="Vegetarian">Vegetarian</option>
                              <option value="Non Vegetarian">
                                Non Vegetarian
                              </option>
                              <option value="Halal">Halal</option>
                              <option value="Sea Food">Sea Food</option>
                            </select>
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="tshirt_size"
                              className="blblock text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              T-Shirt Size
                            </label>
                            <select
                              type="text"
                              name="tshirt_size"
                              id="tshirt_size"
                              onChange={updateDelegateEventDetail.handleChange}
                              value={
                                updateDelegateEventDetail.values.tshirt_size
                              }
                              autoComplete="address-level1"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            >
                              <option value="Not Selected">Not Selected</option>
                              <option value="Small (S)">Small (S)</option>
                              <option value="Medium (M)">Medium (M)</option>
                              <option value="Large (L)">Large (L)</option>
                              <option value="Extra Large (XL)">
                                Extra Large (XL)
                              </option>
                              <option value="Double XL (XXL)">
                              Double XL (XXL)
                              </option>
                              <option value="Triple XL (XXXL)">
                              Triple XL (XXXL)
                              </option>
                            </select>
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <span className="text-lg font-semibold">
                              Arrival Flight Details
                            </span>
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="arrival_flight_date"
                              className="blblock text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Arrival Flight Date
                            </label>
                            <input
                              type="date"
                              name="arrival_flight_date"
                              id="arrival_flight_date"
                              onChange={updateDelegateEventDetail.handleChange}
                              value={
                                updateDelegateEventDetail.values
                                  .arrival_flight_date
                              }
                              autoComplete="address-level1"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="arrival_flight_timeOnly"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Arrival Flight Time
                            </label>
                            <input
                              type="time"
                              name="arrival_flight_timeOnly"
                              id="arrival_flight_timeOnly"
                              onChange={updateDelegateEventDetail.handleChange}
                              value={
                                updateDelegateEventDetail.values
                                  .arrival_flight_timeOnly
                              }
                              autoComplete="address-level1"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="arrival_flight_code"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Arrival Flight Code
                            </label>
                            <input
                              type="text"
                              name="arrival_flight_code"
                              id="arrival_flight_code"
                              onChange={updateDelegateEventDetail.handleChange}
                              value={
                                updateDelegateEventDetail.values
                                  .arrival_flight_code
                              }
                              autoComplete="address-level1"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          {/* <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                          <label
                            htmlFor="passport_number"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Departure Flight Time
                          </label>
                          <input
                            type="datetime-local"
                            name="departure_flight_time"
                            id="departure_flight_time"
                            onChange={updateUserInfo.handleChange}
                            value={updateUserInfo.values.departure_flight_time}
                            autoComplete="address-level1"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div> */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <span className="text-lg font-semibold">
                              Departure Flight Details
                            </span>
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="departure_flight_date"
                              className="blblock text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Departure Flight Time
                            </label>
                            <input
                              type="date"
                              name="departure_flight_date"
                              id="departure_flight_date"
                              onChange={updateDelegateEventDetail.handleChange}
                              value={
                                updateDelegateEventDetail.values
                                  .departure_flight_date
                              }
                              autoComplete="address-level1"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="departure_flight_timeOnly"
                              className="blblock text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Departure Flight Time
                            </label>
                            <input
                              type="time"
                              name="departure_flight_timeOnly"
                              id="departure_flight_timeOnly"
                              onChange={updateDelegateEventDetail.handleChange}
                              value={
                                updateDelegateEventDetail.values
                                  .departure_flight_timeOnly
                              }
                              autoComplete="address-level1"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="passport_number"
                              className="blblock text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Departure Flight Code
                            </label>
                            <input
                              type="text"
                              name="departure_flight_code"
                              id="departure_flight_code"
                              onChange={updateDelegateEventDetail.handleChange}
                              value={
                                updateDelegateEventDetail.values
                                  .departure_flight_code
                              }
                              autoComplete="address-level1"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <span className="text-lg font-semibold">Files</span>
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="flight_ticket_scan"
                              className="blblock text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Upload Flight Ticket
                            </label>
                            <input
                              type="file"
                              name="flight_ticket_scan"
                              id="flight_ticket_scan"
                              onChange={(e) => {
                                uploadTicketDocument(e);
                              }}
                              value={
                                updateDelegateEventDetail.values
                                  .flight_ticket_scan
                              }
                              className="mt-1 block w-full rounded-md border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm sm:my-2"
                            />
                            {isPassportAvailible ? (<button
                              type="button"
                              className="rounded-md border flex flex-row justify-center gap-2 items-center border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={handleFlightDownload}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                                />
                              </svg>
                             <span className="">Uploaded File</span>
                            </button>):(null)}
                            {FlightFileAval && (<span className="text-red-400">No Document Available</span>)}
                            
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="visa_scan"
                              className="blblock text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Upload Visa Document
                            </label>
                            <input
                              type="file"
                              name="visa_scan"
                              id="visa_scan"
                              onChange={(e)=>{
                                uploadVisaDocument(e);
                              }}
                              value={
                                updateDelegateEventDetail.values
                                  .flight_ticket_scan
                              }
                              autoComplete="address-level1"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                            {isVisaAvailible ? (<button
                              type="button"
                              className="rounded-md border flex flex-row justify-center gap-2 items-center border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={handleVisaDownload}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                                />
                              </svg>
                             <span className="">Uploaded File</span>
                            </button>):(null)}
                            {VisaFileAval && (<span className="text-red-400">No Document Available</span>)}
                          </div>
                        </div>
                      </div>

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex justify-end space-x-3">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => setOpenDrawDetails(false)}
                          >
                            Cancel
                          </button>
                          {isSaved ? (<button
                            className="inline-flex justify-center rounded-md border border-transparent bg-green-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                          >
                            Profile Updated
                          </button>):(<button
                            type="submit"
                            className="inline-flex color justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Save
                          </button>)}
                          
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default SingleEvent;
