import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { Navigate, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useState, useRef, useEffect } from "react";
import { ArrowLeftIcon, CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { useFormik } from "formik";
import { toast, Toaster } from "sonner";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

const EventItineraryDetails = () => {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const { eventID,Id } = useParams();
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [itineraries, setItineraries] = useState([]);
  const [editorValue, setEditorValue] = useState("");
  const [startTime, setStartTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [accommodation, setAccommodation] = useState(false);
  const [nonAccommodation, setNonAccommodation] = useState(false);
  const [createItineraryBreakdownSuccess, setCreateItineraryBreakdownSuccess] =
    useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const editorRef = useRef(null);

   useEffect(() => {
    const geteventdata = async () => {
      const result = await axios
        .get(
          `https://dash.bestdiplomats.org/api/method/event-itinerary-with-rundown?filters=[["event","=","${eventID}"],["name","=","${Id}"]]`,
          config
        )
        .then((res) => {
            // if(res.data.data.length==[]){
            //     setIsNotFoundName(true);
            //   }
        //   console.log(res.data.data);
          setItineraries(res?.data?.data[0]?.rundown)
          setStartDate(res?.data?.data[0]?.date)
          setLoadingSkeleton(false)
       
        })
        .catch((e) => {
          console.log("t errrrr", e);
          setLoadingSkeleton(false)
 })
    };
    geteventdata();
  }, [createItineraryBreakdownSuccess]);







  const handleEditorChange = (content, editor) => {
    setEditorValue(content);
  };

  const handleEdit = (index) => {
    window.scrollTo(0, 0);
    const itinerary = itineraries[index];
    setStartTime(itinerary.start_time);
    setAccommodation(itinerary.accommodation === 1);
    setNonAccommodation(itinerary.non_accommodation === 1);
    setEditorValue(itinerary.activity);
    setEditingIndex(index);
  };
  const handleCancel = (index) => {
    setStartTime("");
    setAccommodation(false);
    setNonAccommodation(false);
    setEditorValue("");
    setEditingIndex(null);
  };
  const handleRemove = (index) => {
    const updatedItineraries = itineraries.filter((_, i) => i !== index);
    setItineraries(updatedItineraries);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newItinerary = {
      start_time: startTime,
      accommodation: accommodation ? 1 : 0,
      non_accommodation: nonAccommodation ? 1 : 0,
      activity: editorValue,
    };

    if (editingIndex !== null) {
      const updatedItineraries = [...itineraries];
      updatedItineraries[editingIndex] = newItinerary;
      setItineraries(updatedItineraries);
      setEditingIndex(null);
    } else {
      setItineraries([...itineraries, newItinerary]);
    }
    setStartTime("");
    setAccommodation(false);
    setNonAccommodation(false);
    setEditorValue("");
  };

  const postAnnouncement = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      const jsontoPass = {
        event: eventID,
        event_title: "",
        date: startDate,
        rundown: itineraries,
      };
      const fetchAmbData = async (jsontoPass) => {
        setCreateItineraryBreakdownSuccess(true);
        try {
          await axios.put(
            `${window.$domain}/api/resource/Event Itinerary/${Id}`,
            jsontoPass,
            config
          );
          setItineraries([]);
          toast.success("Updated successfully");
          setCreateItineraryBreakdownSuccess(false);
        } catch (e) {
          toast.error("Announcement failed");
          setCreateItineraryBreakdownSuccess(false);
        }
      };
      fetchAmbData(jsontoPass);
    },
    enableReinitialize: true,
  });
  const goBack = () => {
    window.history.back();
  };
  return (
    <>
      <Toaster position="bottom-left" richColors />
      <form onSubmit={handleSubmit}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-4 pt-4">
          <div className=" flex gap-4 pb-6">
              <span
                className="inline-flex  items-center rounded-full bg-gray-50 px-3 py-1.5 text-xs cursor-pointer font-medium text-gray-600 ring-1 ring-inset ring-gray-50"
                onClick={goBack}
              >
                {" "}
                <ArrowLeftIcon class="h-5 w-5 text-gray-500 pr-1" /> Back
              </span>
              <div className="flex  gap-2">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
              Event Itinerary
            </h2>
              </div>
            </div>
           
            <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="date"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                Day
                </label>
                <div className="mt-2">
                  <input
                    required
                    id="date"
                    name="date"
                    type="date"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="time"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Start Time
                </label>
                <div className="mt-2">
                  <input
                    required
                    id="time"
                    name="time"
                    type="time"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="accommodation"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Accommodation
                </label>
                <div className="mt-2">
                  <input
                    id="accommodation"
                    name="accommodation"
                    type="checkbox"
                    className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    checked={accommodation}
                    onChange={(e) => setAccommodation(e.target.checked)}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="non_accommodation"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Non Accommodation
                </label>
                <div className="mt-2">
                  <input
                    id="non_accommodation"
                    name="non_accommodation"
                    type="checkbox"
                    className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    checked={nonAccommodation}
                    onChange={(e) => setNonAccommodation(e.target.checked)}
                  />
                </div>
              </div>
              <div className="sm:col-span-6">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Description
                </label>
                <div className="mt-2">
                  <Editor
                    apiKey="148jqff4vmvlainlcirs1406j5xa3v54yibpjnzm7xh0etg6"
                    init={{
                      height: 350,
                      branding: false,
                      menubar: true,
                      plugins:
                        "print preview paste searchreplace autolink directionality visualblocks visualchars link template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                      toolbar:
                        "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                      image_advtab: true,
                    }}
                    onEditorChange={handleEditorChange}
                    value={editorValue}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={() => {
              setEditingIndex(null);
              handleCancel();
            }} 
            disabled={createItineraryBreakdownSuccess}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={createItineraryBreakdownSuccess}
            className="rounded-md bg-blue-500 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
          >
            {editingIndex !== null ? "Update" : "Add"}
          </button>
        </div>
      </form>
      <form
        onSubmit={postAnnouncement.handleSubmit}
        className="mt-6 flex items-center justify-end gap-x-6"
      >
      {createItineraryBreakdownSuccess? <button
          type="button"
         disabled
          className="rounded-md bg-blue-500 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
        >
            <svg
                aria-hidden="true"
                role="status"
                class="inline w-5 h-5 mr-3 text-gray-800 animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
        </button>:<button
          type="submit"
          disabled={itineraries?.length === 0?true:false}
          className="rounded-md bg-blue-500 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
        >
          Save
        </button>} 
      </form>

      {itineraries?.length != 0 && (
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {loadingSkeleton? <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-stone-100">
                    <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-start text-sm font-semibold text-gray-900 sm:pl-2"
                    >
                      Time
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  text-start text-sm font-semibold text-gray-900"
                    >
                      Activity
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  text-start  text-sm font-semibold text-gray-900"
                    >
                      Accommodation
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  text-start text-sm font-semibold text-gray-900"
                    >
                      Non Accommodation
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-2 pr-2 sm:pr-0"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                    </tr>
                  </thead>
                
                  <tbody className="divide-y divide-gray-200 bg-white">
                   
                   <tr >
                     <td className="whitespace-nowrap py-3 pl-2  text-sm sm:pl-2">
                     <div className="flex items-center">
                       
                               <Skeleton  width={90}  height={10}/>
                           
                          
                        </div>
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                     <Skeleton />
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       <div className="text-gray-900">
                       <Skeleton />
                       </div>
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td>
                   
                     
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       <div className="text-gray-900">
                       <Skeleton />
                       </div>
                     </td>
                   </tr>
                 
               </tbody>
               </table>:   <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-start text-sm font-semibold text-gray-900 sm:pl-2"
                    >
                      Time
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  text-start text-sm font-semibold text-gray-900"
                    >
                      Activity
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  text-start  text-sm font-semibold text-gray-900"
                    >
                      Accommodation
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  text-start text-sm font-semibold text-gray-900"
                    >
                      Non Accommodation
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-2 pr-2 sm:pr-0"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {itineraries?.map((person, index) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-2">
                       {moment(person?.start_time, "HH:mm:ss").format('HH:mm')}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        <div
                          className="prose mt-4"
                          dangerouslySetInnerHTML={{ __html: person.activity }}
                        />
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.accommodation === 1 ? (
                          <CheckIcon className="h-6 w-6 text-gray-500" />
                        ) : (
                          <XMarkIcon className="h-6 w-6 text-gray-500" />
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.non_accommodation === 1 ? (
                          <CheckIcon className="h-6 w-6 text-gray-500" />
                        ) : (
                          <XMarkIcon className="h-6 w-6 text-gray-500" />
                        )}
                      </td>
                      <td className="relative whitespace-nowrap py-2 pl-2 pr-2 text-right text-sm font-medium sm:pr-2">
                        <span className="flex gap-x-4 items-center justify-center">
                          {" "}
                          <button
                            type="button"
                            onClick={() => handleEdit(index)}
                            className="text-gray-600 py-2 hover:text-gray-700"
                          >
                            Edit
                          </button>{" "}
                          <button
                            type="button"
                            onClick={() => handleRemove(index)}
                            className="text-red-500 py-2 hover:text-red-600"
                          >
                            Delete
                          </button>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>}
            </div>
          </div>
        </div>
      )}
    </>
  );
};


export default EventItineraryDetails