import React from 'react'
import axios from "axios";
// import {
//   CloudArrowDownIcon,
//   ListBulletIcon,
//   DocumentTextIcon,
//   IdentificationIcon,
//   CreditCardIcon,
//   PaperAirplaneIcon,
//   UserPlusIcon,
//   PencilIcon,
// } from "@heroicons/react/24/outline";
// import { XMarkIcon } from "@heroicons/react/24/outline";
// import { Dialog, Menu, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import { Fragment, useState ,useEffect} from "react";
import { useParams } from "react-router-dom";
import Done from "../Alerts/Done";
import Papa from "papaparse";
// import Tag from "../Tag/Tag";
// import moment from "moment";
var userEmail;
var JSToken;
var accessToken;
if (localStorage.user) {
  userEmail = localStorage.user_email;
  JSToken = JSON.parse(localStorage.user);
  accessToken = JSToken.access_token;
}
const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};

const RoomSh = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { eventID } = useParams();
  const [isNotFound, setIsNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allRoomSharingRequests, setAllRoomSharingRequests] = useState(null);
  const [apiFiltersRoomSharing, setApiFiltersRoomSharing] = useState(
    `["event","=","${eventID}"]`)
  const [totalRoomSharingRequestCount, setTotalRoomSharingRequestCount] =
    useState(0);
 
  useEffect(() => {
    const fetchRoomSharing = async () => {
      try {
        setLoading(true);
        const result = await axios
          .get(
            `https://dash.bestdiplomats.org/api/method/get-sharing-requests?filters=[${apiFiltersRoomSharing}]`,
            config
          )
          .then((response) => {
            setTotalRoomSharingRequestCount(response.data.data.length);
            setAllRoomSharingRequests(response.data.data);
            // console.log(response.data.data);
          });
        setLoading(false);
      } catch (e) {
        console.log(e);
        if (e.code == "ERR_NETWORK") {
          setIsNotFound(true);
          setTimeout(() => {
            setIsNotFound(false);
          }, 2000);
        }
      }
    };
    fetchRoomSharing();
  }, [apiFiltersRoomSharing]);
;

const handleDownload = () => {
  const newData = allRoomSharingRequests?.map((item) => ({
   
    Requested_By_Name: item?.requested_by?.user?.full_name,
    Requested_By_Gender:item?.requested_by.gender,
    Requested_By_Nationality: item?.requested_by?.nationality,
    Request_Status : item?.request_status,
// for request_status
    Requested_For_Name: item?.requested_for?.user?.full_name,
    Requested_For_Gender:item?.requested_for.gender,
    Requested_For_Nationality: item?.requested_for?.nationality,
      }));

  const csv = Papa.unparse(newData);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute("download", "my-file.csv");
  document.body.appendChild(link);
  link.click();
};

    // Formik for Filter on Room Sharing
    const applyFiltersRoomSharing = useFormik({
      initialValues: {},
      onSubmit: (values) => {
        setApiFiltersRoomSharing(`["event","=","${eventID}"]`);
  
        // console.log(values);
        //  Payment Package
        if (values.status) {
          if (values.status != "None") {
            setApiFiltersRoomSharing((prevState) => [
              prevState + `,["request_status","=","${values.status}"]`,
            ]);
          } else {
            setApiFiltersRoomSharing(`["event","=","${eventID}"]`);
          }
        }
      },
      enableReinitialize: true,
    });
  return (
    
   
    <>
    <div className="flex justify-end mt-3 gap-2">
      {/* <button className="rounded-md border flex gap-1 border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
        <ExportCSV data={allRoomSharingRequests} />
        <CloudArrowDownIcon className="w-5 h-5 ml-2" />
      </button> */}
         <div className=" absolute top-14 left-6">
          {isNotFound && <Done text="No Data Found" color="red" />}
        </div>
        <div className=" absolute top-14 left-6">
          {isNotFound && <Done text="No Data Found" color="red" />}
        </div>
      <div className="">
        <div className="mt-4 flex justify-end items-center  sm:mt-0 sm:ml-1 sm:flex-none">
        <button
          className="ml-3 inline-flex justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          onClick={() => handleDownload()}
        >
          Download CSV
        </button>
          <form
            onSubmit={applyFiltersRoomSharing.handleSubmit}
          >
            <div className="mt-4 flex justify-end gap-3 items-center  sm:mt-0 sm:ml-4 sm:flex-none">
              <label
                htmlFor="status"
                className="sr-only"
              ></label>
              <select
                id="status"
                name="status"
                className=" block w-60  rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                onChange={
                  applyFiltersRoomSharing.handleChange
                }
                value={applyFiltersRoomSharing.values.status}
              >
                <option value="None">All</option>
                <option value="Approved">Approved</option>
                <option value="Rejected">Rejected</option>
                <option value="Pending">Pending</option>
              </select>
              <button
                type="submit"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                Apply Filter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div className="mt-2">
      <p className="felx justify-end text-right font-semibold">
      Requests: {totalRoomSharingRequestCount} 
      </p>
    </div>

    <div className="mt-4 flex flex-col max-h-screen">
      <div className="-my-2 -mx-4 overflow-x-auto  sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Requested By
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Nationality
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Requested For
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Nationality
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>

                  <th
                    scope="col"
                    className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                  >
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {/* All Event Registration Tab */}
                {loading ? (
                  <>
                    <tr key="1">
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div
                          role="status"
                          className=" animate-pulse w-full md:w-4/12 mb-6 md:mb-0 md:p-3"
                        >
                          <div className="flex items-center">
                            <div className="h-10 w-10 flex-shrink-0">
                              <img
                                className="h-10 w-10 rounded-full"
                                src="https://dash.bestdiplomats.org/files/navAvatar.png"
                                alt=""
                              />
                            </div>
                            <div className="ml-4">
                              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-200 mb-2.5"></div>
                              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-200 mb-2.5"></div>
                            </div>
                          </div>
                        </div>
                      </td>
                      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div className="text-gray-900">
                      {person.payment_package}
                    </div>
                  </td> */}
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div
                          role="status"
                          className=" animate-pulse w-full md:w-4/12 mb-6 md:mb-0 md:p-3"
                        >
                          <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                            <div className="h-2 w-12  rounded-full mb-2.5"></div>
                          </span>
                        </div>
                      </td>

                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <div
                          role="status"
                          className="animate-pulse w-full md:w-4/12 mb-6 md:mb-0 md:p-3"
                        >
                          <div className="h-2 w-8 bg-gray-200 rounded-full dark:bg-gray-200 mb-2.5"></div>
                        </div>
                      </td>
                    </tr>
                  </>
                ) : (
                  allRoomSharingRequests?.map((person) => (
                    <tr key={person.name}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            <img
                              // onClick={() => {
                              //   handleViewDetails(
                              //     person.requested_by.name
                              //   );
                              // }}
                              className="h-10 w-10 rounded-full"
                              src={
                                "https://dash.bestdiplomats.org/" +
                                person.requested_by.user
                                  .user_image
                              }
                              alt=""
                            />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">
                              {person.requested_by.user
                                .first_name +
                                " " +
                                person.requested_by.user
                                  .last_name}
                            </div>
                            <div className="text-gray-500">
                              {person.requested_by.user.name}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          {person.requested_by.nationality}
                        </span>
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            <img
                              // onClick={() => {
                              //   handleViewDetails(
                              //     person.requested_for.name
                              //   );
                              // }}
                              className="h-10 w-10 rounded-full "
                              src={
                                "https://dash.bestdiplomats.org/" +
                                person.requested_for.user
                                  .user_image
                              }
                              alt=""
                            />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">
                              {person.requested_for.user
                                .first_name +
                                " " +
                                person.requested_for.user
                                  .last_name}
                            </div>
                            <div className="text-gray-500">
                              {person.requested_for.user.name}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          {person.requested_for.nationality}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {person.request_status=="Approved"?(<span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800 ">
                          {person.request_status}
                        </span>):person.request_status=="Pending"?(  <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-700 ">
                          {person.request_status}
                        </span>):(  <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800 ">
                          {person.request_status}
                        </span>)}
                      </td>

                      {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <button
                        onClick={() => {
                          handleViewDetails(
                            person.delegate_profile.name
                          );
                        }}
                        className="text-blue-600 hover:text-indigo-900"
                      >
                        View Details
                      </button>
                    </td> */}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    {/* Drawer for details */}

    {/* <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Registration
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-blue-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      {/* <div className="divide-y divide-gray-200">
                        <div className="pb-6">
                          <div className="h-24  sm:h-20 lg:h-28 bg-blue-500" />
                          <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                            <div>
                              <div className="-m-1 flex">
                                <div className="inline-flex overflow-hidden rounded-lg ">
                                  <img
                                    className="h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"
                                    src={
                                      "https://dash.bestdiplomats.org/" +
                                      detailPersonReg?.delegate_profile.user
                                        .user_image
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mt-6 sm:ml-6 sm:flex-1">
                              <div>
                                <div className="flex items-center">
                                  <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                    {detailPersonReg?.delegate_profile.user
                                      .first_name +
                                      " " +
                                      detailPersonReg?.delegate_profile.user
                                        .last_name}
                                  </h3>
                                </div>
                                <p className="text-sm text-gray-500">
                                  {detailPersonReg?.delegate_profile.user.email}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-4 py-5 sm:px-0 sm:py-0">
                          <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Address
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <p>
                                  {detailPersonReg?.delegate_profile.address}
                                </p>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Representing Country
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <Tag
                                  text={detailPersonReg?.representing_country}
                                  color="green"
                                />
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Flight Arrival Time
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {isFlightArrivalTimeMissing ? (
                                  <>
                                    <Tag text="Missing" color="red" />
                                  </>
                                ) : (
                                  <>
                                    {moment(
                                      detailPersonReg?.flight_arrival_time
                                    ).format("LLLL")}
                                  </>
                                )}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Flight Ticket
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {isFlightTicketMissing ? (
                                  <>
                                    <Tag text="Missing" color="red" />
                                  </>
                                ) : (
                                  <>
                                    <a
                                      href={
                                        "https://dash.bestdiplomats.org" +
                                        detailPersonReg?.flight_scan
                                      }
                                      target="_blank"
                                      className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                    >
                                      Open{" "}
                                      <svg
                                        className="w-5 h-5 ml-1"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                        ></path>
                                      </svg>
                                    </a>
                                  </>
                                )}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                T-Shirt Size
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {isTshirtSizeMissing ? (
                                  <>
                                    <Tag text="Missing" color="red" />
                                  </>
                                ) : (
                                  detailPersonReg?.tshirt_size
                                )}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Food Preference
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {isFoodPrefMissing ? (
                                  <>
                                    <Tag text="Missing" color="red" />
                                  </>
                                ) : (
                                  detailPersonReg?.food_preference
                                )}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Visa
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {isVisaMissing ? (
                                  <>
                                    <Tag text="Missing" color="red" />
                                  </>
                                ) : (
                                  <>
                                    <a
                                      href={
                                        "https://dash.bestdiplomats.org" +
                                        detailPersonReg?.visa_scan
                                      }
                                      target="_blank"
                                      className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                    >
                                      Open{" "}
                                      <svg
                                        className="w-5 h-5 ml-1"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                        ></path>
                                      </svg>
                                    </a>
                                  </>
                                )}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Passport Document
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {isPassportMissing ? (
                                  <>
                                    <Tag text="Missing" color="red" />
                                  </>
                                ) : (
                                  <>
                                    <a
                                      href={
                                        "https://dash.bestdiplomats.org" +
                                        detailPersonReg?.delegate_profile
                                          .passport_upload
                                      }
                                      target="_blank"
                                      className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                    >
                                      Open{" "}
                                      <svg
                                        className="w-5 h-5 ml-1"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                        ></path>
                                      </svg>
                                    </a>
                                  </>
                                )}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Passport Expiry
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {isPassportExpiryMissing ? (
                                  <>
                                    <Tag text="Missing" color="red" />
                                  </>
                                ) : (
                                  moment(
                                    detailPersonReg?.delegate_profile
                                      .passport_expiry
                                  ).format("D MMMM, YYYY")
                                )}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Passport Number
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {isPassportNumberMissing ? (
                                  <>
                                    <Tag text="Missing" color="red" />
                                  </>
                                ) : (
                                  detailPersonReg?.delegate_profile.passport_number.toUpperCase()
                                )}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Visa Procedure
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {isVisaProcedureMissing ? (
                                  <>
                                    <Tag text="Missing" color="red" />
                                  </>
                                ) : (
                                  detailPersonReg?.visa_procedure
                                )}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                ID Card
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <a
                                  href={
                                    window.$FWDurl +
                                    "/IdCard/" +
                                    detailPersonReg?.delegate_profile.name +
                                    "-" +
                                    eventID
                                  }
                                  target="_blank"
                                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                >
                                  View & Download{" "}
                                  <CloudArrowDownIcon className="w-5 h-5 ml-2" />
                                </a>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Invitation Letter
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <a
                                  href={
                                    window.$FWDurl +
                                    "/InvitationValidate/" +
                                    eventID +
                                    "/" +
                                    detailPersonReg?.delegate_profile.user.email
                                  }
                                  target="_blank"
                                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                >
                                  View & Download{" "}
                                  <CloudArrowDownIcon className="w-5 h-5 ml-2" />
                                </a>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Hotel Pass
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {isNonAccomodation ? (
                                  <Tag text="Non Accommodation" color="red" />
                                ) : (
                                  <a
                                    href={
                                      window.$FWDurl +
                                      "/HotelValidate/" +
                                      eventID +
                                      "/" +
                                      detailPersonReg?.delegate_profile.user
                                        .email
                                    }
                                    target="_blank"
                                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                  >
                                    View & Download{" "}
                                    <CloudArrowDownIcon className="w-5 h-5 ml-2" />
                                  </a>
                                )}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Certificate
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <a
                                  href={
                                    window.$FWDurl +
                                    "/Certificate/" +
                                    eventID +
                                    "/" +
                                    detailPersonReg?.delegate_profile.name
                                  }
                                  target="_blank"
                                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                >
                                  View & Download{" "}
                                  <CloudArrowDownIcon className="w-5 h-5 ml-2" />
                                </a>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Status
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <form
                                  onSubmit={regStatusUpdate.handleSubmit}
                                  className="flex gap-2"
                                >
                                  <label
                                    htmlFor="status_registration"
                                    className="sr-only"
                                  ></label>
                                  <select
                                    type="text"
                                    name="status_registration"
                                    id="status_registration"
                                    onChange={regStatusUpdate.handleChange}
                                    value={
                                      regStatusUpdate.values.status_registration
                                    }
                                    required
                                    className="mt-1 block w-40 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  >
                                    <option value="Suspended">Suspended</option>
                                    <option value="Active">Active</option>
                                  </select>
                                  <button
                                    type="submit"
                                    className="group relative flex w-24 justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                  >
                                    Update
                                  </button>
                                </form>
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root> */} 
  </>
  )
}

export default RoomSh