import React, { useRef } from "react";
import ReactPlayer from "react-player";
import { useState, useEffect } from "react";
import axios from "axios";

import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { Dialog, Menu, Listbox, Transition } from "@headlessui/react";
import {
  ArrowTopRightOnSquareIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Skeleton from "react-loading-skeleton";
import {
  EllipsisVerticalIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import { useFormik } from "formik";
import { Toaster, toast } from "sonner";
import PrivateImage from "../PrivateImage/PrivateImage";
import { useParams } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  // More people...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const team = [
  {
    name: "Tom Cook",
    email: "tom.cook@example.com",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Whitney Francis",
    email: "whitney.francis@example.com",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Leonard Krasner",
    email: "leonard.krasner@example.com",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Floyd Miles",
    email: "floyd.miles@example.com",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Emily Selman",
    email: "emily.selman@example.com",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
];
const PricingBreakDown = () => {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const { eventID } = useParams();
  const [open, setOpen] = useState(false);
  const [updatePricingPlanOpen, setUpdatePricingPlanOpen] = useState(false);

  const cancelButtonRef = useRef(null);
  const [getAllPriceDown, setGetAllPriceDown] = useState([]);
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [submitDone, setSubmitDone] = useState(false);
  const [instagramprofile, setInstagramProfile] = useState();
  const [facebookprofile, setFacebookProfile] = useState();
  const [twitterprofile, setTwitterProfile] = useState();
  const [linkedinprofile, setLinkedinProfile] = useState();
  const [businessperson, setBusinessPerson] = useState(false);
  const [student, setStudent] = useState(false);
  const [employee, setEmployee] = useState(false);
  const [totalPriceBreakDown, setTotalPriceBreakDown] = useState();
  const [isNotFoundPriceDown, setIsNotFoundPriceDown] = useState(false);

  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedUpdate, setIsCheckedUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setpageNumber] = useState([]);
  const tableRef = useRef(null);
  const [inviteFilter, setInviteFilter] = useState([]);
  const [bio, setBio] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const fetchPriceBreakData = async () => {
      try {
        await axios
          .get(
            `${window.$domain}/api/resource/Conference Pricing Plans?fields=["*"]&filters=[["conference","=","${eventID}"]]`,
            config
          )
          .then((response) => {
            setGetAllPriceDown(response.data.data);
            // console.log("Price breakdown", response.data.data);
            setpageNumber([]);
            for (
              let i = 1;
              i <= Math.ceil(response.data.data.length / 40);
              i++
            ) {
              setpageNumber((prevState) => [...prevState, i]);
              // console.log(pageNumbers);
            }
            // console.log(eventID);
            setTotalPriceBreakDown(response.data.data.length);
            setIsNotFoundPriceDown(false);
            setLoadingSkeleton(false);
            if (response.data.data.length == 0) {
              setLoadingSkeleton(true);
              // toast.error("No Data Found");
              setIsNotFoundPriceDown(true);
            }
          });
      } catch (e) {
        console.log(e);
        if (e.code == "ERR_NETWORK") {
          // toast.error("No Data Found");
          setIsNotFoundPriceDown(true);
        }
      }
    };
    fetchPriceBreakData();
  }, [submitDone, inviteFilter]);
  const scrollToTop = () => {
    tableRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleClick = (number) => {
    setCurrentPage(number);
    scroll();
    scrollToTop();
  };
  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handlePaginationClick = (event) => {
    if (event == "Previous") {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        scroll();
        scrollToTop();
      }
    } else if (event == "Next") {
      if (currentPage < pageNumbers.length) {
        setCurrentPage(currentPage + 1);
        scroll();
        scrollToTop();
      }
    }
  };
  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev);
  };
  const handleCheckboxUpdate = () => {
    setIsCheckedUpdate((prev) => !prev);
  };

  const [getAllEvents, setGetAllEvents] = useState([]);
  const [getAllBreakDown, setGetAllBreakDown] = useState([]);
  const [updatePricingPlanId, setUpdatePricingPlanId] = useState(null);
  const [selected, setSelected] = useState({ name: "None", title: "Select" });
  const [openAddNewPlan, setOpenAddNewPlan] = useState(false);
  const [updatedLoading, setUpdatedLoading] = useState(false);
  const [editedLoading, setEditedLoading] = useState(false);
  const [PriceBreakDown, setPriceBreakDown] = useState(null);
  const [PriceBreakDownDetailOpen, setPriceBreakDownDetailOpen] =
    useState(false);
  useEffect(() => {
    const fetchEventsData = async () => {
      try {
        const result = await axios
          .get(
            'https://dash.bestdiplomats.org/api/resource/Events?filters=[["status","in","Active,Upcoming"]]&fields=["*"]'
          )
          .then((response) => {
            const newData = response.data.data;
            newData.unshift({ name: "None", title: "Select" });
            setGetAllEvents(newData);

            // console.log("events", "getAllEvents", newData, response.data.data);
          });
      } catch (e) {
        console.log(e);
      }
    };
    fetchEventsData();
  }, []);
  // const fetchPriceBreakDownDetailData = async (id) => {
  //   try {
  //     await axios
  //       .get(
  //         `${window.$domain}/api/resource/Conference Pricing Plans/${id}`,
  //         config
  //       )
  //       .then((response) => {
  //         console.log("Single", response.data.data);
  //         setGetAllBreakDown(response?.data?.data?.breakdown);
  //         setPriceBreakDownDetailOpen(true);
  //         setPriceBreakDown(response.data.data);

  //         console.log(eventID);
  //       });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };
  const createNewPricingPlan = useFormik({
    initialValues: {
      plan_name: "",
      // base_price: "",
      conference: eventID,
      processing_fee: "",
      accommodation_pricing: "",
      non_accommodation_pricing: "",
      full_experience_pricing: "",
      valid_from: "",
      valid_till: "",
      // valid_for_days: "",
    },
    onSubmit: (values) => {
      setEditedLoading(true);
      var PassJson = {
        plan_name: values.plan_name,
        // base_price: values.base_price,
        conference: eventID,
        processing_fee: values.processing_fee,
        valid_from: values.valid_from,
        valid_till: values.valid_till,
        accommodation_pricing: values.accommodation_pricing,
        non_accommodation_pricing: values.non_accommodation_pricing,
        full_experience_pricing: values.full_experience_pricing,
        // valid_for_days: values.valid_for_days,
        // breakdown: [
        //   {
        //     accommodation_price: values.accommodation_price,
        //     non_accommodation_price: values.non_accommodation_price,
        //     full_experience_price: values.full_experience_price,
        //     valid_from: values.valid_froms,
        //     valid_till: values.valid_tills,
        //     installments_allowed: isChecked === false ? 0 : 1,
        //   },
        // ],
      };

      const createnew = async () => {
        try {
          await axios
            .post(
              `${window.$domain}/api/resource/Conference Pricing Plans`,
              PassJson,
              config
            )
            .then((res) => {
              // console.log("create events", res);
              setSubmitDone(!submitDone);
              setEditedLoading(false);
              setSelected({ name: "None", title: "Select" });
              toast.success("Price plan created successfully");
              createNewPricingPlan.resetForm();
            });
        } catch (e) {
          console.log(e);
          setEditedLoading(false);
          toast.error("Creation failed");
        }
      };

      createnew();
    },

    enableReinitialize: true,
  });
  // const handleDateChange = (e) => {
  //   const { name, value } = e.target;
  //   createNewPricingPlan.setFieldValue(name, value);

  //   if (name === 'valid_from' && createNewPricingPlan.values.valid_till) {
  //     calculateDays(value, createNewPricingPlan.values.valid_till);
  //   } else if (name === 'valid_till' && createNewPricingPlan.values.valid_from) {
  //     calculateDays(createNewPricingPlan.values.valid_from, value);
  //   }
  // };

  // const calculateDays = (fromDate, tillDate) => {
  //   const date1 = new Date(fromDate);
  //   const date2 = new Date(tillDate);
  //   const timeDifference = date2 - date1;
  //   const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  //   createNewPricingPlan.setFieldValue('valid_for_days', daysDifference);
  // };

  const UpdatePricingPlan = useFormik({
    initialValues: {
      // plan_name: "",
      // base_price: "",
      // conference: eventID,
      // processing_fee: "",
      // accommodation_pricing: "",
      // non_accommodation_pricing: "",
      // full_experience_pricing: "",
      // valid_from: "",
      // valid_till: "",
      // base_price: PriceBreakDown?.base_price,
      conference: eventID,
      plan_name: PriceBreakDown?.plan_name,
      conference: PriceBreakDown?.conference,
      processing_fee: PriceBreakDown?.processing_fee,
      valid_from: PriceBreakDown?.valid_from,
      valid_till: PriceBreakDown?.valid_till,
      accommodation_pricing: PriceBreakDown?.accommodation_pricing,
      non_accommodation_pricing: PriceBreakDown?.non_accommodation_pricing,
      full_experience_pricing:PriceBreakDown?.full_experience_pricing,
  
    },
    onSubmit: (values) => {
      setUpdatedLoading(true);
      var PassJson = {
        conference: eventID,
        plan_name: values?.plan_name,
        processing_fee: values?.processing_fee,
        accommodation_pricing: values.accommodation_pricing,
        non_accommodation_pricing: values.non_accommodation_pricing,
        full_experience_pricing:values?.full_experience_pricing,
        valid_from: values.valid_from,
        valid_till: values.valid_till,
      };
      // console.log("PassJson",PassJson);
      // let newRelations = getAllBreakDown;
      // newRelations?.push(PassJson);
      const createnew = async () => {
        try {
          await axios
            .put(
              `${window.$domain}/api/resource/Conference Pricing Plans/${PriceBreakDown?.name}`,
              PassJson,
              config
            )
            .then((res) => {
              setIsCheckedUpdate(false);
              setUpdatedLoading(false);
              setSubmitDone(!submitDone);
              toast.success("Updated successfully");
              setUpdatePricingPlanOpen(false);
              UpdatePricingPlan.resetForm();
            });
        } catch (e) {
          console.log(e);
          toast.error("try again");
          setUpdatedLoading(false);
        }
      };
      createnew();
    },

    enableReinitialize: true,
  });
  return (
    <>
      <div className="flex justify-end items-center mt-3  pr-3 ">
        {/* {loadingSkeleton ? (
          <>
            <Skeleton width={80} height={40} />{" "}
          </>
        ) : (
          <> */}
            <button
              type="button"
              className=" inline-flex mb-1 justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              onClick={() => {
                setOpenAddNewPlan(true);
              }}
            >
              Add New Plan
            </button>
          {/* </>
        )} */}
      </div>
      <div className="py-1  lg:mr-2 flex items-center  justify-end sm:mt-0 sm:ml-1 sm:flex-none">
        <Toaster position="bottom-left" richColors />
        <p className=" font-semibold flex   items-center justify-end text-gray-700">
          {loadingSkeleton ? (
            <Skeleton width={40} height={20} />
          ) : (
            <> Entities: {totalPriceBreakDown} </>
          )}
        </p>
      </div>
      <div className="px-2 sm:px-4   lg:px-4">
        <div className=" flex flex-col">
          <div
            ref={tableRef}
            className="my-2 -mx-4 overflow-x-auto max-h-screen sm:-mx-4 lg:-mx-4"
          >
            <div
              ref={tableRef}
              className="inline-block overflow-auto min-w-full py-1 align-middle md:px-3 lg:px-3"
            >
              {isNotFoundPriceDown ? (
                <div className="h-screen flex justify-center flex-col items-center ">
                  <svg
                    fill="none"
                    className="w-40 h-40 text-gray-400"
                    stroke="currentColor"
                    strokeWidth={1.5}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
                    />
                  </svg>{" "}
                  <span className=" text-lg text-gray-400"> No Data Found</span>
                </div>
              ) : (
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  {" "}
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-stone-100">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Processing Fee
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                      Accommodation Price
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                        Non Accommodation Price
                        </th>   <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                        Full Experience Price
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Valid Till
                        </th>

                        {/* <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Valid For Days
                        </th> */}
                      </tr>
                    </thead>
                    {loadingSkeleton ? (
                      <tbody className="divide-y divide-gray-200 bg-white">
                        <tr>
                          <td className="whitespace-nowrap py-3 pl-4  text-sm sm:pl-6">
                            <div className="flex items-center">
                              <div className="ml-1">
                                <div className="font-medium cursor-pointer text-gray-900">
                                  <Skeleton width={110} height={28} />
                                </div>
                              </div>
                            </div>
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <Skeleton
                              borderRadius={5}
                              width={68}
                              height={28}
                            />
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <Skeleton
                              borderRadius={5}
                              width={68}
                              height={28}
                            />
                          </td> 
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <Skeleton
                              borderRadius={5}
                              width={68}
                              height={28}
                            />
                          </td> <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <Skeleton
                              borderRadius={5}
                              width={68}
                              height={28}
                            />
                          </td> <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <Skeleton
                              borderRadius={10}
                              width={68}
                              height={28}
                            />
                          </td>
                       
                        </tr>
                      </tbody>
                    ) : (
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {getAllPriceDown
                          ?.slice((currentPage - 1) * 40, currentPage * 40)
                          .map((price) => (
                            <tr key={price?.name}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                <div className="flex items-center">
                                  <div className="">
                                    <div
                                      onClick={() => {
                                        setPriceBreakDownDetailOpen(true);
                                                setPriceBreakDown(price);
                                        // fetchPriceBreakDownDetailData(
                                        //   price?.name
                                        // );
                                      }}
                                      className="font-medium  cursor-pointer text-gray-900"
                                    >
                                      {price?.plan_name}
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                {price?.processing_fee}%
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                {price?.accommodation_pricing}
                              </td>  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                {price?.non_accommodation_pricing}
                              </td>  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                {price?.full_experience_pricing}
                              </td>
                              {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                <span
                                  className={
                                    "bg-green-100 text-green-800 inline-flex rounded-full  px-2 text-xs font-semibold leading-5 "
                                  }
                                >
                                  {price?.valid_from}
                                </span>
                              </td>                          */}
                                   <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                <span
                                  className={
                                    "bg-green-100 text-green-800 inline-flex rounded-full  px-2 text-xs font-semibold leading-5 "
                                  }
                                >
                                  {price?.valid_till}
                                </span>
                              </td>
                              {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                <span
                                  className={classNames(
                                    price?.valid_for_days == 0
                                      ? "bg-red-100 text-red-800"
                                      : price?.valid_for_days <= 4
                                      ? "bg-orange-100 text-orange-500"
                                      : "bg-green-100 text-green-800",
                                    "inline-flex rounded-full  px-2 text-xs font-semibold leading-5 "
                                  )}
                                >
                                  {price?.valid_for_days}
                                </span>
                              </td> */}
                            </tr>
                          ))}
                      </tbody>
                    )}
                  </table>{" "}
                </div>
              )}
            </div>
          </div>
        </div>
        {isNotFoundPriceDown ? null : (
          <div className="flex items-center justify-between mt-2 bg-white px-4 py-3 sm:px-3  rounded-lg shadow">
            <div className="flex flex-1 justify-between sm:hidden">
              <button
                onClick={() => {
                  handlePaginationClick("Previous");
                }}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Previous
              </button>
              <button
                onClick={() => {
                  handlePaginationClick("Next");
                }}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">
                    {(currentPage - 1) * 40 + 1}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium">
                    {currentPage * (totalPriceBreakDown % 40)}
                  </span>{" "}
                  of <span className="font-medium">{totalPriceBreakDown}</span>{" "}
                  results
                </p>
              </div>
              <div>
                <nav
                  className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <button
                    onClick={() => {
                      handlePaginationClick("Previous");
                    }}
                    className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                  {pageNumbers?.map((number) => {
                    return (
                      <button
                        key={number}
                        className={
                          currentPage == number
                            ? "relative z-10 inline-flex items-center border border-purple-500 bg-purple-50 px-4 py-2 text-sm font-medium text-purple-600 focus:z-20"
                            : "relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex"
                        }
                        onClick={() => handleClick(number)}
                      >
                        {number}
                      </button>
                    );
                  })}

                  <button
                    onClick={() => {
                      handlePaginationClick("Next");
                    }}
                    className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <Transition.Root show={PriceBreakDownDetailOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={setPriceBreakDownDetailOpen}
        >
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-[44rem]">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1">
                      
                        <div className="banner-back px-4 py-3 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-base font-semibold leading-6 text-white">
                                Conference Pricing Plans
                              </Dialog.Title>
                              <p className="text-sm sr-only text-gray-500">
                                Get started by filling in the information
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="relative text-white hover:bg-gray-200 hover:text-gray-900"
                                onClick={() =>
                                  setPriceBreakDownDetailOpen(false)
                                }
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                    
                        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                          <div className="space-y-2   px-4 sm:grid sm:grid-cols-3 sm:gap-4  sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="date_of_revision"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1"
                              >
                                Plan Name
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              {PriceBreakDown?.plan_name}
                            </div>
                          </div>
                          <div></div>
                        </div>
                      
                        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                          
                          <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <h3 className="text-sm font-medium leading-6 text-gray-900">
                                Processing Fee
                              </h3>
                            </div>
                            <div className="sm:col-span-2">
                              <div className="flex space-x-2">
                                {PriceBreakDown?.processing_fee}
                              </div>
                            </div>
                          </div>
                          <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <h3 className="text-sm font-medium leading-6 text-gray-900">
                                Valid From
                              </h3>
                            </div>
                            <div className="sm:col-span-2">
                              <span
                                className={
                                  "bg-green-100 text-green-800 inline-flex rounded-full  px-2 text-xs font-semibold leading-5 "
                                }
                              >
                                {PriceBreakDown?.valid_from}
                              </span>
                            </div>
                          </div>
                          <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <h3 className="text-sm font-medium leading-6 text-gray-900">
                                Valid Till
                              </h3>
                            </div>
                            <div className="sm:col-span-2">
                              <span
                                className={
                                  "bg-green-100 text-green-800 inline-flex rounded-full  px-2 text-xs font-semibold leading-5 "
                                }
                              >
                                {PriceBreakDown?.valid_till}
                              </span>
                            </div>
                          </div>
                          <div className="flex-shrink-0 border-t border-gray-200 px-4 py-2 sm:px-6">
                            <div className="flex justify-end space-x-3">
                              <button
                                type="button"
                                onClick={() => {
                                  setUpdatePricingPlanOpen(true);
                                  setUpdatePricingPlanId(PriceBreakDown?.name);
                                }}
                                className="inline-flex justify-center rounded-md banner-back px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                Edit
                              </button>
                            </div>
                          </div>
                          <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div className="px-4 sm:px-4 lg:px-4 sm:col-span-full">
                              <div className="mt-0 flow-root">
                                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                  <div className="inline-block min-w-full py-2 align-middle sm:px-4 lg:px-4">
                                    <table className="min-w-full divide-y divide-gray-300">
                                      <thead>
                                        <tr>
                                          <th
                                            scope="col"
                                            className="py-3.5 whitespace-nowrap pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                          >
                                            Accommodation Price
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-3 whitespace-nowrap py-3.5 text-left text-sm font-semibold text-gray-900"
                                          >
                                            Non Accommodation Price
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-3 whitespace-nowrap py-3.5 text-left text-sm font-semibold text-gray-900"
                                          >
                                            Full Experience Price
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-3 whitespace-nowrap  py-3.5 text-left text-sm font-semibold text-gray-900"
                                          >
                                            Valid From
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-3 whitespace-nowrap py-3.5 text-left text-sm font-semibold text-gray-900"
                                          >
                                            Valid Till
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-3 whitespace-nowrap  py-3.5 text-left text-sm font-semibold text-gray-900"
                                          >
                                            Installments Allowed
                                          </th>
                                          <th
                                            scope="col"
                                            className="relative py-3.5 pl-3 pr-0"
                                          >
                                            <span className="sr-only">
                                              Edit
                                            </span>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="divide-y divide-gray-200 bg-white">
                                        {PriceBreakDown?.breakdown?.map(
                                          (person) => (
                                            <tr key={person.name}>
                                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                                {person.accommodation_price}
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {person.non_accommodation_price}
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {person.full_experience_price}
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {person.valid_from}
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {person.valid_till}
                                              </td>
                                              <td className="whitespace-nowrap flex justify-center px-3 py-4 text-sm text-gray-500">
                                                <input
                                                  id={
                                                    person?.installments_allowed
                                                  }
                                                  name={
                                                    person?.installments_allowed
                                                  }
                                                  type="checkbox"
                                                  disabled
                                                  checked={
                                                    person?.installments_allowed ===
                                                    1
                                                      ? true
                                                      : false
                                                  }
                                                  className="h-5 w-5 rounded mr-1 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                />
                                              </td>
                                              <td className="relative  whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-0">
                                                <a
                                                  href="#"
                                                  className="text-indigo-600 hover:text-indigo-900 "
                                                >
                                                  <span className="sr-only">
                                                    , {person.name}
                                                  </span>
                                                </a>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root> */}
      <Transition.Root show={PriceBreakDownDetailOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setPriceBreakDownDetailOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6 banner-back">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-white">
                             Conference Pricing Plans Details
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md  text-gray-900 hover:text-gray-500 focus:ring-2 "
                              onClick={() => setPriceBreakDownDetailOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      <div className="divide-y items-center divide-gray-200">
                        <form
                          onSubmit={UpdatePricingPlan.handleSubmit}
                          className="flex  flex-col divide-y  divide-gray-200 bg-white "
                        >
                          <div className="">
                            <div className="space-y-4 px-4">
                              <div className="mt-2 border-gray-900/10 ">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                  Plan Information
                                </h2>

                                <div className="mt-3 grid grid-cols-1 gap-x-3 gap-y-3 sm:grid-cols-6">
                                  <div className="col-span-3">
                                    <label
                                      htmlFor="plan_name"
                                      className="block text-sm font-medium text leading-6 text-gray-900"
                                    >
                                      Plan Name{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <div className="mt-1">
                                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
                                        <input
                                          type="text"
                                          name="plan_name"
                                          id="plan_name"
                                          onChange={
                                            UpdatePricingPlan.handleChange
                                          }
                                          value={
                                            UpdatePricingPlan.values
                                              .plan_name
                                          }
                                          autoComplete="off"
                                          className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-span-3">
                                    <label
                                      htmlFor="processing_fee"
                                      className="block text-sm font-medium text leading-6 text-gray-900"
                                    >
                                      Processing Fee{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <div className="mt-1">
                                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
                                        <input
                                          type="number"
                                          step="0.01"
                                          min={0}
                                          name="processing_fee"
                                          id="processing_fee"
                                          onChange={
                                            UpdatePricingPlan.handleChange
                                          }
                                          value={
                                            UpdatePricingPlan.values
                                              .processing_fee
                                          }
                                          autoComplete="off"
                                          className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                   {/* <div className="col-span-3">
                                    <label
                                      htmlFor="base_price"
                                      className="block text-sm font-medium text leading-6 text-gray-900"
                                    >
                                      Base Price{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <div className="mt-1">
                                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
                                        <input
                                          type="number"
                                          step="0.01"
                                          min={0}
                                          name="base_price"
                                          id="base_price"
                                          onChange={
                                            UpdatePricingPlan.handleChange
                                          }
                                          value={
                                            UpdatePricingPlan.values.base_price
                                          }
                                          autoComplete="off"
                                          className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                  </div> */}
                               
                                </div>
                              </div>
                              <div className="  border-gray-900/10 pb-2">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                  BreakDown Information
                                </h2>

                                <div className="mt-3 grid grid-cols-1 gap-x-3 gap-y-3 sm:grid-cols-6">
                                  <div className="col-span-3">
                                    <label
                                      htmlFor="accommodation_pricing"
                                      className="block text-sm font-medium text leading-6 text-gray-900"
                                    >
                                      Accommodation Price
                                    </label>
                                    <div className="mt-1">
                                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
                                        <input
                                          type="number"
                                          step="0.01"
                                          min={0}
                                          name="accommodation_pricing"
                                          id="accommodation_pricing"
                                          onChange={
                                            UpdatePricingPlan.handleChange
                                          }
                                          value={
                                            UpdatePricingPlan.values.accommodation_pricing
                                          }
                                          autoComplete="off"
                                          className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-span-3">
                                    <label
                                      htmlFor="non_accommodation_pricing"
                                      className="block text-sm font-medium text leading-6 text-gray-900"
                                    >
                                      Non Accommodation Price
                                    </label>
                                    <div className="mt-1">
                                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
                                        <input
                                          type="number"
                                          step="0.01"
                                          min={0}
                                          name="non_accommodation_pricing"
                                          id="non_accommodation_pricing"
                                          onChange={
                                            UpdatePricingPlan.handleChange
                                          }
                                          value={
                                            UpdatePricingPlan.values.non_accommodation_pricing
                                          }
                                          autoComplete="off"
                                          className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-span-3">
                                    <label
                                      htmlFor="full_experience_pricing"
                                      className="block text-sm font-medium text leading-6 text-gray-900"
                                    >
                                      Full Experience Price
                                    </label>
                                    <div className="mt-1">
                                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
                                        <input
                                          type="number"
                                          step="0.01"
                                          min={0}
                                          name="full_experience_pricing"
                                          id="full_experience_pricing"
                                          onChange={
                                            UpdatePricingPlan.handleChange
                                          }
                                          value={
                                            UpdatePricingPlan.values.full_experience_pricing
                                          }
                                          autoComplete="off"
                                          className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="valid_from"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Valid From
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="date"
                                        name="valid_from"
                                        id="valid_from"
                                        onChange={
                                          UpdatePricingPlan.handleChange
                                        }
                                        // onChange={handleDateChange}
                                        value={
                                          UpdatePricingPlan.values.valid_from
                                        }
                                        autoComplete="off"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="valid_till"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Valid Till
                                    </label>
                                    <div className="mt-1">
                                      <input
                                        type="date"
                                        name="valid_till"
                                        id="valid_till"
                                        // onChange={handleDateChange}
                                        onChange={
                                          UpdatePricingPlan.handleChange
                                        }
                                        value={
                                          UpdatePricingPlan.values.valid_till
                                        }
                                        autoComplete="off"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>  
                                  {/* <div className="sm:col-span-3">
                                    <label
                                      htmlFor="valid_for_days"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                    Valid For Days
                                    </label>
                                    <div className="mt-1">
                                      <input
                                        type="number"
                                       
                                        name="valid_for_days"
                                        id="valid_for_days"
                                        value={createNewPricingPlan.values.valid_for_days}
                                        readOnly
                                        autoComplete="off"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div> */}
                                  {/* <div className="sm:col-span-3">
                                    <label
                                      htmlFor="installments_allowed"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Installments Allowed
                                    </label>
                                    <div className="mt-1">
                                      <input
                                        id="installments_allowed"
                                        name="installments_allowed"
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                        className="h-7 w-7 ml-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* editedLoading */}
                          <div className="flex flex-shrink-0 justify-end mt-4  px-4 py-4 pt-10">
                            {updatedLoading ? (
                              <button
                                type="button"
                                className="ml-4 w-32 inline-flex justify-center rounded-md banner-back px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                              >
                                <div role="status">
                                  <svg
                                    aria-hidden="true"
                                    class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-white"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </button>
                            ) : (
                              <>
                                {" "}
                                <button
                                  type="button"
                                  className="rounded-md w-32 bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                  onClick={() => {
                                    setPriceBreakDownDetailOpen(false);
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="ml-4 w-32 inline-flex justify-center rounded-md banner-back px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                >
                                  Update
                                </button>
                              </>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* create Pricing plan */}
        <Transition.Root show={openAddNewPlan} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpenAddNewPlan}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6 banner-back">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-white">
                            Create New Conference Pricing Plans
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md  text-gray-900 hover:text-gray-500 focus:ring-2 "
                              onClick={() => setOpenAddNewPlan(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      <div className="divide-y items-center divide-gray-200">
                        <form
                          onSubmit={createNewPricingPlan.handleSubmit}
                          className="flex  flex-col divide-y  divide-gray-200 bg-white "
                        >
                          <div className="">
                            <div className="space-y-4 px-4">
                              <div className="mt-2 border-gray-900/10 ">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                  Plan Information
                                </h2>

                                <div className="mt-3 grid grid-cols-1 gap-x-3 gap-y-3 sm:grid-cols-6">
                                  <div className="col-span-3">
                                    <label
                                      htmlFor="plan_name"
                                      className="block text-sm font-medium text leading-6 text-gray-900"
                                    >
                                      Plan Name{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <div className="mt-1">
                                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
                                        <input
                                          type="text"
                                          name="plan_name"
                                          id="plan_name"
                                          onChange={
                                            createNewPricingPlan.handleChange
                                          }
                                          value={
                                            createNewPricingPlan.values
                                              .plan_name
                                          }
                                          autoComplete="off"
                                          className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-span-3">
                                    <label
                                      htmlFor="processing_fee"
                                      className="block text-sm font-medium text leading-6 text-gray-900"
                                    >
                                      Processing Fee{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <div className="mt-1">
                                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
                                        <input
                                          type="number"
                                          step="0.01"
                                          min={0}
                                          name="processing_fee"
                                          id="processing_fee"
                                          onChange={
                                            createNewPricingPlan.handleChange
                                          }
                                          value={
                                            createNewPricingPlan.values
                                              .processing_fee
                                          }
                                          autoComplete="off"
                                          className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                   {/* <div className="col-span-3">
                                    <label
                                      htmlFor="base_price"
                                      className="block text-sm font-medium text leading-6 text-gray-900"
                                    >
                                      Base Price{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <div className="mt-1">
                                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
                                        <input
                                          type="number"
                                          step="0.01"
                                          min={0}
                                          name="base_price"
                                          id="base_price"
                                          onChange={
                                            createNewPricingPlan.handleChange
                                          }
                                          value={
                                            createNewPricingPlan.values.base_price
                                          }
                                          autoComplete="off"
                                          className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                  </div> */}
                                  {/* <div className="col-span-6">
                                    <div>
                                      <span className="block text-sm font-medium text-gray-700 pb-1.5">
                                        Conference
                                      </span>

                                      <Listbox
                                        value={selected}
                                        onChange={setSelected}
                                      >
                                        {({ open }) => (
                                          <>
                                            <Listbox.Label className="block sr-only text-sm font-medium leading-6 text-gray-900">
                                              Assigned to
                                            </Listbox.Label>
                                            <div className="relative mt-0">
                                              <Listbox.Button className="relative w-full  cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900  ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                <span className="block truncate">
                                                  {selected.title}
                                                </span>
                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                  <ChevronUpDownIcon
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              </Listbox.Button>

                                              <Transition
                                                show={open}
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                              >
                                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                  {getAllEvents?.map(
                                                    (person) => (
                                                      <Listbox.Option
                                                        key={person.name}
                                                        className={({
                                                          active,
                                                        }) =>
                                                          classNames(
                                                            active
                                                              ? "bg-indigo-600 text-white"
                                                              : "text-gray-900",
                                                            "relative cursor-default select-none py-2 pl-3 pr-9"
                                                          )
                                                        }
                                                        value={person}
                                                      >
                                                        {({
                                                          selected,
                                                          active,
                                                        }) => (
                                                          <>
                                                            <span
                                                              className={classNames(
                                                                selected
                                                                  ? "font-semibold"
                                                                  : "font-normal",
                                                                "block truncate"
                                                              )}
                                                            >
                                                              {person.title}
                                                            </span>

                                                            {selected ? (
                                                              <span
                                                                className={classNames(
                                                                  active
                                                                    ? "text-white"
                                                                    : "text-indigo-600",
                                                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                                                )}
                                                              >
                                                                <CheckIcon
                                                                  className="h-5 w-5"
                                                                  aria-hidden="true"
                                                                />
                                                              </span>
                                                            ) : null}
                                                          </>
                                                        )}
                                                      </Listbox.Option>
                                                    )
                                                  )}
                                                </Listbox.Options>
                                              </Transition>
                                            </div>
                                          </>
                                        )}
                                      </Listbox>
                                    </div>
                                  </div> */}
                                  {/* <div className="sm:col-span-3">
                                    <label
                                      htmlFor="valid_from"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Valid From
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="date"
                                        name="valid_from"
                                        id="valid_from"
                                        onChange={
                                          createNewPricingPlan.handleChange
                                        }
                                        value={
                                          createNewPricingPlan.values.valid_from
                                        }
                                        autoComplete="off"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="valid_till"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Valid Till
                                    </label>
                                    <div className="mt-1">
                                      <input
                                        type="date"
                                        name="valid_till"
                                        id="valid_till"
                                        onChange={
                                          createNewPricingPlan.handleChange
                                        }
                                        value={
                                          createNewPricingPlan.values.valid_till
                                        }
                                        autoComplete="off"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                              <div className="  border-gray-900/10 pb-2">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                  BreakDown Information
                                </h2>

                                <div className="mt-3 grid grid-cols-1 gap-x-3 gap-y-3 sm:grid-cols-6">
                                  <div className="col-span-3">
                                    <label
                                      htmlFor="accommodation_pricing"
                                      className="block text-sm font-medium text leading-6 text-gray-900"
                                    >
                                      Accommodation Price
                                    </label>
                                    <div className="mt-1">
                                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
                                        <input
                                          type="number"
                                          step="0.01"
                                          min={0}
                                          name="accommodation_pricing"
                                          id="accommodation_pricing"
                                          onChange={
                                            createNewPricingPlan.handleChange
                                          }
                                          value={
                                            createNewPricingPlan.values.accommodation_pricing
                                          }
                                          autoComplete="off"
                                          className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-span-3">
                                    <label
                                      htmlFor="non_accommodation_pricing"
                                      className="block text-sm font-medium text leading-6 text-gray-900"
                                    >
                                      Non Accommodation Price
                                    </label>
                                    <div className="mt-1">
                                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
                                        <input
                                          type="number"
                                          step="0.01"
                                          min={0}
                                          name="non_accommodation_pricing"
                                          id="non_accommodation_pricing"
                                          onChange={
                                            createNewPricingPlan.handleChange
                                          }
                                          value={
                                            createNewPricingPlan.values.non_accommodation_pricing
                                          }
                                          autoComplete="off"
                                          className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-span-3">
                                    <label
                                      htmlFor="full_experience_pricing"
                                      className="block text-sm font-medium text leading-6 text-gray-900"
                                    >
                                      Full Experience Price
                                    </label>
                                    <div className="mt-1">
                                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
                                        <input
                                          type="number"
                                          step="0.01"
                                          min={0}
                                          name="full_experience_pricing"
                                          id="full_experience_pricing"
                                          onChange={
                                            createNewPricingPlan.handleChange
                                          }
                                          value={
                                            createNewPricingPlan.values.full_experience_pricing
                                          }
                                          autoComplete="off"
                                          className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="valid_from"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Valid From
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="date"
                                        name="valid_from"
                                        id="valid_from"
                                        onChange={
                                          createNewPricingPlan.handleChange
                                        }
                                        // onChange={handleDateChange}
                                        value={
                                          createNewPricingPlan.values.valid_from
                                        }
                                        autoComplete="off"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="valid_till"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Valid Till
                                    </label>
                                    <div className="mt-1">
                                      <input
                                        type="date"
                                        name="valid_till"
                                        id="valid_till"
                                        // onChange={handleDateChange}
                                        onChange={
                                          createNewPricingPlan.handleChange
                                        }
                                        value={
                                          createNewPricingPlan.values.valid_till
                                        }
                                        autoComplete="off"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>  
                                  {/* <div className="sm:col-span-3">
                                    <label
                                      htmlFor="valid_for_days"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                    Valid For Days
                                    </label>
                                    <div className="mt-1">
                                      <input
                                        type="number"
                                       
                                        name="valid_for_days"
                                        id="valid_for_days"
                                        value={createNewPricingPlan.values.valid_for_days}
                                        readOnly
                                        autoComplete="off"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div> */}
                                  {/* <div className="sm:col-span-3">
                                    <label
                                      htmlFor="installments_allowed"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Installments Allowed
                                    </label>
                                    <div className="mt-1">
                                      <input
                                        id="installments_allowed"
                                        name="installments_allowed"
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                        className="h-7 w-7 ml-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* editedLoading */}
                          <div className="flex flex-shrink-0 justify-end mt-4  px-4 py-4 pt-10">
                            {editedLoading ? (
                              <button
                                type="button"
                                className="ml-4 w-32 inline-flex justify-center rounded-md banner-back px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                              >
                                <div role="status">
                                  <svg
                                    aria-hidden="true"
                                    class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-white"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </button>
                            ) : (
                              <>
                                {" "}
                                <button
                                  type="button"
                                  className="rounded-md w-32 bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                  onClick={() => {
                                    setOpenAddNewPlan(false);
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="ml-4 w-32 inline-flex justify-center rounded-md banner-back px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                >
                                  Save
                                </button>
                              </>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* <Transition.Root show={updatePricingPlanOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setUpdatePricingPlanOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                  <form className="" onSubmit={UpdatePricingPlan.handleSubmit}>
                    <div>
                      <div className=" pt-2">
                        <h2 className="text-lg font-medium text-gray-900">
                          Shipping information
                        </h2>

                        <div className="mt-4 grid grid-cols-4 gap-y-6 sm:gap-x-4">
                          <div className="col-span-2">
                            <label
                              htmlFor="plan_name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Plan Name
                            </label>
                            <div className="mt-1 ">
                              <input
                                type="text"
                                id="plan_name"
                                name="plan_name"
                                onChange={UpdatePricingPlan.handleChange}
                                value={UpdatePricingPlan.values.plan_name}
                                autoComplete="off"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          <div className="col-span-2">
                            <label
                              htmlFor="processing_fee"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Processing Fee
                            </label>
                            <div className="mt-1 ">
                              <input
                                type="text"
                                id="processing_fee"
                                name="processing_fee"
                                autoComplete="off"
                                onChange={UpdatePricingPlan.handleChange}
                                value={UpdatePricingPlan.values.processing_fee}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          <div className="col-span-2">
                            <label
                              htmlFor="valid_from"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Valid From
                            </label>
                            <div className="mt-1">
                              <input
                                type="date"
                                name="valid_from"
                                id="valid_from"
                                onChange={UpdatePricingPlan.handleChange}
                                value={UpdatePricingPlan.values.valid_from}
                                autoComplete="off"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          <div className="col-span-2">
                            <label
                              htmlFor="valid_till"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Valid Till
                            </label>
                            <div className="mt-1">
                              <input
                                type="date"
                                name="valid_till"
                                id="valid_till"
                                onChange={UpdatePricingPlan.handleChange}
                                value={UpdatePricingPlan.values.valid_till}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          <div className="col-span-full">
                            <h2 className="text-lg font-medium text-gray-900">
                              BreakDown information
                            </h2>
                          </div>
                          <div className="col-span-2">
                            <label
                              htmlFor="accommodation_price"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Accommodation Price
                            </label>
                            <div className="mt-1">
                              <input
                                type="number"
                                name="accommodation_price"
                                id="accommodation_price"
                                onChange={UpdatePricingPlan.handleChange}
                                value={
                                  UpdatePricingPlan.values.accommodation_price
                                }
                                autoComplete="off"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          <div className="col-span-2">
                            <label
                              htmlFor="non_accommodation_price"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Non Accommodation Price
                            </label>
                            <div className="mt-1">
                              <input
                                type="number"
                                name="non_accommodation_price"
                                id="non_accommodation_price"
                                onChange={UpdatePricingPlan.handleChange}
                                value={
                                  UpdatePricingPlan.values
                                    .non_accommodation_price
                                }
                                autoComplete="off"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          <div className="col-span-2">
                            <label
                              htmlFor="full_experience_price"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Full Experience Price
                            </label>
                            <div className="mt-1">
                              <input
                                type="number"
                                name="full_experience_price"
                                id="full_experience_price"
                                onChange={UpdatePricingPlan.handleChange}
                                value={
                                  UpdatePricingPlan.values.full_experience_price
                                }
                                autoComplete="off"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          <div className="col-span-2">
                            <label
                              htmlFor="valid_froms"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Valid From
                            </label>
                            <div className="mt-1">
                              <input
                                type="date"
                                name="valid_froms"
                                id="valid_froms"
                                onChange={UpdatePricingPlan.handleChange}
                                value={UpdatePricingPlan.values.valid_froms}
                                autoComplete="off"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          <div className="col-span-2">
                            <label
                              htmlFor="valid_tills"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Valid Till
                            </label>
                            <div className="mt-1">
                              <input
                                type="date"
                                name="valid_tills"
                                id="valid_tills"
                                onChange={UpdatePricingPlan.handleChange}
                                value={UpdatePricingPlan.values.valid_tills}
                                autoComplete="off"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          <div className="col-span-2">
                            <label
                              htmlFor="installments_allowed"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Installments Allowed
                            </label>
                            <div className="mt-1">
                              <input
                                id="installments_allowed"
                                name="installments_allowed"
                                type="checkbox"
                                checked={isCheckedUpdate}
                                onChange={handleCheckboxUpdate}
                                className="h-7 w-7 ml-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                            </div>
                          </div>
                          <div className="col-span-full flex justify-end">
                            <div className="flex justify-center gap-4 items-center">
                              {updatedLoading ? (
                                <button
                                  type="button"
                                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                >
                                  <div role="status">
                                    <svg
                                      aria-hidden="true"
                                      class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                      viewBox="0 0 100 101"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                      />
                                    </svg>
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                </button>
                              ) : (
                                <>
                                  {" "}
                                  <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                    onClick={() => {
                                      setUpdatePricingPlanOpen(false);
                                      UpdatePricingPlan.resetForm();
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="submit"
                                    className="inline-flex w-full justify-center rounded-md banner-back px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 sm:col-start-2"
                                  >
                                    Update
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root> */}
    </>
  );
};

export default PricingBreakDown;
