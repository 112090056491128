import {
    ChevronLeftIcon,
    ChevronRightIcon
} from "@heroicons/react/20/solid";
import {
    ArrowLeftIcon
} from "@heroicons/react/24/outline";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const PointsAudit = () => {
    const { ID } = useParams();
    var JSToken;
    var accessToken;
    if (localStorage.user) {
        // Role = localStorage.role;
        // userEmail = localStorage.user_email;
        JSToken = JSON.parse(localStorage.user);
        accessToken = JSToken.access_token;
    }
    const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
    };

    const [pointsDetail, setpointsDetail] = useState([]);
    const [loadingSkeleton, setLoadingSkeleton] = useState(false);
    const [totalAmbs, setTotalAmbs] = useState();
    const [isNotFoundAmb, setIsNotFoundAmb] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumbers, setpageNumber] = useState([]);
    const tableRef = useRef(null);

    useEffect(() => {
        setLoadingSkeleton(true);
        const fetchAmbData = async () => {
            try {
                await axios
                    .get(
                        `${window.$domain}/api/method/representative-points-audit?filters=[["representative_profile","=","${ID}"]]`,
                        config
                    )
                    .then((response) => {
                        setpointsDetail(response.data.data);
                        setpageNumber([]);
                        for (
                            let i = 1;
                            i <= Math.ceil(response.data.data.length / 40);
                            i++
                        ) {
                            setpageNumber((prevState) => [...prevState, i]);
                        }
                        setTotalAmbs(response.data.data.length);
                        setLoadingSkeleton(false);
                        setIsNotFoundAmb(false);
                        if (response.data.data.length == []) {
                            setLoadingSkeleton(true);
                            setIsNotFoundAmb(true);
                        }
                    });
            } catch (e) {
                console.log(e);
                if (e.code == "ERR_NETWORK") {
                    setIsNotFoundAmb(true);
                }
            }
        };
        fetchAmbData();
    }, []);

    const scrollToTop = () => {
        tableRef.current.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleClick = (number) => {
        setCurrentPage(number);
        scroll();
        scrollToTop();
    };
    
    const scroll = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const handlePaginationClick = (event) => {
        if (event == "Previous") {
            if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
                scroll();
                scrollToTop();
            }
        } else if (event == "Next") {
            if (currentPage < pageNumbers.length) {
                setCurrentPage(currentPage + 1);
                scroll();
                scrollToTop();
            }
        }
    };

    const goBack = () => {
        window.history.back();
      };

    return (
        <>
            <span onClick={() => goBack()} className="hover:cursor-pointer pt-5 pb-3 flex justify-start items-center">
                <ArrowLeftIcon className="h-5 w-5 mr-1 flex justify-center items-center  text-gray-500 cursor-pointer" /> Back</span>
            <div className="px-2 sm:px-4   lg:px-4">
                <div className=" flex flex-col">
                    <div
                        ref={tableRef}
                        className="my-2 -mx-4 overflow-x-auto max-h-screen sm:-mx-4 lg:-mx-4"
                    >
                        <div
                            ref={tableRef}
                            className="inline-block overflow-x-auto min-w-full py-1 align-middle md:px-1 lg:px-1"
                        >
                            {isNotFoundAmb ? (
                                <div className="h-screen flex justify-center flex-col items-center ">
                                    <svg
                                        fill="none"
                                        className="w-40 h-40 text-gray-400"
                                        stroke="currentColor"
                                        strokeWidth={1.5}
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
                                        />
                                    </svg>{" "}
                                    <span className=" text-lg text-gray-400"> No Data Found</span>
                                </div>
                            ) : (
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    {" "}
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-stone-100">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                                >
                                                    Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Event
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Points Allocated
                                                </th>

                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Reason
                                                </th>
                                            </tr>
                                        </thead>
                                        {loadingSkeleton ? (
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                <tr>
                                                    <td className="whitespace-nowrap py-3 pl-4  text-sm sm:pl-6">
                                                        <div className="flex items-center">
                                                            <div className="h-14 w-14 flex-shrink-0">
                                                                <Skeleton
                                                                    borderRadius={40}
                                                                    width={50}
                                                                    height={50}
                                                                />
                                                            </div>
                                                            <div className="ml-2">
                                                                <div className="font-medium cursor-pointer text-gray-900">
                                                                    <Skeleton width={90} height={10} />
                                                                </div>
                                                                <div className="text-gray-500">
                                                                    <Skeleton width={80} height={10} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <Skeleton />
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <Skeleton />
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <Skeleton />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {pointsDetail
                                                    ?.slice((currentPage - 1) * 40, currentPage * 40)
                                                    .map((detail) => (
                                                        <tr key={detail.name}>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                                <div className="flex items-center">
                                                                    <div className="ml-4">
                                                                        <div
                                                                            className="font-medium cursor-pointer text-gray-900"
                                                                        >
                                                                            {detail?.delegate_profile?.user_first_name}{" "}
                                                                            {detail?.delegate_profile?.user_last_name}
                                                                        </div>
                                                                        <div className="text-gray-500">
                                                                            {detail.delegate_profile.email}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                <div className="text-gray-900">
                                                                    {detail?.corresponding_lead?.event_title}
                                                                </div>
                                                                <div className="text-gray-500"></div>
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                <div className="font-semibold leading-5 text-red-800">
                                                                    {detail?.added_points}
                                                                </div>
                                                                <div className="text-gray-500"></div>
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {detail.reason == null ||
                                                                    detail.reason == "" ? (
                                                                    <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                                                        Missing
                                                                    </span>
                                                                ) : <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                                                    {detail.reason}
                                                                </span>}
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        )}
                                    </table>{" "}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {isNotFoundAmb ? null : (
                    <div className="flex items-center justify-between mt-7 bg-white px-3 py-3 sm:px-3  rounded-lg shadow">
                        <div className="flex flex-1 justify-between sm:hidden">
                            <button
                                onClick={() => {
                                    handlePaginationClick("Previous");
                                }}
                                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                            >
                                Previous
                            </button>
                            <button
                                onClick={() => {
                                    handlePaginationClick("Next");
                                }}
                                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                            >
                                Next
                            </button>
                        </div>
                        <div className="hidden sm:flex sm:flex-1 sm:items-start sm:flex-col gap-y-1 sm:justify-between">
                            <div>
                                <p className="text-sm text-gray-700">
                                    Showing{" "}
                                    <span className="font-medium">
                                        {(currentPage - 1) * 40 + 1}
                                    </span>{" "}
                                    to{" "}
                                    <span className="font-medium">
                                        {/* {currentPage * (totalAmbs % 40)} */}
                                        {Math.min(((currentPage - 1) * 40 + 1) + 40, totalAmbs)}
                                    </span>{" "}
                                    of <span className="font-medium">{totalAmbs}</span> results
                                </p>
                            </div>
                            <div>
                                <nav
                                    className="isolate inline-flex flex-wrap gap-y-1 -space-x-px rounded-md shadow-sm"
                                    aria-label="Pagination"
                                >
                                    <button
                                        onClick={() => {
                                            handlePaginationClick("Previous");
                                        }}
                                        className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                                    >
                                        <span className="sr-only">Previous</span>
                                        <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
                                    </button>
                                    {pageNumbers?.map((number) => {
                                        return (
                                            <button
                                                key={number}
                                                className={
                                                    currentPage == number
                                                        ? "relative z-10 inline-flex items-center border border-purple-500 bg-purple-50 px-2 py-1.5 text-sm font-medium text-purple-600 focus:z-20"
                                                        : "relative hidden items-center border border-gray-300 bg-white px-2 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex"
                                                }
                                                onClick={() => handleClick(number)}
                                            >
                                                {number}
                                            </button>
                                        );
                                    })}

                                    <button
                                        onClick={() => {
                                            handlePaginationClick("Next");
                                        }}
                                        className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                                    >
                                        <span className="sr-only">Next</span>
                                        <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                                    </button>
                                </nav>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default PointsAudit;
