import React from 'react'
import axios from "axios";
import { Toaster, toast } from 'sonner'

// import {
//   CloudArrowDownIcon,
//   ListBulletIcon,
//   DocumentTextIcon,
//   IdentificationIcon,
//   CreditCardIcon,
//   PaperAirplaneIcon,
//   UserPlusIcon,
//   PencilIcon,
// } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import { Fragment, useState ,useEffect,useRef} from "react";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Done from "../Alerts/Done";
import Papa from "papaparse";
import Tag from "../Tag/Tag";
import PrivateImage from '../PrivateImage/PrivateImage';
// import moment from "moment";

var userEmail;
var JSToken;
var accessToken;
if (localStorage.user) {
  userEmail = localStorage.user_email;
  JSToken = JSON.parse(localStorage.user);
  accessToken = JSToken.access_token;
}
const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};

const RoomAllocation = () => {
  const { eventID } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [popUPImage, setPopUPImage] = useState(false);
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [personDetail, setPersonDetail] = useState(null);
  

  const [allRoomSharing, setAllRoomSharing] = useState(null);
  const [totalRoomCount, setTotalRoomCount] =useState(0);
 
  useEffect(() => {
    setLoadingSkeleton(true)
    const fetchRoomSharing = async () => {
      try {
        setLoading(true);
        const result = await axios
          .get(
            `https://dash.bestdiplomats.org/api/method/ops-rooming-list?event=${eventID}`,
            config
          )
          .then((response) => {
            // console.log(">>>>> room",response.data.data)
            setTotalRoomCount(response.data.data.length);
            setAllRoomSharing(response.data.data);
            setLoadingSkeleton(false)
            if(response.data.data.length==[]){
              toast.error('No Data Found')
              setLoadingSkeleton(true)
              setIsNotFound(true);
            }
          });
        setLoading(false);
      } catch (e) {
        console.log(e);
        if (e.code == "ERR_NETWORK") {
          toast.error('No Data Found')
          setLoadingSkeleton(true)
          setIsNotFound(true);
        }
      }
    };
    fetchRoomSharing();
  }, []);

  const handleChangeIfImage = () => {
    setPopUPImage(true);
  };

  const Handleviewdetail=(person)=>{
    setPersonDetail(person)
    
   
    setOpen(true)
  }
  const handleDownload = () => {
    const newData = allRoomSharing?.map((item) => ({
     
      Name: item?.delegate_profile?.user?.full_name,
     
      Gender:item?.delegate_profile.gender,
      Representing_Country: item?.delegate_profile?.representing_country,
      Nationality: item?.delegate_profile?.nationality,
     
      Payment_Package: item?.payment_package  ,
      Hotel_Room_Number: item?.hotel_room_number,
      Room_Status : item?.room_status,
      Passport_Number: item?.delegate_profile?.passport_number ,
      Passport_Expiry: item?.delegate_profile?.passport_expiry,
      Tshirt_Size: item?.delegate_profile?.tshirt_size==""||item?.delegate_profile?.tshirt_size==null||item?.delegate_profile?.tshirt_size=="Not Selected"?"N/A":item?.delegate_profile?.tshirt_size,
      Food_Preference: item?.delegate_profile?.food_preference==""||item?.delegate_profile?.food_preference==null||item?.delegate_profile?.food_preference=="Not Selected"?"N/A":item?.delegate_profile?.food_preference,
      Visa_Scan: item?.delegate_profile?.visa_scan == "" || item?.delegate_profile?.visa_scan == null ? "N/A" : "https://dash.bestdiplomats.org" + item?.delegate_profile?.visa_scan,
      // Flight_Arrival_Time:item?.delegate_profile?.flight_arrival_time == "" || item?.delegate_profile?.flight_arrival_time == null? "N/A" : item?.delegate_profile?.flight_arrival_time,
  
        }));
  
    const csv = Papa.unparse(newData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "my-file.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
    <div className="flex justify-end mt-3 gap-2">
      
         {/* <div className=" absolute top-14 left-6">
          {isNotFound && <Done text="No Data Found" color="red" />}
        </div>
        <div className=" absolute top-14 left-6">
          {isNotFound && <Done text="No Data Found" color="red" />}
        </div> */}
      <div className="">

        <div className="mt-4 mb-0.5 flex justify-end items-center  sm:mt-0 sm:ml-1 sm:flex-none">
        {loadingSkeleton ?<><Skeleton width={80} height={40} /> </>:   <button
          className="ml-3 inline-flex justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          onClick={() => handleDownload()}
        >
          Download CSV
        </button>}
    
        </div>
      </div>
    </div>
    <div className="py-1">
      <p className="felx justify-end text-right font-semibold">
      {loadingSkeleton ?<><Skeleton width={40} height={20} /> </>: <> Allocations: {totalRoomCount}</>}
      </p>
    </div>
<div className='px-2'>
    <div className="mt-2 flex flex-col max-h-screen">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-6">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          { isNotFound? <div className="min-w-full bg-slate-100 rounded-lg font-bold text-red-500 p-8 flex justify-center items-center h-20 divide-gray-300">
                                  No Data Found
            </div>:  <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-stone-100">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                  Name & Email
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                   Hotel Room Number
                  </th>
                  
                
                  <th
                    scope="col"
                    className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Payment Package
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                   Room Status
                  </th>
                  <th
                    scope="col"
                    className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                  >
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
             {  loadingSkeleton? <tbody className="divide-y divide-gray-200 bg-white">
                   
                   <tr >
                     <td className="whitespace-nowrap py-3 pl-4  text-sm sm:pl-6">
                     <div className="flex items-center">
                          <div
                            className="h-14 w-14 flex-shrink-0"
                           
                          >
                              <Skeleton borderRadius={40} width={50}  height={50}/>
                          </div>
                          <div className="ml-2">
                            <div
                            
                              className="font-medium cursor-pointer text-gray-900"
                            >
                               <Skeleton  width={90}  height={10}/>
                            </div>
                            <div className="text-gray-500">
                            <Skeleton  width={80}  height={10}/>
                            </div>
                          </div>
                        </div>
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                     <Skeleton />
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       <div className="text-gray-900">
                       <Skeleton />
                       </div>
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       
                           <Skeleton />
                        
                     </td>
                    

                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       <div className="text-gray-900">
                       <Skeleton />
                       </div>
                     </td>
                   </tr>
                 
               </tbody>:<tbody className="divide-y divide-gray-200 bg-white">
             
                 { allRoomSharing?.map((person) => (
                    <tr key={person.name}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0"   onClick={() => {
                                Handleviewdetail(person);
                              }}>
                          <PrivateImage imageUrl={person?.delegate_profile?.user?.user_image} className={"h-10 w-10 rounded-full cursor-pointer"}/> 
                           
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900 cursor-pointer"  onClick={() => {
                                Handleviewdetail(person);
                              }}>
                              {person?.delegate_profile?.user?.first_name +
                                " " +
                                person?.delegate_profile?.user?.last_name}
                            </div>
                            <div className="text-gray-500">
                              {person?.delegate_profile?.user?.email
}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {person?.hotel_room_number===0?(<span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-700">
                      {person?.hotel_room_number}
                          </span>):(<span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          {person?.hotel_room_number}
                        </span> )}
                        
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        
                          {person?.payment_package}
                       
                      </td>
                    
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          {person?.room_status}
                        </span>
                      </td>
                     
                    </tr>
                  ))
                }
              </tbody>}
            </table>}
          </div>
        </div>
      </div>
    </div>
    </div>
        {/* nationalty for Filters */}
        <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Registration
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-blue-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      <div className="divide-y divide-gray-200">
                        <div className="pb-6">
                          <div className="h-24  sm:h-20 lg:h-28 bg-blue-500" />
                          <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                            <div>
                              <div className="-m-1 flex">
                                <div className="inline-flex overflow-hidden rounded-lg ">
                                <PrivateImage imageUrl={  personDetail?.delegate_profile?.user?.user_image} className={"h-24 w-24  flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"}/> 
                                 
                                </div>
                              </div>
                            </div>
                            <div className="mt-6 sm:ml-6 sm:flex-1">
                              <div>
                                <div className="flex items-center">
                                  <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                    {personDetail?.delegate_profile.user
                                      .first_name +
                                      " " +
                                      personDetail?.delegate_profile.user
                                        .last_name}
                                  </h3>
                                </div>
                                <p className="text-sm text-gray-500">
                                  {personDetail?.delegate_profile?.user.email}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-4 py-5 sm:px-0 sm:py-0">
                          <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                            
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Checkin Passport Scan

                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {personDetail?.checkin_passport_scan?(<div   onClick={()=>handleChangeIfImage()} className="h-14 w-14 cursor-pointer flex-shrink-0 sm:h-40 sm:w-40 lg:h-18 lg:w-18">  <PrivateImage imageUrl={personDetail?.checkin_passport_scan?.split("https://dash.bestdiplomats.org")[1]} className={"h-14 w-14 cursor-pointer flex-shrink-0 sm:h-40 sm:w-40 lg:h-18 lg:w-18"}/> </div>
                              
                              ):(
                                <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                Missing
                              </span>
                              
                             ) }</dd>
                            </div>

                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Hotel Room Number

                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                             {personDetail?.hotel_room_number===0?<span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">
                              {personDetail?.hotel_room_number}
                        </span>: <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                              {personDetail?.hotel_room_number}
                        </span>}
                               

                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Room Status
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                           <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                              {personDetail?.room_status}
                              </span>
                              </dd>
                            </div>

                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Contact Number

                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {personDetail?.delegate_profile?.contact_number}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Representing Country

                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                             {personDetail?.representing_country}
                              </dd>
                            </div>
                          
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Payment Package

                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                               
                              {personDetail?.payment_package}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Address

                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {personDetail?.delegate_profile.address}
                              </dd>
                            </div>
                          
                          </dl>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
            {/* Popup for Picture */}
            <Transition.Root show={popUPImage} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setPopUPImage}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-2 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-2 pt-5 pb-2  shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="flex justify-center items-center bg-orange-200">
                  <PrivateImage imageUrl={ personDetail?.checkin_passport_scan?.split("https://dash.bestdiplomats.org")[1]} /> 
                      
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    {/* <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                 
                </button> */}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-40 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setPopUPImage(false)}
                      ref={cancelButtonRef}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
   
  </>
  )
}

export default RoomAllocation