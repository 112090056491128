import React from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./app/store";
import 'react-loading-skeleton/dist/skeleton.css'


// Live
window.$FWDurl='https://app.bestdiplomats.org';
window.$ClientID='2316170aff';
// staging
// window.$FWDurl='https://staging.bestdiplomats.org';
// window.$ClientID='0b577ea7dd';

// // Local
// window.$FWDurl='http://localhost:3000';
// window.$ClientID='dbaa0a84cf';


window.$domain='https://dash.bestdiplomats.org';



// Sentry.init({
//   dsn: "https://2ac45269df6840bebbd423ee81da3991@o4504679372881920.ingest.sentry.io/4504679374389248",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
