import React from "react";
import axios from "axios";
import { Toaster, toast } from "sonner";
import { useFormik } from "formik";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

import moment from "moment";
import {
  XMarkIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
  ExclamationTriangleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Papa from "papaparse";
import { ClearLocalStorage } from "../../ClearLocalStorage";
import PrivateImage from "../../PrivateImage/PrivateImage";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const HotelRooms = () => {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const inputRef = useRef(null);
  const { eventID } = useParams();
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [isNotFound, setIsNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showRoomNumberPoUp, setShowRoomNumberPoUp] = useState(false);
  const [popcheckinselfieImage, setPopcheckinselfieImage] = useState(false);
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [showDeletepoup, setShowDeletePoUp] = useState(false);
  const [personDetail, setPersonDetail] = useState(null);
  const [loadingBeforeSend, setLoadingBeforeSend] = useState(false);
  const [UpdatePopUp, setUpdatePopUp] = useState(false);
  const [UpdateRoommatePopUp, setUpdateRoommatePopUp] = useState(false);
  const [UpdateRoomPopUp, setUpdateRoomPopUp] = useState(false);

  const [UpdateData, setUpdateData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setpageNumber] = useState([]);

  const [apiFilters, setApiFilters] = useState(`["event","=","${eventID}"]`);
  const [page, setPage] = useState(50);
  const [getAllRoomNumber, setGetAllRoomNumber] = useState(null);
  const [totalRoomCount, setTotalRoomCount] = useState(0);
  const tableRef = useRef(null);

  const scrollToTop = () => {
    tableRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // setLoadingSkeleton(true);
    // console.log("apiFilters", apiFilters);
    const fetchRoomSharing = async () => {
      try {
        setLoading(true);

        setIsLoading(true);
        const result = await axios
          .get(
            `https://dash.bestdiplomats.org/api/resource/Hotel Rooms?fields=["*"]&filters=[${apiFilters}]&limit_start=0&limit_page_length=300`,
            config
          )
          .then((response) => {
            // console.log(">>>>> room", response.data.data);
            setpageNumber([]);
            for (
              let i = 1;
              i <= Math.ceil(response.data.data.length / 30);
              i++
            ) {
              setpageNumber((prevState) => [...prevState, i]);
              // console.log(pageNumbers);
            }
            setTotalRoomCount(response.data.data.length);
            setGetAllRoomNumber(response.data.data);
            setLoadingSkeleton(false);
            setIsNotFound(false);
            setIsLoading(false);
            if (response.data.data.length == []) {
              toast.error("No Data Found");
              setLoadingSkeleton(true);
              setIsNotFound(true);
            }
          });
        setLoading(false);
      } catch (e) {
        if (
          JSON.parse(JSON.stringify(e)).message ==
          "Request failed with status code 403"
        ) {
          ClearLocalStorage(JSON.parse(JSON.stringify(e)));
        } else {
          // console.log(JSON.parse(JSON.stringify(e)));
        }
        toast.error("No Data Found");
        setLoadingSkeleton(true);
        setIsNotFound(true);
      }
    };
    fetchRoomSharing();
  }, [page, UpdateData, apiFilters]);
  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 50);
    // console.log("22", page);
  };
  const handleClick = (number) => {
    // setCurrentPage(number);
    scroll();
    scrollToTop();
  };
  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    scrollToTop();
  };
  const [RommateOne, setRommateOne] = useState(null);
  const [RommateTwo, setRommateTwo] = useState(null);
  const CheckDetailRoomateOne = async (roomateoneId, roomatetwoId) => {
    setOpen(true);
    try {
      const result = await axios
        // 628865c27d
        .get(
          `https://dash.bestdiplomats.org/api/method/hotel-roommates?room_id=${roomateoneId}`,
          config
        )
        .then((response) => {
          // console.log(">>>>> Rommate one", response.data,"c024242197", roomateoneId);
          setRommateTwo(response?.data?.message?.roommate_two)
          setRommateOne(response?.data?.message?.roommate_one)
        });
    } catch (e) {
      console.log(">>>>> Rommate one",e.response);
      setRommateOne(null)
      setRommateTwo(null)
      // toast.error("No Data Found");
    }
 
  };

  const handlePaginationClick = (event) => {
    if (event == "Previous") {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        scroll();
        scrollToTop();
      }
    } else if (event == "Next") {
      if (currentPage < pageNumbers.length) {
        setCurrentPage(currentPage + 1);
        scroll();
        scrollToTop();
      }
    }
  };

  const handleAddRoom = () => {
    AddRoomNumbers.resetForm();
    setShowRoomNumberPoUp(true);
  };
  const [ShowPopupImage, setShowPopupImage] = useState(null)
  const handleChangeselfiesImage = (image) => {
    setPopcheckinselfieImage(true);
    setShowPopupImage(image)
  };

  const Handleviewdetail = (person) => {
    setPersonDetail(person);
    // console.log("person",person);
    setUpdatePopUp(true);
  };
  const HandleDelete = (person) => {
    setPersonDetail(person);
    setShowDeletePoUp(true);
  };
  const handleConfirmDelete = async () => {
    setLoadingBeforeSend(true);
    try {
      const result = await axios
        .delete(
          `https://dash.bestdiplomats.org/api/resource/Hotel Rooms/${personDetail?.name}`,
          config
        )
        .then((response) => {
          toast.success("Deleted successfully");
          setUpdateData(!UpdateData);
          setShowDeletePoUp(false);
          setLoadingBeforeSend(false);
        });
    } catch (e) {
      console.log(e);
      toast.error("Try again");
      setLoadingBeforeSend(false);
    }
  };
  const handleDownload = () => {
    const newData = getAllRoomNumber?.map((item) => ({
      Name:
        item?.linked_registration === "Guest"
          ? item?.registration?.first_name + " " + item?.registration?.last_name
          : item?.user?.first_name + " " + item?.user?.last_name,
      Gender: item?.registration?.gender,
      Registration_type: item?.linked_registration,
      Payment_Package:
        item.linked_registration == "Delegate"
          ? item?.registration?.payment_package
          : item.linked_registration == "Core Team"
          ? item?.registration?.package
          : item.linked_registration == "Observers"
          ? item?.registration?.package
          : item?.linked_registration == "Local Team"
          ? item?.registration?.package
          : item?.registration?.package,
      Hotel_Room_Number: item?.hotel_room_number,
      Room_Status: item?.room_status,
      Passport_Number: item?.registration?.passport_number,
      Passport_Expiry: item?.registration?.passport_expiry,
    }));

    const csv = Papa.unparse(newData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "my-file.csv");
    document.body.appendChild(link);
    link.click();
  };

  const [editedData, setEditedData] = useState({});
  const [editedID, setEditedID] = useState(null);
  const [editedValue, setEditedValue] = useState("");

  const AddRoomNumbers = useFormik({
    initialValues: {
      room_number: "",
      room_type: "",
    },
    onSubmit: (values) => {
      const JSONforAdd = {
        event: eventID,
        room_type: values.room_type,
        room_number: values.room_number,
      };
      setLoadingBeforeSend(true);

      const CreateRoomNumber = async () => {
        try {
          const result = await axios
            .post(
              `https://dash.bestdiplomats.org/api/resource/Hotel Rooms`,
              JSONforAdd,
              config
            )
            .then((response) => {
              // console.log(response.data.data);
              inputRef.current.focus();
              setUpdateData(!UpdateData);
              setLoadingBeforeSend(false);
              toast.success("Added successfully");
              AddRoomNumbers.resetForm();
            });
        } catch (e) {
          console.log(e.response);
          if (e.response && e.response.status === 417) {
            toast.error("Duplicate Entry Not Allowed");
          } else {
            toast.error("Try again");
          }

          setLoadingBeforeSend(false);
        }
      };
      CreateRoomNumber();
    },
    enableReinitialize: true,
  });
  const EditRoomNumber = useFormik({
    initialValues: {
      room_number: personDetail?.room_number,
      room_type: personDetail?.room_type,
    },
    onSubmit: (values) => {
      const JSONforUpdate = {
        room_type: values.room_type,
        room_number: values.room_number,
      };
      setLoadingBeforeSend(true);
      const EditRoom = async () => {
        try {
          const result = await axios
            .put(
              `https://dash.bestdiplomats.org/api/resource/Hotel Rooms/${personDetail?.name}`,
              JSONforUpdate,
              config
            )
            .then((response) => {
              //   console.log(response.data.data);
              toast.success("Updated successfully");
              setUpdateData(!UpdateData);
              setUpdatePopUp(false);
              setLoadingBeforeSend(false);
            });
        } catch (e) {
          console.log(e);
          toast.error("updated failed");
          setLoadingBeforeSend(false);
        }
      };
      EditRoom();
    },
    enableReinitialize: true,
  });
  // Formik for Filter on Room Sharing

  const applyFilters = useFormik({
    initialValues: {
      room_number: "",
      room_type: "",
      occupied_status: "",
    },
    onSubmit: (values) => {
      // console.log(values);
      if (values.room_number != "" || values.room_type != ""||values.occupied_status != "None") {
        setLoadingSkeleton(true);
      }
      setApiFilters(`["event","=","${eventID}"]`);
      setCurrentPage(1);
      // First Name % addition

      if (values.room_type) {
        if (values.room_type != "None") {
          setApiFilters((prevState) => [
            prevState + `,["room_type","=","${values.room_type}"]`,
          ]);
        } else {
          setApiFilters(`["event","=","${eventID}"]`);
        }
      }
      if (values.room_number) {
        setApiFilters((prevState) => [
          prevState + `,["room_number","=","${values.room_number}"]`,
        ]);
      }
      if (values.occupied_status) {
        if (values.occupied_status != "None") {
          setApiFilters((prevState) => [
            prevState + `,["occupied_status","=","${values.occupied_status}"]`,
          ]);
        }
      }
      
    },

    enableReinitialize: true,
  });
  const [resetRoomID,setResetRoomID]=useState(null)
  const [resetRoommateID,setResetRoommateID]=useState(null)
  const ResetRoomateStatus = async (UpdateJson) => {
    try {
      setLoading(true);
      const result = await axios
        .post(
          `https://dash.bestdiplomats.org/api/method/reset-checkin`,{id:resetRoommateID} ,
          config
        )
        .then((response) => {
        //  console.log(response.data);
         toast.success("Updated successfully");
         setUpdateData(!UpdateData);
         setLoading(false);
         setUpdateRoommatePopUp(false);
         CheckDetailRoomateOne(resetRoomID?.name)
        });
    } catch (e) {
      // console.log(e.response);
      setLoading(false);
      if (e?.response?.status==403) {
        toast.error(e.response.data.exception)  
      }else{
        toast.error("Try again later")
      }
    }
  };

  const ResetRoom = async (UpdateJson) => {
    try {
      // {room_id:resetRoomID} 
      setLoading(true);
      const result = await axios
        .post(
          `https://dash.bestdiplomats.org/api/method/reset-room`,{room_id:resetRoomID?.name} ,
          config
        )
        .then((response) => {
        //  console.log(response.data);
         toast.success("Reset full room successfully");
         setUpdateData(!UpdateData);
         setLoading(false);
         setUpdateRoomPopUp(false);
         CheckDetailRoomateOne(resetRoomID?.name)
         setOpen(false);
        });
    } catch (e) {
      console.log(e.response);
      setLoading(false);
      if (e?.response?.status==417) {
        toast.error(e.response.data.exception)  
      }else{
        toast.error("Try again later")
      }
    }
  };
  return (
    <>
      <div className="flex justify-end mt-3 gap-2">
        <form onSubmit={applyFilters.handleSubmit}>
          <div className="mt-4 flex justify-end gap-3 pb-1 items-end  sm:mt-0 sm:ml-5 sm:flex-none">
            <div className="flex flex-col gap-y-0.5 items-start">
              <label htmlFor="room_number" className=" text-sm ">
                Room Number
              </label>
              <input
                className="block w-44 rounded-md border-0 py-[6.5px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                type="number"
                min={0}
                name="room_number"
                id="room_number"
                onChange={applyFilters.handleChange}
                value={applyFilters.values.room_number}
                placeholder="Enter room number"
              />
            </div>
            <div className="flex flex-col  gap-y-0.5 items-start">
              <label htmlFor="room_type" className="text-sm">
                Room Type
              </label>
              <select
                id="room_type"
                name="room_type"
                className=" block w-44 rounded-md border-gray-300 py-2 pl-5 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                onChange={applyFilters.handleChange}
                value={applyFilters.values.room_type}
              >
                <option value="None">All</option>
                <option value="Single">Single</option>
                <option value="Twin">Twin</option>
              </select>
            </div> <div className="flex flex-col  gap-y-0.5 items-start">
              <label htmlFor="occupied_status" className="text-sm">
              
              Occupied Status

              </label>
              <select
                id="occupied_status"
                name="occupied_status"
                className=" block w-44 rounded-md border-gray-300 py-2 pl-5 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                onChange={applyFilters.handleChange}
                value={applyFilters.values.occupied_status}
              >
                <option value="None">All</option>
                <option value="Available">Available</option>
                <option value="Partially Occupied">Partially Occupied</option>
                <option value="Fully Occupied">Fully Occupied</option>
              </select>
            </div>

            <button
              type="button"
              onClick={() => {
                applyFilters.resetForm();
                setPage(50);
                setApiFilters(`["event","=","${eventID}"]`);
              }}
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2"
            >
              Clear Filter
            </button>
            <button
              type="submit"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Apply Filter
            </button>
            <button
              onClick={() => {
                handleAddRoom();
              }}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Add Room
            </button>
          </div>
        </form>
      </div>
      <div className="py-1">
        <p className="felx justify-end text-right font-semibold">
          {loadingSkeleton ? (
            <>
              {isNotFound?<>Total Rooms:0</>:<Skeleton width={40} height={20} />}
            </>
          ) : (
            <> Total Rooms: {totalRoomCount}</>
          )}
        </p>
      </div>
      <div className="px-2">
        <div className="mt-2 flex flex-col max-h-screen">
          <div
            ref={tableRef}
            className="-my-2 -mx-2  overflow-x-auto sm:-mx-5 lg:-mx-5"
          >
            <div className="inline-block min-w-full py-2 align-middle md:px-1 lg:px-1">
              {isNotFound ? (
                <div className="h-screen flex justify-center flex-col items-center ">
                  <svg
                    fill="none"
                    className="w-40 h-40 text-gray-400"
                    stroke="currentColor"
                    strokeWidth={1.5}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
                    />
                  </svg>{" "}
                  <span className=" text-lg text-gray-400"> No Data Found</span>
                </div>
              ) : (
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  {" "}
                  <table className="min-w-full divide-y  divide-gray-300">
                    <thead className="bg-stone-100">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-4"
                        >
                          Room Number
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Room Type
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Occupied Status
                        </th>{" "}
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    {loadingSkeleton ? (
                      <tbody className="divide-y divide-gray-200 bg-white">
                        <tr>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 pl-4 sm:pl-6">
                            <Skeleton />
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-gray-900">
                              <Skeleton />
                            </div>
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-gray-900">
                              <Skeleton />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody className="divide-y divide-gray-200  bg-white">
                        {getAllRoomNumber?.map((person, index) => (
                          <tr key={person?.name} 
                                  className="">
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 pl-4 sm:pl-6">
                              <span
                              
                              >
                                {person?.room_number}
                              </span>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                              <span
                                className={`inline-flex rounded-full  px-2 text-xs font-semibold leading-5  ${
                                  person.room_type == "Single"
                                    ? "bg-green-100 text-green-800"
                                    : "bg-gray-100 text-gray-800"
                                }`}
                              >
                                {person?.room_type}
                              </span>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                              <span
                                className={`inline-flex rounded-full  px-2 text-xs font-semibold leading-5  ${
                                  person?.occupied_status == "Available"
                                    ? "bg-gray-100 text-gray-800"
                                    : "bg-green-100 text-green-800"
                                }`}
                              >
                                {person?.occupied_status}
                              </span>
                            </td>
                            <td className="relative  whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <div className="flex justify-end items-center gap-5 ">
                               {/* person?.roommate_one!=null&&person.roommate_one!=undefined &&person?.roommate_two!=null&&person.roommate_two!=undefined&&person?.roommate_one!=""&&person.roommate_two!="" */}
                           {person?.occupied_status=="Available"?"": <span
                                 onClick={() => {
                                  CheckDetailRoomateOne(
                                    person?.name,
                                    person?.name
                                  );
                                  setRommateOne(null);
                                  setRommateTwo(null)
                                  setResetRoomID(person)
                                }}
                                  className="text-indigo-600 py-0.5 cursor-pointer hover:text-indigo-600"
                                >
                                 View Details
                                </span>}
                                <span
                                  onClick={() => {
                                    Handleviewdetail(person);
                                  }}
                                  className="text-indigo-600 py-0.5 cursor-pointer hover:text-indigo-800"
                                >
                                  Edit
                                </span>
                                <span
                               
                                  onClick={() => {
                                    HandleDelete(person);
                                  }}
                                  className=" cursor-pointer"
                                >
                                  <TrashIcon class="h-5 w-5 text-red-600" />
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isNotFound ? null : totalRoomCount >= 50 ? (
        <div className="flex items-center justify-center mt-7 bg-white px-4 py-3 sm:px-6  rounded-lg shadow">
          {isLoading && (
            <button
              type="button"
              disabled
              className="rounded-md banner-back px-3 py-2 text-sm font-semibold text-white shadow-sm hover:banner-back focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Loading...
            </button>
          )}
          {!isLoading && (
            <>
              <button
                type="button"
                onClick={handleLoadMore}
                disabled={isLoading}
                className="rounded-md banner-back px-3 py-2 text-sm font-semibold text-white shadow-sm hover:banner-back focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Load More
              </button>
              <button
                type="button"
                onClick={handleClick}
                className="rounded-md banner-back px-3 ml-4 py-2 text-sm font-semibold text-white shadow-sm hover:banner-back focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <ChevronUpIcon class="h-6 w-6 " />
              </button>
            </>
          )}
        </div>
      ) : (
        ""
      )}

      {/* Popup for edit */}
      <Transition.Root show={UpdatePopUp} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setUpdatePopUp}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-2 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-2 pt-5 pb-2  shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-4">
                  <div className="flex justify-start flex-col gap-y-4 items-start w-full ">
                    <h1 className="text-base font-semibold leading-6 text-gray-900 ">
                      Update Room
                    </h1>
                    <form
                      className="w-full"
                      onSubmit={EditRoomNumber.handleSubmit}
                    >
                      <div>
                        <div className="flex justify-between">
                          <label
                            htmlFor="room_number"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Room Number
                          </label>
                        </div>
                        <div className="mt-2">
                          <input
                            required
                            onChange={EditRoomNumber.handleChange}
                            value={EditRoomNumber.values.room_number}
                            type="number"
                            min={0}
                            name="room_number"
                            id="room_number"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="0"
                          />
                        </div>
                      </div>
                      <div>
                     { personDetail?.occupied_status==="Available"&& <><div className="flex justify-between">
                          <label
                            htmlFor="room_type"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Room Type
                          </label>
                        </div>

                        <div className="mt-2">
                          <select
                            required
                            id="room_type"
                            name="room_type"
                            className=" block w-full  rounded-md border-gray-300 py-2 pl-5 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            onChange={EditRoomNumber.handleChange}
                            value={EditRoomNumber.values.room_type}
                          >
                            <option value="">Select..</option>
                            <option value="Single">Single</option>
                            <option value="Twin">Twin</option>
                          </select>
                        </div></>}
                      </div>
                      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        {loadingBeforeSend == true ? (
                          <>
                            <div
                              role="status"
                              className="flex justify-center items-center"
                            ></div>
                            <div
                              role="status"
                              className="flex justify-center items-center"
                            >
                              <svg
                                aria-hidden="true"
                                class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                              <span class="sr-only">Loading...</span>
                            </div>{" "}
                          </>
                        ) : (
                          <>
                            <button
                              type="submit"
                              className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                            >
                              Update
                            </button>
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                              onClick={() => {
                                setUpdatePopUp(false);
                                EditRoomNumber.resetForm();
                              }}
                              ref={cancelButtonRef}
                            >
                              Close
                            </button>
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* Popup for add room  */}
      <Transition.Root show={showRoomNumberPoUp} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setShowRoomNumberPoUp}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-2 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-2 pt-5 pb-2  shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-4">
                  <div className="flex justify-start flex-col gap-y-4 items-start w-full ">
                    <h1 className="text-base font-semibold leading-6 text-gray-900 ">
                      Add Room
                    </h1>
                    <form
                      className="w-full"
                      onSubmit={AddRoomNumbers.handleSubmit}
                    >
                      <div>
                        <div className="flex justify-between">
                          <label
                            htmlFor="room_number"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Room Number
                          </label>
                        </div>
                        <div className="mt-2">
                          <input
                            ref={inputRef}
                            onChange={AddRoomNumbers.handleChange}
                            value={AddRoomNumbers.values.room_number}
                            type="number"
                            required
                            min={0}
                            name="room_number"
                            id="room_number"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="0"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="flex justify-between">
                          <label
                            htmlFor="room_type"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Room Type
                          </label>
                        </div>

                        <div className="mt-2">
                          <select
                            id="room_type"
                            required
                            name="room_type"
                            className=" block w-full  rounded-md border-gray-300 py-2 pl-5 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            onChange={AddRoomNumbers.handleChange}
                            value={AddRoomNumbers.values.room_type}
                          >
                            <option value="">Select..</option>
                            <option value="Single">Single</option>
                            <option value="Twin">Twin</option>
                          </select>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        {loadingBeforeSend == true ? (
                          <>
                            <div
                              role="status"
                              className="flex justify-center items-center"
                            ></div>
                            <div
                              role="status"
                              className="flex justify-center items-center"
                            >
                              <svg
                                aria-hidden="true"
                                class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                              <span class="sr-only">Loading...</span>
                            </div>{" "}
                          </>
                        ) : (
                          <>
                            <button
                              type="submit"
                              className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                            >
                              Save
                            </button>
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                              onClick={() => {
                                setShowRoomNumberPoUp(false);
                                AddRoomNumbers.resetForm();
                              }}
                              ref={cancelButtonRef}
                            >
                              Close
                            </button>
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* showDeletepoup */}
      <Transition.Root show={showDeletepoup} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setShowDeletePoUp}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setShowDeletePoUp(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="flex items-center">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="">
                        <p className="text-sm text-gray-500">
                          Are you sure want to Delete room number
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    {loadingBeforeSend == true ? (
                      <div
                        role="status"
                        className="w-60 flex justify-center items-center"
                      >
                        <svg
                          aria-hidden="true"
                          class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-red-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-60 justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 "
                        onClick={() => {
                          handleConfirmDelete();
                        }}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-0 sm:pl-0">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-3 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Roommates Details
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      <div className="divide-y divide-gray-200">
                        {/* <div className="">
                          <div className="h-10 banner-back sm:h-10 lg:h-10" />
                          <div className=" flow-root px-4  sm:flex sm:items-end "></div>
                        </div> */}
                        {/* Roommate one id RommateOne!=null&&<*/}
                       { resetRoomID?.occupied_status=="Available" ?"": <div className="px-4 py-5 sm:px-0 sm:py-0">
                          <dl className="space-y-8 sm:space-y-0 sm:divide-y  sm:divide-gray-200">
                          <div></div>
                            <div className="sm:flex sm:px-6 sm:py-4 ">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Reset Full Room
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <button
                                  type="button"
                                  onClick={()=>{setUpdateRoomPopUp(true)}}
                                  className="rounded-md bg-red-600 px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                >
                                  Reset 
                                </button>
                              </dd>
                            </div>
                           
                          </dl>
                        </div>}
                        
                          {/* Roommate one id RommateOne!=null&&<*/}
                       {RommateOne!=null&&<div className="px-4 py-5 sm:px-0 sm:py-0">
                          <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                            <ul
                              role="list"
                              className="divide-y divide-gray-200 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 "
                            >
                              <li className="relative flex justify-between gap-x-6 px-4 py-3 text-white banner-back  sm:px-6">
                                <div className="flex min-w-0 gap-x-4">
                                  <PrivateImage
                                    imageUrl={RommateOne?.user?.user_image}
                                    className={
                                      "h-14 w-14 flex-none rounded-full bg-gray-50"
                                    }
                                  />
                                  <div className="min-w-0 flex-auto">
                                    <p className="text-sm font-semibold leading-6">
                                      <a>
                                        <span className="absolute inset-x-0 -top-px bottom-0" />
                                        {`${RommateOne?.user?.first_name} ${RommateOne?.user?.last_name}`}
                                      </a>
                                    </p>
                                    <p className=" flex text-xs leading-5 ">
                                      <a className="relative truncate">
                                        {RommateOne?.user?.email}
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <div className="sm:flex sm:px-6 sm:py-2">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Checkin Selfie
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {" "}
                                  {RommateOne?.passport_front_upload ? (
                                    RommateOne?.checkin_selfie?.endsWith(
                                      ".pdf"
                                    ) ? (
                                      <div>
                                        <a
                                          className="inline-flex items-center gap-x-1.5 rounded-md    shadow-sm  bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                          href={
                                            "https://dash.bestdiplomats.org" +
                                            RommateOne?.checkin_selfie
                                          }
                                          target="_blank"
                                        >
                                          Open{" "}
                                          <svg
                                            className="w-5 h-5 mr--0.5"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                            ></path>
                                          </svg>
                                        </a>
                                      </div>
                                    ) : (
                                      <div
                                        onClick={() => handleChangeselfiesImage(RommateOne?.checkin_selfie)}
                                        className="h-14 w-14 cursor-pointer flex-shrink-0 sm:h-40 sm:w-40  lg:h-20 lg:w-20"
                                      >
                                        <PrivateImage
                                          imageUrl={RommateOne?.checkin_selfie}
                                          className={
                                            "h-14 w-14 cursor-pointer flex-shrink-0 sm:h-40 sm:w-40  lg:h-20 lg:w-20"
                                          }
                                        />
                                      </div>
                                    )
                                  ) : (
                                    <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                    </span>
                                  )}
                                </dd>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-2">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Passport Front Upload
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {" "}
                                  {RommateOne?.passport_front_upload ? (
                                    RommateOne?.passport_front_upload?.endsWith(
                                      ".pdf"
                                    ) ? (
                                      <div>
                                        <a
                                          className="inline-flex items-center gap-x-1.5 rounded-md  shadow-sm  bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                          href={
                                            "https://dash.bestdiplomats.org" +
                                            RommateOne?.passport_front_upload
                                          }
                                          target="_blank"
                                        >
                                          Open{" "}
                                          <svg
                                            className="w-5 h-5 mr--0.5"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                            ></path>
                                          </svg>
                                        </a>
                                      </div>
                                    ) : (
                                      <div
                                      onClick={() => handleChangeselfiesImage(RommateOne?.passport_front_upload)}
                                        className="h-14 w-14 cursor-pointer flex-shrink-0 sm:h-40 sm:w-40  lg:h-20 lg:w-20"
                                      >
                                        <PrivateImage
                                          imageUrl={
                                            RommateOne?.passport_front_upload
                                          }
                                          className={
                                            "h-14 w-14 cursor-pointer flex-shrink-0 sm:h-40 sm:w-40  lg:h-20 lg:w-20"
                                          }
                                        />
                                      </div>
                                    )
                                  ) : (
                                    <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                    </span>
                                  )}
                                </dd>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-2">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Reset this Check In
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <button
                                  type="button"
                                  onClick={()=>{setUpdateRoommatePopUp(true)
                                    setResetRoommateID(RommateOne?.name)}}
                                  className="rounded-md bg-red-600 px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                >
                                  Reset 
                                </button>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            CheckIn Location
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                             {RommateOne?.checkin_location}
                            </dd>
                          </div>  
                           <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Checkin Status
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                             {RommateOne?.checkin_status}
                            </dd>
                          </div>   
                           <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Face Similarity Score
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                             {RommateOne?.face_similarity_score}
                            </dd>
                          </div> 
                           <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Passport Name
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                             {RommateOne?.passport_name}
                            </dd>
                          </div> 
                            <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Passport SurName
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                             {RommateOne?.passport_surname}
                            </dd>
                          </div>
                          <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Passport Gender
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                             {RommateOne?.passport_gender}
                            </dd>
                          </div>  
                          <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Passport Birthday
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                           
                             { moment(
                                      RommateOne?.passport_birthday 
                          ).format("DD-MM-YYYY")}
                            </dd>
                          </div>
                            <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Passport Number
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                             {RommateOne?.passport_number}
                            </dd>
                          </div> 
                          <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Passport Country
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                             {RommateOne?.passport_country}
                            </dd>
                          </div> 
                          <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Passport Nationality
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                             {RommateOne?.passport_nationality}
                            </dd>
                          </div>
                          </dl>
                        </div>
                        } 
                        {/* Roommate two id */}
                       {RommateTwo!=null&&<div className="px-4 py-5 sm:px-0 sm:py-0">
                          <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                            <ul
                              role="list"
                              className="divide-y divide-gray-200 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 "
                            >
                              <li className="relative flex justify-between text-white banner-back gap-x-6 px-4 py-3  sm:px-6">
                                <div className="flex min-w-0 gap-x-4">
                                  <PrivateImage
                                    imageUrl={RommateTwo?.user?.user_image}
                                    className={
                                      "h-14 w-14 flex-none rounded-full bg-gray-50"
                                    }
                                  />
                                  <div className="min-w-0 flex-auto">
                                    <p className="text-sm font-semibold leading-6 ">
                                      <a>
                                        <span className="absolute inset-x-0 -top-px bottom-0" />
                                        {`${RommateTwo?.user?.first_name} ${RommateTwo?.user?.last_name}`}
                                      </a>
                                    </p>
                                    <p className=" flex text-xs leading-5 ">
                                      <a className="relative truncate">
                                        {RommateTwo?.user?.email}
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <div className="sm:flex sm:px-6 sm:py-2">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Checkin Selfie
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {" "}
                                  {RommateTwo?.passport_front_upload ? (
                                    RommateTwo?.checkin_selfie?.endsWith(
                                      ".pdf"
                                    ) ? (
                                      <div>
                                        <a
                                          className="inline-flex items-center gap-x-1.5 rounded-md    shadow-sm  bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                          href={
                                            "https://dash.bestdiplomats.org" +
                                            RommateTwo?.checkin_selfie
                                          }
                                          target="_blank"
                                        >
                                          Open{" "}
                                          <svg
                                            className="w-5 h-5 mr--0.5"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                            ></path>
                                          </svg>
                                        </a>
                                      </div>
                                    ) : (
                                      <div
                                      onClick={() => handleChangeselfiesImage(RommateTwo?.checkin_selfie)}
                                        className="h-14 w-14 cursor-pointer flex-shrink-0 sm:h-40 sm:w-40  lg:h-20 lg:w-20"
                                      >
                                        <PrivateImage
                                          imageUrl={RommateTwo?.checkin_selfie}
                                          className={
                                            "h-14 w-14 cursor-pointer flex-shrink-0 sm:h-40 sm:w-40  lg:h-20 lg:w-20"
                                          }
                                        />
                                      </div>
                                    )
                                  ) : (
                                    <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                    </span>
                                  )}
                                </dd>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-2">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Passport Front Upload
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {" "}
                                  {RommateTwo?.passport_front_upload ? (
                                    RommateTwo?.passport_front_upload?.endsWith(
                                      ".pdf"
                                    ) ? (
                                      <div>
                                        <a
                                          className="inline-flex items-center gap-x-1.5 rounded-md  shadow-sm  bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                          href={
                                            "https://dash.bestdiplomats.org" +
                                            RommateTwo?.passport_front_upload
                                          }
                                          target="_blank"
                                        >
                                          Open{" "}
                                          <svg
                                            className="w-5 h-5 mr--0.5"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                            ></path>
                                          </svg>
                                        </a>
                                      </div>
                                    ) : (
                                      <div
                                      onClick={() => handleChangeselfiesImage( RommateTwo?.passport_front_upload)}
                                        className="h-14 w-14 cursor-pointer flex-shrink-0 sm:h-40 sm:w-40 lg:h-20 lg:w-20"
                                      >
                                        <PrivateImage
                                          imageUrl={
                                            RommateTwo?.passport_front_upload
                                          }
                                          className={
                                            "h-14 w-14 cursor-pointer flex-shrink-0 sm:h-40 sm:w-40 lg:h-20 lg:w-20"
                                          }
                                        />
                                      </div>
                                    )
                                  ) : (
                                    <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                    </span>
                                  )}
                                </dd>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-3">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Reset this Check In
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <button
                                  type="button"
                                  onClick={()=>{setUpdateRoommatePopUp(true)
                                    setResetRoommateID(RommateTwo?.name)}}
                                  className="rounded-md bg-red-600 px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                >
                                  Reset 
                                </button>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            CheckIn Location
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                             {RommateTwo?.checkin_location}
                            </dd>
                          </div>  
                           <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Checkin Status
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                             {RommateTwo?.checkin_status}
                            </dd>
                          </div>   
                           <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Face Similarity Score
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                             {RommateTwo?.face_similarity_score}
                            </dd>
                          </div> 
                           <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Passport Name
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                             {RommateTwo?.passport_name}
                            </dd>
                          </div> 
                            <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Passport SurName
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                             {RommateTwo?.passport_surname}
                            </dd>
                          </div>
                          <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Passport Gender
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                             {RommateTwo?.passport_gender}
                            </dd>
                          </div> 
                          <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Passport Birthday
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                             { moment(
                                      RommateTwo?.passport_birthday 
                          ).format("DD-MM-YYYY")}
                            </dd>
                          </div>  <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Passport Number
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                             {RommateTwo?.passport_number}
                            </dd>
                          </div> 
                          <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Passport Country
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                             {RommateTwo?.passport_country}
                            </dd>
                          </div> 
                          <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Passport Nationality
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                             {RommateTwo?.passport_nationality}
                            </dd>
                          </div>
                          </dl>
                        </div>}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={popcheckinselfieImage} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setPopcheckinselfieImage}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-2 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-2 pt-5 pb-2  shadow-xl transition-all  sm:my-8 sm:w-full sm:max-w-2xl sm:p-2">
                  <div className="flex justify-center rounded-sm items-center bg-orange-200">
                    <PrivateImage
                      imageUrl={ShowPopupImage}
                      className="h-[35rem] w-full object-contain object-center"
                    />
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    {/* <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                 
                </button> */}
                    <button
                      type="button"
                      className="mx-4 my-3 inline-flex w-40 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setPopcheckinselfieImage(false)}
                      ref={cancelButtonRef}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={UpdateRoommatePopUp} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setUpdateRoommatePopUp}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:ml-3 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-red-600">
                      Reset Roommate
                    </Dialog.Title>
                    <div className="mt-0.5">
                      <p className="text-sm text-gray-600">
                      This will reset the checkin and will ask the delegate to mark their destination again. Any allocated rooms and roommates will be removed as well.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 justify-end sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
               {loading==true? <><button
                    type="button"
                    className=" inline-flex w-full justify-center rounded-md bg-white px-10 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:w-auto"
                    disabled
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="inline-flex mt-3 w-full justify-center rounded-md bg-green-600 px-10 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500  sm:ml-3 sm:mt-0 sm:w-auto"
                    disabled
                  >
                    Loading...
                  </button></>:<> <button
                    type="button"
                    className=" inline-flex w-full justify-center rounded-md bg-white px-10 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:w-auto"
                    onClick={() => setUpdateRoommatePopUp(false)}
                    ref={cancelButtonRef}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="inline-flex mt-3 w-full justify-center rounded-md bg-green-600 px-10 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500  sm:ml-3 sm:mt-0 sm:w-auto"
                    onClick={() => ResetRoomateStatus()}
                  >
                    Yes
                  </button></>}
                  
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>  
     <Transition.Root show={UpdateRoomPopUp} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setUpdateRoomPopUp}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:ml-3 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-red-600">
                      Reset Full Room
                    </Dialog.Title>
                    <div className="mt-0.5">
                      <p className="text-sm text-gray-600">
                      This will reset the checkin and will ask the delegate to mark their destination again. Any allocated rooms and roommates will be removed as well.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 justify-end sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
               {loading==true? <><button
                    type="button"
                    className=" inline-flex w-full justify-center rounded-md bg-white px-10 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:w-auto"
                    disabled
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="inline-flex mt-3 w-full justify-center rounded-md bg-green-600 px-10 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500  sm:ml-3 sm:mt-0 sm:w-auto"
                    disabled
                  >
                    Loading...
                  </button></>:<> <button
                    type="button"
                    className=" inline-flex w-full justify-center rounded-md bg-white px-10 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:w-auto"
                    onClick={() => setUpdateRoomPopUp(false)}
                    ref={cancelButtonRef}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="inline-flex mt-3 w-full justify-center rounded-md bg-green-600 px-10 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500  sm:ml-3 sm:mt-0 sm:w-auto"
                    onClick={() => ResetRoom()}
                  >
                    Yes
                  </button></>}
                  
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </>
  );
};

export default HotelRooms;
