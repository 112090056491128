import React, { useEffect } from 'react'
import { useState } from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";
import { useParams, Link } from "react-router-dom";
import CheckInAllTypeRegistration from './CheckInAllTypeRegistration';
import { ListBulletIcon } from '@heroicons/react/24/outline';
import LocalTeamCheckin from './LocalTeamCheckin';
const CheckinNavbar = () => {
    const { eventID } = useParams();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    
    const CheckinNavbar = [
        {
          name: "All Check In",
          href: `all-checkin/${eventID}`,
          icon: ListBulletIcon,
          current: true,
        },
        {
          name: "Local Team Check In",
          href: `local-team-checkin/${eventID}`,
          icon: ListBulletIcon,
          current: false,
        },
       
      ];
      function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
      }
    const [
        currentCheckinNavigation,
        setCurrentCheckinNavigation,
      ] = useState(CheckinNavbar);
      
  const handleClickCheckinNavigation = (index, setCurrentCheckinNavigation) => {
    const updatedNavigation = currentCheckinNavigation.map((item, i) => {
      if (i === index) {
        return { ...item, current: true };
      } else {
        return { ...item, current: false };
      }
    });
    setCurrentCheckinNavigation(updatedNavigation);
  };
  
  useEffect(() => {
    const path = window.location.pathname;
    const updatedNavigation = currentCheckinNavigation.map((item) => {
      if (path.includes(item.href)) {
        return { ...item, current: true };
      } else {
        return { ...item, current: false };
      }
    });
    const hasCurrentItem = updatedNavigation.some((item) => item.current);
    if (!hasCurrentItem) {
      updatedNavigation[0].current = true;
    }

    setCurrentCheckinNavigation(updatedNavigation);
  }, []);

  return (
    <div classNames="pt-4">
    <nav className=" px-2 h-12  banner-back rounded-md flex items-center justify-between ">
      <div className=" px-2 gap-2  rounded-md flex items-center  ">
      {currentCheckinNavigation.map((item, index) => (
        <Link
          key={item.name}
          to={item.href}
          className={classNames(
            item.current
              ? "bg-blue-700 border-blue-700 text-white hover:bg-blue-600 hover:text-white"
              : "border-transparent text-white hover:bg-blue-600 hover:text-white",
            "group flex items-center rounded-md px-3 py-2 text-sm font-medium"
          )}
          onClick={() => handleClickCheckinNavigation(index, setCurrentCheckinNavigation)}
        >
          {item.name}
        </Link>
      ))}
      </div>
   
    </nav>
    <Routes>
      <Route path="all-checkin/:eventID" element={<CheckInAllTypeRegistration  />} />
      <Route path="local-team-checkin/:eventID" element={<LocalTeamCheckin />} />
 
    </Routes>
  </div>
  )
}

export default CheckinNavbar