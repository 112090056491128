
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";
import { useParams, Link } from "react-router-dom";
// import CheckInAllTypeRegistration from './CheckInAllTypeRegistration';
import { ListBulletIcon } from '@heroicons/react/24/outline';
import DiplomaticExperts from './DiplomaticExperts';
import DiplomaticExpertsAnnouncement from './Diplomatic Expert Announcement/DiplomaticExpertsAnnouncement';
import DiplomaticChat from './Diplomatic Chat/DiplomaticChat';
import DiplomaticTask from './DiplomaticTask/DiplomaticTask';
import DiplomaticAllTask from './DiplomaticTask/DiplomaticAllTask';
import DiplomaticSubmission from './DiplomaticTask/DiplomaticSubmission';
// import LocalTeamCheckin from './LocalTeamCheckin';
const DiplomaticExpertsNavbar = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    const { eventID } = useParams();
    const CheckinNavbar = [
        {
          name: "Moderators",
          href: `diplomatic-experts/${eventID}`,
          icon: ListBulletIcon,
          current: true,
        },
        {
          name: "Announcement",
          href: `Dpl-Announcement/${eventID}`,
          icon: ListBulletIcon,
          current: false,
        },
        {
          name: "Chat",
          href: `diplomatic-Chat/${eventID}`,
          icon: ListBulletIcon,
          current: false,
        },
        {
          name: "Task",
          href: `dpl-task/${eventID}`,
          icon: ListBulletIcon,
          current: false,
        },
       
       
      ];
      function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
      }
    const [
        currentRepresentativeNavigation,
        setcurrentRepresentativeNavigation,
      ] = useState(CheckinNavbar);
      
  const handleClickRepresentativeNavigation = (index, setcurrentRepresentativeNavigation) => {
    const updatedNavigation = currentRepresentativeNavigation.map((item, i) => {
      if (i === index) {
        return { ...item, current: true };
      } else {
        return { ...item, current: false };
      }
    });
    setcurrentRepresentativeNavigation(updatedNavigation);
  };
  
  useEffect(() => {
    const path = window.location.pathname;
    const updatedNavigation = currentRepresentativeNavigation.map((item) => {
      if (path.includes(item.href)) {
        return { ...item, current: true };
      } else {
        return { ...item, current: false };
      }
    });
    const hasCurrentItem = updatedNavigation.some((item) => item.current);
    if (!hasCurrentItem) {
      updatedNavigation[0].current = true;
    }

    setcurrentRepresentativeNavigation(updatedNavigation);
  }, []);
  const [dataFromChild, setDataFromChild] = useState(null);
  const handleDataFromChild = (data) => {
    setDataFromChild(data);
    // console.log("data",data);
  };
  return (
    <div classNames="pt-4">
    <nav className=" px-2 h-12  banner-back rounded-md flex items-center justify-between ">
      <div className=" px-2 gap-2  rounded-md flex items-center  ">
      {currentRepresentativeNavigation.map((item, index) => (
        <Link
          key={item.name}
          to={item.href}
          className={classNames(
            item.current
              ? "bg-blue-700 border-blue-700 text-white hover:bg-blue-600 hover:text-white"
              : "border-transparent text-white hover:bg-blue-600 hover:text-white",
            "group flex items-center rounded-md px-3 py-2 text-sm font-medium"
          )}
          onClick={() => handleClickRepresentativeNavigation(index, setcurrentRepresentativeNavigation)}
        >
          {item.name}
        </Link>
      ))}
      </div>
   
    </nav>
    <Routes>
      <Route path="diplomatic-experts/:eventID" element={<DiplomaticExperts  />} />
      <Route path="Dpl-Announcement/:eventID" element={<DiplomaticExpertsAnnouncement />} />
      <Route path="diplomatic-Chat/:eventID" element={<DiplomaticChat />} />
      <Route path="dpl-task/:eventID" element={<DiplomaticTask/>} />
      <Route path="dpl-task/:eventID/dpl-alltask/:eventID" element={<DiplomaticAllTask sendDataToParent={handleDataFromChild}/>} />
      <Route path="dpl-task/:eventID/dpl-alltask/:eventID/dpl-all-submission/:eventID" element={<DiplomaticSubmission passData={dataFromChild}/>} />
    </Routes>
  </div>)}
export default DiplomaticExpertsNavbar