import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { PDFDocument, rgb } from 'pdf-lib';
import BlankVoucher from '../../img/hotel_pass_bg_new_address.png'
import FontRoboto from '../../Roboto-Regular.ttf'
import FontRobotoBold from '../../Roboto-Bold.ttf'
import axios from 'axios';
import fontkit from '@pdf-lib/fontkit'
import QRCode from 'qrcode'
import { useSelector } from 'react-redux';
import { Document, Page, Text, View, StyleSheet,PDFViewer, Image, Font,PDFDownloadLink } from '@react-pdf/renderer';
import moment from 'moment';

const HotelVoucher = () => {


  Font.register({
    family: 'Roboto',
    src: FontRobotoBold
  });
   
      const styles = StyleSheet.create({
        page: {
          
        },
        section: {
          margin: 10,
          padding: 10,
          flexGrow: 1
        },viewer: {
          width: 610, 
          height: 870,
        },
        bgimages: {
          height:842,
          position: 'absolute'
        },venu:{
          top:223,
          left:260,
          fontSize:12,
          marginTop:4,
        },QRImage:{
          top:155,
          left:460,
          position:'absolute',
          width:75
        },venudetails:{
            top:223,
            left:260,
            fontSize:12,
            marginTop:8 
        },roomdetails:{
            top:269,
            left:125,
            fontSize:12,
            marginTop:8 
        },guest:{
            top:325,
            left:290,
            fontSize:12,
            marginTop:8 
        },guestpassport:{
            top:336,
            left:290,
            fontSize:12,
            marginTop:8 ,
           fontFamily:'Roboto'
        },dob:{
            top:347,
            left:290,
            fontSize:12,
            marginTop:8 
        }
    }
      );

    // States
    const [PDFFinalDoc, setPDFFinalDoc] = useState(null);
    const {eventId}= useParams();
    const [currentUser,setCurrentUser]=useState();
    const [event,setEvent]=useState();
    const [loading,setLoading]=useState(false);
    const [qrUriImageFile,setqrUriImageFile]=useState();
    const [isRegisteredFinal,setIsRegisteredFinal]=useState();
    const [firstName,setFirstName]=useState();
    const [lastName,setLastName]=useState();
    const [roomStatus,setRoomStatus]=useState();
    const [noOfAdults,setNoOfAdults]=useState();
    const [checkInDate,setCheckInDate]=useState();
    const [checkOutDate,setCheckOutDate]=useState();
    
    






    // Configrations
    var userEmail;
    var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

    // Get User Details

    useEffect(() => {
        const fetchUserData = async () => {
          try {
            
            await axios
              .get(
                "https://dash.bestdiplomats.org/api/method/get-delegate?email=" +
                  userEmail,
                config
              )
              .then((responseUser) => {
                
                setCurrentUser(responseUser.data.data)
                const firstNameToTitleCase = (firstname) => {
                  return firstname?.toLowerCase().split(" ").map((word) => word.charAt(0)?.toUpperCase() + word.slice(1)).join(" ");
                };
                const secondNameToTitleCase = (secondname) => {
                  return secondname?.toLowerCase().split(" ").map((word) => word.charAt(0)?.toUpperCase() + word.slice(1)).join(" ");
                };
                setFirstName(firstNameToTitleCase(responseUser.data.data.user?.first_name))
                setLastName(secondNameToTitleCase(responseUser.data.data.user?.last_name))
                const fetchEventRegisteration = async () => {
                    try {
                      setLoading(true);
                      const result = await axios
                        .get(
                          `https://dash.bestdiplomats.org/api/method/get-registration?event=${eventId}&delegate=${responseUser?.data?.data?.name}`,config
                        )
                        .then((response) => {
                          const fetchEventData = async () => {
                            try {
                              setLoading(true);
                              const result = await axios
                                .get(
                                  "https://dash.bestdiplomats.org/api/resource/Events/" + eventId
                                )
                                .then((eventResponse) => {
                                  if (response.data.data?.hotel_checkin_date == "" || response.data.data?.hotel_checkin_date == null || response.data.data?.hotel_checkout_date == "" || response.data.data?.hotel_checkout_date == null) {
                                    if (response.data.data?.payment_package=="Full Experience (Second Payment Pending)" || response.data.data?.payment_package=="Full Experience") {
                                      setCheckInDate(eventResponse.data.data?.start_date);
                                      setCheckOutDate(eventResponse.data.data?.full_experience_end_date)
                                    }else{
                                      setCheckInDate(eventResponse.data.data?.start_date);
                                      setCheckOutDate(eventResponse.data.data?.end_date)
                                    }
                                  }else {
                                    
                                    setCheckInDate(response.data.data?.hotel_checkin_date);
                                    setCheckOutDate(response.data.data?.hotel_checkout_date);
              
                                  }
                                  
                                  if(response.data.data?.room_status == "Single Room"){
                                    setRoomStatus("Single Room")
                                    setNoOfAdults("1")
                                    
                                  }else{
                                    setRoomStatus("Twin Room")
                                    setNoOfAdults("2")
                                  }
                                });
                              setLoading(false);
                            } catch (e) {
                              console.log(e);
                            }
                          };
                          fetchEventData();
                          // console.log(response.data);
                          if(response.data.error=='Not found'){
                          setIsRegisteredFinal("Not Registered");
                          }else{
                            
                            const generateQR = async text => {
                                try {
                                  setqrUriImageFile(await QRCode.toDataURL(text,{
                                    color: {
                                      dark: '#000000',  // Blue dots
                                      light: '#0000' // Transparent background
                                    }
                                  }))
                                  
                                } catch (err) {
                                  console.error(err)
                                }
                              }  
                            generateQR(window.$FWDurl+"/HotelValidate/"+eventId+"/"+responseUser.data.data.user?.name);
                            
                          }
                        });
                      setLoading(false);
                    } catch (e) {
                      
                      console.log(e);
                    }
                  };
                  fetchEventRegisteration();
            });
            
          } catch (e) {
            console.log(e);
          }
        };
        fetchUserData();
        
      }, []);
 
    
    //   Event Details
useEffect(() => {
    const fetchEventData = async () => {
      try {
        setLoading(true);
        const result = await axios
          .get(
            "https://dash.bestdiplomats.org/api/resource/Events/" + eventId
          )
          .then((response) => {
            // console.log(response.data.data);
            setEvent(response.data.data);
          });
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    fetchEventData();
  }, []);


  return (
    <div>
    <PDFDownloadLink document={  <Document>
    <Page size={[595,842]} >
    <Image src={BlankVoucher} style={styles.bgimages}></Image>
    <Image src={qrUriImageFile} style={styles.QRImage}></Image>
    <Text style={styles.venu}>{event?.venue==null||event?.venue==undefined||event?.venue==""?"N/A":event?.venue}</Text>
    <Text style={styles.venudetails}>{event?.venue_details==null||event?.venue_details==undefined||event?.venue_details==""?"N/A":event?.venue_details}</Text>
    <Text style={styles.venudetails}>{event?.booking_reference_number==null||event?.booking_reference_number==undefined||event?.booking_reference_number==""?"N/A":event?.booking_reference_number}</Text>
    <Text style={styles.venudetails}>{moment(checkInDate).format("D MMMM, YYYY")}</Text>
    <Text style={styles.venudetails}>{moment(checkOutDate).format("D MMMM, YYYY")}</Text>
    <Text style={styles.roomdetails}>{"1"+`                                     ${roomStatus}                                        ${noOfAdults}`}</Text>
    <Text style={styles.guest}>{firstName+" "+lastName}</Text>
    <Text style={styles.guestpassport}>{currentUser?.passport_number==null||currentUser?.passport_number==undefined||currentUser?.passport_number==""?"N/A":currentUser?.passport_number}</Text>
    <Text style={styles.dob}>{currentUser?.birthday==null||currentUser?.birthday==undefined||currentUser?.birthday==""?"N/A":currentUser?.birthday}</Text>
      
    </Page>
  </Document>} fileName={`Invitation-${currentUser?.user?.first_name}.pdf`}>
  {({ blob, url, loading, error }) =>
        loading ? ( <span className='group relative flex w-64 justify-center rounded-md border border-transparent bg-blue-400 my-4  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>Loading...</span>) : ( <span className='group relative flex w-64 justify-center rounded-md border border-transparent bg-blue-400 my-4  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>Download PDF</span>)
      }
  </PDFDownloadLink>
    <PDFViewer style={styles.viewer}>
    <Document>
    <Page size={[595,842]} >
    <Image src={BlankVoucher} style={styles.bgimages}></Image>
    <Image src={qrUriImageFile} style={styles.QRImage}></Image>
    <Text style={styles.venu}>{event?.venue==null||event?.venue==undefined||event?.venue==""?"N/A":event?.venue}</Text>
    <Text style={styles.venudetails}>{event?.venue_details==null||event?.venue_details==undefined||event?.venue_details==""?"N/A":event?.venue_details}</Text>
    <Text style={styles.venudetails}>{event?.booking_reference_number==null||event?.booking_reference_number==undefined||event?.booking_reference_number==""?"N/A":event?.booking_reference_number}</Text>
    <Text style={styles.venudetails}>{moment(checkInDate).format("D MMMM, YYYY")}</Text>
    <Text style={styles.venudetails}>{moment(checkOutDate).format("D MMMM, YYYY")}</Text>
    <Text style={styles.roomdetails}>{"1"+`                                     ${roomStatus}                                        ${noOfAdults}`}</Text>
    <Text style={styles.guest}>{firstName+" "+lastName}</Text>
   <Text style={styles.guestpassport}>{currentUser?.passport_number==null||currentUser?.passport_number==undefined||currentUser?.passport_number==""?"N/A":currentUser?.passport_number}</Text>
    <Text style={styles.dob}>{currentUser?.birthday==null||currentUser?.birthday==undefined||currentUser?.birthday==""?"N/A":currentUser?.birthday}</Text>
      
    </Page>
  </Document>
  </PDFViewer>
    
    </div>
  )
}

export default HotelVoucher