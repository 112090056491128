const RepresentingCountry = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Argentina",
    "Armenia", "Aruba", "Australia", "Austria", "Azerbaijan", "Antigua and Barbuda",
    "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize",
    "Benin", "Bermuda", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana",
    "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cambodia", "Cameroon",
    "Canada", "Cape Verde", "Central African Republic", "Chad", "Chile", "China",
    "Colombia", "Comoros", "Congo, The Democratic Republic of The",
    "Republic of the Congo", "Costa Rica", "Côte d'Ivoire", "Croatia", "Cuba",
    "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica",
    "Dominican Republic", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea",
    "Eritrea", "Estonia", "Ethiopia", "Eswatini", "Fiji", "Finland", "France",
    "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada",
    "Guatemala", "Guinea", "Guinea-bissau", "Guyana", "Haiti",
    "Holy See (Vatican City State)", "Honduras", "Hong Kong", "Hungary",
    "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy",
    "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "North Korea",
    "South Korea", "Kuwait", "Kosovo", "Lao People's Democratic Republic",
    "Kyrgyzstan", "Kiribati", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya",
    "State of Libya", "Liechtenstein", "Lithuania", "Luxembourg",
    "Macedonia, The Former Yugoslav Republic of", "Madagascar", "Malawi",
    "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania",
    "Mauritius", "Mexico", "Micronesia, Federated States of",
    "Moldova, Republic of", "Monaco", "Mongolia", "Montenegro", "Morocco",
    "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands",
    "New Zealand", "Nicaragua", "Niger", "Nigeria", "Norway", "North Macedonia",
    "Oman", "Pakistan", "Palau", "Palestinian Territories", "Panama",
    "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal",
    "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis",
    "Saint Vincent and the Grenadines", "Saint Lucia", "Samoa", "San Marino",
    "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles",
    "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands",
    "Somalia", "South Africa", "Spain", "Sri Lanka", "Scotland", "Sudan",
    "South Sudan", "Suriname", "Swaziland", "Sweden", "Switzerland",
    "Syrian Arab Republic", "Taiwan (ROC)", "Tajikistan", "Tanzania", "Thailand",
    "Timor-leste", "Togo", "Tokelau", "Tuvalu", "Republic of Somaliland",
    "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan",
    "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
    "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Venezuela",
    "Viet Nam", "Yemen", "Zambia", "Zimbabwe", "International Observer",
    "Ambassador", "Diplomatic Experts", "Local Team Registration Members",
    "Ambassadors", "Speakers and Guest of Honours", "International Observers",
    "Volunteers", "Shell PLC", "Greenpeace", "Chevron", "Exxon", "British Petroleum",
    "Gazprom", "Coal India", "Saudi Aramco", "Total SA", "Iraq national Oil Co.",
    "Kuwait Petroleum Corp", "Clean Air Fund", "Majid Al Futtaim",
    "Abu Dhabi National Oil (ADNOC)", "YoungO", "Amnesty International",
    "CERES", "Doctors without borders", "Oxfam", "Apple", "Microsoft", "Amazon",
    "Facebook", "Google", "YouTube", "IBM", "Open AI", "Nvidia",
    "EFF Electronic Frontier Foundation", "Not Selected", "Director General",
    "Communication Manager", "Communication Executive", "Conference Supervisor",
    "Head of Operations", "Academics", "Tech Team One", "Tech Team Two",
    "Conference Coordinator", "Conference Manager", "Diplomatic Expert",
    "Airport Team", "Moderator", "Operations Coordinator", "Volunteer",
    "Asabe Shehu Yar'Adua Foundation (ASYARF)", "Benetech", "CBR Global Network",
    "Communities Forestry and Social Development Organization Communications",
    "Data Pop Alliance", "Design Bangladesh", "Electronic Frontier Foundation (EFF)",
    "Essl Foundation", "Franklin & Eleasnor Roosevelt Institute",
    "Friends of Peace and Development Organization", "G3ict Global Initiative for Inclusive Technologies",
    "Global Alliance on Accessible Technologies and Environment (GAATES)",
    "Harmony of the World", "Human Rights Watch", "Landmine Survivors Network",
    "New World Hope Organization", "Orange Penguin Foundation", "Save The Children",
    "Scope", "Solar Cookers International", "World Future Council Foundation",
    "World Policy Analysis Center", "Arab Office of Youth and Environment",
    "American Forum for Global Education", "Arab Urban Development Institute",
    "Asia Pacific Forum on Women, Law and Development",
    "Asian and Pacific Development Center (APDC)", "Asian Students Association",
    "Business Council of Australia", "Caribbean Policy Development Centre Inc.",
    "Center for Environmental Studies and Research",
    "Center for International Environmental Law (CIEL)",
    "Center for the Study of Women and Society", "Center for Respect of Life and Environment",
    "Centre for Environment and Development",
    "Centre for Research, Planning and Action",
    "Centre for Research on Sustainable Agricultural and Rural Development",
    "Centre for International Environmental Law", "Climate Council",
    "Environmental Investigation Agency", "Environmental Law Centre (Alberta)",
    "Environment and Rural Development Foundation",
    "European Center of Environmental Studies",
    "African Youth Network for Sustainable Development",
    "All China Youth Federation (ACFY)", "Arab NGO Network for Development",
    "Asia Pacific Environmental Connection of Sociologist",
    "Association for Human Rights and Democracy in Africa",
    "Association For Sustainable Human Development",
    "Association for Protection of Environment and Culture",
    "Association for Research on Climate and Environment",
    "Association for the Protection of the Environment (APE)",
    "Bahrain Youth and Environment Society", "Brazilian Forum on Climate Change",
    "British Columbia Environmental Network", "Business Council for Sustainable Energy",
    "Business for Social Responsibility", "Center for Community Development Studies",
    "Center for Energy and Environmental Policy",
    "CENTRE FOR DEVELOPMENT AND CIVIC EDUCATION",
    "Centre for Environmental Training and Information",
    "Centre for Rural Development Systems", "Children of the Earth",
    "China Economic and Social Council (CESC)", "Clean Air Foundation",
    "Council for International Development",
    "Council for Scientific and Industrial Research",
    "European Network of Women", "European Policies Research Centre",
    "Global Environmental Management Education Center",
    "Human Development Center", "Human Rights Information Network",
    "Institute for Global Environmental Strategies",
    "Institute of Policy Studies", "Institute of Sustainable Development and Management of Natural Resources",
    "International Development Center",
    "International Movement for Peace and Sustainable Development",
    "Japan Youth Ecology League", "Leadership Watch",
    "Youth for Development and Cooperation", "Youth Enhancement Organization",
    "Worldwatch Institute", "World Women Parliamentarians for Peace (WWPP)",
    "World Peace Council", "Youth Program For The Development Of Africa",
    "World Sustainable Agriculture Association",
    "World Economy, Ecology and Development",
    "Women's International Policy Action Committee on Environment and Development",
    "Women and Environment Education and Development Foundation",
    "United Socio-Economic Development and Research Programme",
    "UNED - United Kingdom (UN Environment and Development Committee)",
    "Sustainable Development Policy Institute",
    "Stockholm Environment Institute",
    "Society for Rural Development",
    "Society for International Development",
    "Rural Development Youth Training Institute",
    "Overseas Development Council, The",
    "One World Now, Inc",
    "Pacific Energy and Resources Center",
    "International Synergy Institute",
    "International Youth Federation for Environmental Studies and Conservation",
    "International Society for Environmental Ethics",
    "International Court of the Environment",
    "Global Climate Coalition",
    "Green Earth Organization",
    "Global Commons Institute",
    "Friends of the Environment",
    "Environmental Protection Society",
    "Action for Development (ACFODE)",
    "American Forum for Global Education",
    "American Society of International Law",
    "Arab Office of Youth and Environment",
    "Arab Urban Development Institute",
    "Asia Pacific Forum on Women, Law and Development",
    "Australian Council for International Development",
    "Caribbean Network for Integrated Rural Development",
    "Caribbean Policy Development Centre Inc.",
    "Center for Environmental Studies and Research",
    "Center for Respect of Life and Environment",
    "Centre for Culture and Development",
    "Centre for Development of International Law",
    "Centre for Environment and Development",
    "Centre for International Environmental Law",
    "Centre for Science and Environment",
    "Climate Council",
    "Cooperation for Peace, Sweden",
    "Council for Human Ecology Kenya (CHEK)",
    "Earth Light Network Inc",
    "Earth Savers Movement Inc",
    "Economists for Peace and Security",
    "Educators for Peace, Norway",
    "Environmental Business Council",
    "Environmental Investigation Agency",
    "European Youth Forest Action",
    "Green Earth Organization",
    "Global Climate Coalition",
    "Global Energy Network International",
    "Walmart",
    "Exxon Mobil",
    "McKesson",
    "Chevron",
    "Marathon Petroleum",
    "Valero Energy",
    "Ford Motor",
    "General Motors",
    "JPMorgan Chase",
    "Meta Platforms",
    "Bank of America",
    "Target",
    "Dell Technologies",
    "Archer Daniels Midland",
    "Citigroup",
    "UPS",
    "Pfizer",
    "Johnson & Johnson",
    "FedEx",
    "Freddie Mac",
    "PepsiCo",
    "Walt Disney",
    "ConocoPhillips",
    "Tesla",
    "Procter & Gamble",
    "General Electric",
    "Albertsons",
    "MetLife",
    "Boeing",
    "Intel",
    "HP",
    "Merck",
    "World Kinect",
    "AIG",
    "American Express",
    "Tyson Foods",
    "Cisco Systems",
    "Oracle",
    "Nationwide",
    "Delta Air Lines",
    "American Airlines Group",
    "Nike",
    "United Airlines Holdings",
    "Thermo Fisher Scientific",
    "Abbott Laboratories",
    "Coca-Cola",
    "Nucor",
    "Massachusetts Mutual Life Insurance",
    "TIAA",
    "General Dynamics",
    "Capital One Financial",
    "Arrow Electronics",
    "Occidental Petroleum",
    "Northwestern Mutual",
    "3M",
    "US Foods Holding",
    "Warner Bros. Discovery",
    "Cheniere Energy",
    "Broadcom",
    "Starbucks",
    "Uber Technologies",
    "Philip Morris International",
    "Netflix",
    "NRG Energy",
    "Mondelez International",
    "Paramount Global",
    "Visa",
    "United Natural Foods",
    "Hewlett Packard Enterprise",
    "PayPal Holdings",
    "Nvidia",
    "EOG Resources",
    "Union Pacific",
    "Southwest Airlines",
    "Advanced Micro Devices",
    "McDonald's",
    "Steel Dynamics",
    "Mastercard",
    "PG&E",
    "Baker Hughes",
    "Marriott International",
    "Halliburton",
    "Altria Group",
    "Texas Instruments",
    "Bank of New York Mellon",
    "ManpowerGroup",
    "American Electric Power",
    "Moderna",
    "General Mills",
    "Exelon",
    "PPG Industries",
    "Adobe",
    "Lumen Technologies",
    "Edison International",
    "Quanta Services",
    "Reliance Steel & Aluminum",
    "Thor Industries",
    "Reinsurance Group of America",
    "Discover Financial Services",
    "Baxter International",
    "Laboratory Corp. of America",
    "Jacobs Solutions",
    "Guardian Life Ins. Co. of America",
    "Fidelity National Information Services",
    "Ameriprise Financial",
    "Tractor Supply",
    "Ecolab",
    "Farmers Insurance Exchange",
    "Sonic Automotive",
    "Johnson and Johnson",
    "JP Morgan",
    "Nestle",
    "UnitedHealth Group",
    "HSBC",
    "Verizon",
    "BRAC",
    "International Committee of the Red Cross (ICRC)",
    "Human Rights Watch"
]

export default RepresentingCountry
