import React, { useEffect, useState } from "react";

import FontRoboto from "../../Roboto-Bold.ttf";
import Gold from '../../img/Impact Director Gold imag.png'
import Blue from '../../img/Impact Director Blue imag.png'
import Black from '../../img/Impact Director Black imag.png'
import QRCode from "qrcode";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
  PDFDownloadLink
} from "@react-pdf/renderer";

const AllIDCardForImpactDirector = (props) => {


  Font.register({
    family:"RobotoBold",
    src: FontRoboto,
  });
  // Create styles
  var setFullname=297.4;
  var setLineOne=301.1;
  var setLineTwo=299.0;
  if((props?.firstName + ' ' + props?.lastName).length>44){
    setFullname=286.5
    setLineOne=290
    setLineTwo=288.1}
  const styles = StyleSheet.create({
    page: {backgroundColor: '#8c2c2c'},
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    viewer: {
      width: 500,
      height: 770,
    },
    images: {
      height: 420,
      position: "absolute",
    },
    imagesProfile: {
      width: 133.8,
      top: 121.1,
      left: 67.2,
      position: "absolute",
      borderRadius: 100,
      overflow:'hidden'
    },
    title: {
      fontFamily:"RobotoBold",
      textTransform: "capitalize",
      color: "#8c2c2c",
      fontSize: 14,
      top: setFullname,
      paddingHorizontal: 5,
      textAlign: "center",
    },
    designation: {
      color: "#000000",
      fontFamily:"RobotoBold",
      paddingHorizontal:10,
      fontSize: 10,
      marginTop: 3,
      textAlign:'center',
      top: setLineOne,
    },
    eventname: {
      color: "#ffffff",
      textAlign: "center",
      position: "absolute",
      fontSize: 11,
      top: 403.2,
      right:0 ,
      left:0,
      margin:'auto',
    },
    qrimage: {
      width: 50,
      top: 246,
      position: "absolute",
      left: 112,
      top: 349.6,
    },
    representingtwo: {
      color: "#000000",
      fontFamily:"RobotoBold",
      textAlign:'center',
      paddingHorizontal:9,
      marginTop:3,
      fontSize: 10,
      top: setLineTwo,
    }
  });
  const [qrUriImageFile, setqrUriImageFile] = useState();
  
  const generateQR = async (text) => {
    try {
      setqrUriImageFile(
        await QRCode.toDataURL(text, {
          color: {
            dark: "#8c2c2c", // Blue dots
            light: "#0000", // Transparent background
          },
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  generateQR(props.qrText);

  return (
    <>
    {props.payment_package=="Blue"  && <Document>
    <Page size={[277, 420]} style={styles.page}>
      <Image
        style={styles.images}
        source={Blue}
      />
      
      <Image style={styles.imagesProfile} source={props.profileImage} />
      
      <Text style={styles.title}>
        {props.firstName +
          " " +
          props.lastName}
      </Text>
      <Text style={styles.designation}>{""+props.firstLine}</Text>
      
      
      <Text style={styles.representingtwo}>
        {""+props.secondLine}
      </Text>
      
      <Text style={styles.eventname}>{"" + props.eventName}</Text>
      <Image style={styles.qrimage} source={qrUriImageFile} />
    </Page>
  </Document>}
    {props.payment_package=="Black"  && <Document>
    <Page size={[277, 420]} style={styles.page}>
      <Image
        style={styles.images}
        source={Black}
      />
      
      <Image style={styles.imagesProfile} source={props.profileImage} />
      
      <Text style={styles.title}>
        {props.firstName +
          " " +
          props.lastName}
      </Text>
      <Text style={styles.designation}>{""+props.firstLine}</Text>
      
      
      <Text style={styles.representingtwo}>
        {""+props.secondLine}
      </Text>
      
      <Text style={styles.eventname}>{"" + props.eventName}</Text>
      <Image style={styles.qrimage} source={qrUriImageFile} />
    </Page>
  </Document>}
    {props.payment_package=="Gold"  && <Document>
    <Page size={[277, 421]} style={styles.page}>
      <Image
        style={styles.images}
        source={Gold}
      />
      
      <Image style={styles.imagesProfile} source={props.profileImage} />
      
      <Text style={styles.title}>
        {props.firstName +
          " " +
          props.lastName}
      </Text>
      <Text style={styles.designation}>{""+props.firstLine}</Text>
      
      
      <Text style={styles.representingtwo}>
        {""+props.secondLine}
      </Text>
      
      <Text style={styles.eventname}>{"" + props.eventName}</Text>
      <Image style={styles.qrimage} source={qrUriImageFile} />
    </Page>
  </Document>}
    
      </>   
  );
};

export default  AllIDCardForImpactDirector 