import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FontAbhaya from "../../../AbhayaLibre-SemiBold.ttf";
import RobotoBold from "../../../Roboto-Bold.ttf";
import AbhayaRegular from "../../../AbhayaLibre-Regular.ttf";
import AbhayaBold from "../../../AbhayaLibre-Bold.ttf";
import QRCode from "qrcode";
import BlankCertificate from "../../../img/Certificate All Core Team.png";
import BlankCertificateTurkiye from "../../../img/Impact Directors Certificate of Appreciation For Turkiye.png";
import GoodVibrations from '../../../GoodVibrations Script.ttf'
import PoppinsSemiBold from '../../../Poppins-SemiBold.ttf'
import PoppinsRegular from '../../../Poppins-Regular.ttf'
import axios from "axios";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  
  Image,
  Font,
  
} from "@react-pdf/renderer";
import moment from "moment";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import JSZip from "jszip";
import toFilled from "../../../img/toFilled.pdf";
import  LoadingIcons  from 'react-loading-icons';

const CertificateAllCoreTeamForImpactDirector = () => {
  const [isDone, setIsDone] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [dateFormate, setDateformate] = useState(96.5);
  const { eventId, delegateId } = useParams();

  Font.register({
    family: "AbhayaLibreSemiBold",
    src: FontAbhaya,
  });
  Font.register({
    family: "RobotoBold",
    src: RobotoBold,
  });
  Font.register({
    family: "AbhayaRegular",
    src: AbhayaRegular,
  });
  Font.register({
    family: "AbhayaBold",
    src: AbhayaBold,
  }); Font.register({
    family: "PoppinsSemiBold",
    src: PoppinsSemiBold,
  }); Font.register({
    family: "GoodVibrations",
    src: GoodVibrations,
  }); Font.register({
    family: "PoppinsRegular",
    src: PoppinsRegular,
  });
  const styles = StyleSheet.create({
    page: {},
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    viewer: {
      width: 870,
      height: 690,
    },
    bgimages: {
      height:595,
      width:842,
      position: 'absolute'
    },
    title: {
      top: 15,
      textAlign: "center",
      fontSize: 58,
      color: "white",
      fontFamily: "AbhayaLibreSemiBold",
    },
    participation: {
      top: 15,
      textAlign: "center",
      fontSize: 21,
      color: "#F3D649",
      fontFamily: "RobotoBold",
    },
    initialline: {
      letterSpacing: 1.5,
      top: 210,
      textAlign: "center",
      fontSize: 16,
      color: "#000000",
      fontFamily: "AbhayaRegular",
    },
    showcase: {
    //   top: 250,
    marginTop:2,
      textAlign: "center",
      fontSize: 11,
      color: "#000000",
      fontFamily: "PoppinsRegular",
      marginHorizontal: 140,
    },
    complete: {
      top: 260,
      textAlign: "center",
      fontSize: 16,
      color: "#000000",
      fontFamily: "AbhayaRegular",
    },
    completeDate: {
    
        top:410.8,
        left:96.5,
        textAlign: "start",
        fontSize: 13,
        color: "#4c8dcb",
        fontFamily: "PoppinsRegular",
    },
    completeLocation: {
      top: 260,
      textAlign: "center",
      fontSize: 16,
      color: "#000000",
      fontFamily: "AbhayaRegular",
    },
    fullname: {
      textAlign: "center",
      color: "#4c8dcb",
      fontFamily: "AbhayaRegular",
    },
    headNameRight: {
      top:288,
      textAlign: "center",
      fontSize: 16,
      color: "#4c8dcb",
      fontFamily: "AbhayaBold",
    },
    headNameLeft: {
      top:288,
      textAlign: "center",
      fontSize: 16,
      color: "#4c8dcb",
      fontFamily: "AbhayaBold",
      marginLeft: 18,
    },
    headPositionRight: {
      top:286,
      textAlign: "center",
      fontSize: 12,
      color: "#000000",
      fontFamily: "RobotoBold",
      marginRight: 45,
    },
    headPositionLeft: {
      top:286,
      textAlign: "center",
      fontSize: 12,
      color: "#000000",
      fontFamily: "RobotoBold",
      marginLeft: 55,
    },
    QRImage: {
      top:504,
      left: 390,
      position: "absolute",
      width: 75,
    },
  });

  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const zip = new JSZip();
  const folder = zip.folder("files");
  const generateQR = async (text) => {
    try {
      return await QRCode.toDataURL(text, {
        color: {
          dark: "#4c8dcb", // Blue dots
          light: "#0000", // Transparent background
        },
      });
    } catch (err) {
      console.error(err);
    }
  };
  const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const fetchEventRegisteration = async (eventPass) => {
    try {
      const result = await axios
        .get(
          `${window.$domain}/api/method/get-core-team?event=${eventId}`,
          config
        )
        .then((response) => {
          generatePdfDocument(toFilled);
          console.log(response.data.data.length);
          if (response.data.data.length > 0) {
            const forLoop = async () => {
              for (let index = 0; index < response.data.data?.length; index++) {
                
                await sleep(1000);
                // console.log("Doing>>>", index);
// console.log((
//   response.data.data[index]?.delegate_profile.user.first_name +
//   response.data.data[index]?.delegate_profile.user.last_name
// ).length);
setPercentage(response?.data?.data?.length===1?"100":Math.floor(index/(response.data.data?.length-1)*100))
                if (
                  (
                    response.data.data[index]?.first_name +
                    response.data.data[index]?.last_name+""
                  ).length > 25
                ) {
                  // console.log("SAdsadsadasdasdasd");
                  var setFontSize = 36;
                  var setFullNameTop = 389.7;
                  var setLowerViewTop =390;
                  var setcomplete ;
                  if(eventId=="b50a010e23"){

                    setDateformate(60.5)
                    setcomplete = 419.2;
                  }
                  else{
                    setDateformate(96.5)
                    setcomplete = 418.0;
                  }
                } else {
                    // console.log("kkkkkkkkkkkk");
                  var setFontSize = 44;
                  var setFullNameTop = 378.7;
                  var setLowerViewTop = 387.2;
                  var setcomplete ;
                  if(eventId=="b50a010e23"){
                    setcomplete = 411.2;
                    setDateformate(60.5)
                  }
                  else{
                    setDateformate(96.5)
                    setcomplete = 410.2;
                  }
                }

                const generateQR = async (text) => {
                  try {
                    return await QRCode.toDataURL(text, {
                      color: {
                        dark: "#002060", // Blue dots
                        light: "#0000", // Transparent background
                      },
                    });
                  } catch (err) {
                    console.error(err);
                  }
                };

                
                generatePdfDocument(
                  <Document>
                    <Page size={[842, 595]}>
                    {eventId=="b50a010e23"? <Image
                        src={BlankCertificateTurkiye}
                        style={styles.bgimages}
                      ></Image>:<Image
                      src={BlankCertificate}
                      style={styles.bgimages}
                    ></Image>}
                   
                      <Text
                        style={{
                          top: setFullNameTop,
                          textTransform: "capitalize",
                          fontSize: setFontSize,
                          textAlign: "center",
                          color: "#002060",
                          fontFamily: "GoodVibrations",
                        }}
                      >
                        {""}
                        {"" +
                          response.data.data[
                            index
                          ]?.first_name?.toLowerCase()+
                          " " +
                          response.data.data[
                            index
                          ]?.user.last_name?.toLowerCase()}
                      </Text>
                      
                      <View style={{ top: setLowerViewTop }}>
                      <Text style={{
                           marginTop: 2,
                          textAlign: "center",
                          fontSize:14,
                          fontFamily: "PoppinsRegular",
                        color: "#002060",
                        }} >{ response.data.data[index]?.first_line==undefined||response.data.data[index]?.first_line==null||response.data.data[index]?.first_line==""?"    ":response.data.data[index]?.first_line}</Text>
                        <Text style={styles.showcase}>In appreciation of your diligence, dedication, and efforts during the {eventPass?.title}.  </Text>

                      
                         
                        </View>

                      <Text style={
                        {  top:setcomplete,
                            left:eventId=="b50a010e23"?87.6:89.5,
                            textAlign: "start",
                            fontSize: 13,
                            color: "#000000",
                            fontFamily: "PoppinsRegular",}
                      }>
                            {moment(eventPass?.end_date).format(
                              "Do MMMM YYYY"
                            )}
                          </Text>
                      {/* <Image
                        source={generateQR(
                          `https://verify.bestdiplomats.org/verify/${response.data.data[index]?.delegate_profile.name}-${eventId}-${response.data.data[index]?.name}`
                        )}
                        style={styles.QRImage}
                      ></Image> */}
                    </Page>
                  </Document>,
                  response.data.data[index]?.first_name,
                  response.data.data[index]?.last_name
                );
              }
              setTimeout(() => {
                zip
                .generateAsync({ type: "blob" })
                .then((blob) => saveAs(blob, "All Certificates Core Team"));
              setIsDone(true);
              }, 2000);
              
            };
            forLoop();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
  const generatePdfDocument = async (documentData, firstname, lastname) => {
    const blob = await pdf(documentData).toBlob();
    console.log(firstname);
    folder.file(firstname + " " + lastname + ".pdf", blob);

    // saveAs(blob,  firstname+" "+lastname);
  };

  //   Event Details
  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const result = await axios
          .get("https://dash.bestdiplomats.org/api/resource/Events/" + eventId)
          .then((response) => {
            //  console.log(response.data.data.country);
            fetchEventRegisteration(response.data.data);
          });
      } catch (e) {
        console.log(e);
      }
    };
    fetchEventData();
  }, []);

  return (
    <div>
      {isDone ? (
        <span className="group ml-auto mr-auto relative flex w-64 justify-center rounded-md border border-transparent bg-green-700 my-4  py-2 px-4 text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-offset-2">
          Done Downloading
        </span>
      ) : (
        <>
        <span className="group ml-auto mr-auto relative flex w-64 justify-center rounded-md border border-transparent bg-blue-400 my-4  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
        <LoadingIcons.SpinningCircles className="w-7 h-7"/>
        
        </span>
        <div className="flex justify-center">
        <span>This may take a few minutes. Your file will be download automatically.</span>
        </div>
        </>
      )}
      <div className="flex justify-center mt-2">
      <div className="w-96 bg-gray-200 rounded-full dark:bg-gray-700 ">
    <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{'width':percentage+"%"}}> {percentage}%</div>
  </div>
  </div>
    </div>
  );
};

export default CertificateAllCoreTeamForImpactDirector