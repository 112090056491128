import React, { useState } from 'react'
import { Link, Outlet, Route ,Router,Routes, UNSAFE_DataRouterStateContext,NavLink, useParams} from 'react-router-dom'
import { PlusCircleIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')}
const EventItineraryNavBar = () => {
  console.log(localStorage.getItem("selected"));
  const { eventID } = useParams();
  return (<>
  <div className="px-2 mt-2">
   <div className="flex justify-between items-center  rounded-md mb-4">
      { window.location.pathname.includes(`/add-new-itinerary`)? "":<>
      <div className="text-2xl font-semibold leading-2 text-gray-900">
         
          </div> 
           <NavLink to={`add-new-itinerary/${eventID}`}  className="rounded-md flex remove-bg gap-2 bg-blue-500 px-4 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"> Add New Itrinerary <PlusCircleIcon className="h-6 w-6" aria-hidden="true" /></NavLink>

           </>}
      </div>
  

  <Routes>
  </Routes>
   <Outlet/>

    </div>
  </>
  )
}

export default EventItineraryNavBar