import React, { useEffect, useState } from 'react'

import BlankCertificate from "../../img/Certificate of Thanks (3) (1).png";
// import BlankCertificate from '../../img/New Award certificate Blank Title (2).png'

import BlankCertificateTurkiye from "../../img/Certificate OF Thanks For Turkiye.png";
import FontAbhaya from '../../AbhayaLibre-SemiBold.ttf'
import RobotoBold from '../../Roboto-Bold.ttf'
import AbhayaRegular from '../../AbhayaLibre-Regular.ttf'
import AbhayaBold from '../../AbhayaLibre-Bold.ttf'
import GoodVibrations from '../../GoodVibrations Script.ttf'
import PoppinsSemiBold from '../../Poppins-SemiBold.ttf'
import PoppinsRegular from '../../Poppins-Regular.ttf'
import QRCode from 'qrcode'
import { Document, Page, Text, View, StyleSheet, Image, Font, } from '@react-pdf/renderer';
import moment from "moment";
const AwardCertificateForGuest = (props) => {

  // const [dateFormate, setDateformate] = useState(96.5);
  var setDateformate ;
  var setCompleteDateTop;
    if (
        (
          props.FirstName +
          props.LastName
        ).length > 25
      ) {
        var setFontSize = 38;
        var setFullNameTop = 379.2;
        var setLowerViewTop = 147.6;
      
      } else {
        
        var setFontSize = 44;
        var setFullNameTop = 375;
        var setLowerViewTop = 142.5;
     }

    Font.register({
        family: "AbhayaLibreSemiBold",
        src: FontAbhaya,
      });
      Font.register({
        family: "RobotoBold",
        src: RobotoBold,
      });
      Font.register({
        family: "AbhayaRegular",
        src: AbhayaRegular,
      });
      Font.register({
        family: "AbhayaBold",
        src: AbhayaBold,
      });
      Font.register({
        family: "PoppinsSemiBold",
        src: PoppinsSemiBold,
      }); Font.register({
        family: "GoodVibrations",
        src: GoodVibrations,
      }); Font.register({
        family: "PoppinsRegular",
        src: PoppinsRegular,
      });
      const styles = StyleSheet.create({
        page: {},
        section: {
          margin: 10,
          padding: 10,
          flexGrow: 1,
        },
        viewer: {
          width: 870,
          height: 690,
        },
        bgimages: {
          height:595,
          width:842,
          position: 'absolute'
        },
        title: {
          top: 15,
          textAlign: "center",
          fontSize: 58,
          color: "white",
          fontFamily: "AbhayaLibreSemiBold",
        },
        participation: {
          top: 15,
          textAlign: "center",
          fontSize: 21,
          color: "#F3D649",
          fontFamily: "RobotoBold",
        },
        initialline: {
          letterSpacing: 1.5,
          top: 210,
          textAlign: "center",
          fontSize: 16,
          color: "#000000",
          fontFamily: "AbhayaRegular",
        },
        showcase: {
          top: 240,
          textAlign: "center",
          fontSize:10,
          color:'#000000',
          fontFamily:'PoppinsRegular',
          marginHorizontal: 140,
        },
        showcase3: {
          top: 241,
          textAlign: "center",
          fontSize: 12,
          color: "#000000",
          marginRight: 80,
          marginLeft:2,
          fontFamily: "PoppinsSemiBold",
          
        },
        showcase1: {
          top: 242,
          textAlign: "center",
          fontSize: 12,
          color: "#000000",
          fontFamily: "PoppinsRegular",
          marginHorizontal: 140,
        },
        showcase2: {
          top: 244,
          textAlign: "center",
          fontSize: 12,
          color: "#000000",
          fontFamily: "PoppinsRegular",
          marginHorizontal: 140,
        },
        complete: {
          top: 265,
          textAlign: "center",
          fontSize: 16,
          color: "#000000",
          fontFamily: "AbhayaRegular",
        },
        completeDate: {
          top:395.8,
          left:96.5,
          textAlign: "start",
          fontSize: 13,
          color: "#000000",
          fontFamily: "PoppinsRegular",
        },
        completeLocation: {
          top: 265,
          textAlign: "center",
          fontSize: 16,
          color: "#000000",
          fontFamily: "AbhayaRegular",
        },
        fullname: {
          textAlign: "center",
          color: "#4c8dcb",
          fontFamily: "GoodVibrations",
        },
        headNameRight: {
          top:296,
          textAlign: "center",
          fontSize: 16,
          color: "#4c8dcb",
          fontFamily: "AbhayaBold",
        },
        headNameLeft: {
          top:296,
          textAlign: "center",
          fontSize: 16,
          color: "#4c8dcb",
          fontFamily: "AbhayaBold",
          marginLeft: 18,
        },
        headPositionRight: {
          top:293,
          textAlign: "center",
          fontSize: 12,
          color: "#000000",
          fontFamily: "RobotoBold",
          marginRight: 45,
        },
        headPositionLeft: {
          top:293,
          textAlign: "center",
          fontSize: 12,
          color: "#000000",
          fontFamily: "RobotoBold",
          marginLeft: 55,
        },
        QRImage: {
          top:504,
          left: 390,
          position: "absolute",
          display:"none",
          width: 75,
        },
      });
    
    // States
   
    const [qrUriImageFile, setqrUriImageFile] = useState();
  
  const generateQR = async (text) => {
    if (
      (
        props.FirstName +
        props.LastName
      ).length > 25
    ) {
 
      if(props?.event?.name=="b50a010e23"){
        setDateformate= 86.5
        setCompleteDateTop = 390.1;
      }
      else{
        setDateformate=96.5
      setCompleteDateTop = 389.9;
      }
    } else {
      if(props?.event?.name=="b50a010e23"){
        setCompleteDateTop = 383.1;
        setDateformate=86.5
      }
      else{
        setDateformate=89.5
        setCompleteDateTop = 382.8;
      }
   
    }
    try {
      setqrUriImageFile(
        await QRCode.toDataURL(text, {
          color: {
            dark: "#4c8dcb", // Blue dots
            light: "#0000", // Transparent background
          },
        })
      );
    } catch (err) {
      console.error(err);
    }
  

  };
  generateQR(props.qrText);
    
  return (
  
    <Document>
  
       <Page size={[842, 595]}>
                    {props?.event?.name=="b50a010e23"?<Image
                        src={BlankCertificateTurkiye}
                        style={styles.bgimages}
                      ></Image>:<Image
                        src={BlankCertificate}
                        style={styles.bgimages}
                      ></Image>}  
                    
                      <Text
                        style={{
                          top: setFullNameTop,
                          fontSize: setFontSize,
                          textAlign: "center",
                          color: "#002060",
                          textTransform: "capitalize",
                          fontFamily: "GoodVibrations",
                        }}
                      >
                        {""}
                        {"" +
          props.FirstName?.toLowerCase() +
          " " +
          props.LastName?.toLowerCase()}
                      </Text>
                      
                      <View style={{ top: setLowerViewTop }}>
                     
                       <View > 
                       <Text style={{
                        top:240,
                        whiteSpace: 'normal',
                        textAlign: "center",
                     color: "#002060",
                     fontFamily: "PoppinsRegular",
                     fontSize:15,
                    color: "#002060",}}>
                      {props?.title}</Text>  
                          <Text style={styles.showcase}>
                          In recognition of your outstanding contribution as a Guest Speaker at {props.event?.title}, We hereby acknowledge and express our heartfelt gratitude for your invaluable insights, expertise, and dedication to enriching our event.
                        </Text> 
                      </View> 
                   
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                      
                        </View>

                      

                     
                      </View>
                      <Text style={{
      top:setCompleteDateTop,
      left:setDateformate,
      textAlign: "start",
      fontSize: 13,
      color: "#000000",
      fontFamily: "PoppinsRegular",
    }}>{moment(props?.end_date).format("Do MMMM YYYY")}</Text>
                     
                    </Page>
  </Document>
  )
}

export default AwardCertificateForGuest