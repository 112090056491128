import { Fragment, useState, useCallback, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Editor } from "@tinymce/tinymce-react";
import { Toaster, toast } from "sonner";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import {
  LinkIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import ReactQuill, { Quill } from "react-quill";
import Mention from "quill-mention";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import axios from "axios";
import PrivateImage from "../PrivateImage/PrivateImage";
import { useFormik } from "formik";
import moment from "moment";

Quill.register("modules/mention", Mention);
const modules = {
  mention: {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ["@"],
    source: function (searchTerm, renderList) {
      const values = [
        { id: 1, value: "{first_name}" },
        { id: 2, value: "{last_name}" },
        { id: 3, value: "{event_name}" },
      ];

      if (searchTerm.length === 0) {
        renderList(values, searchTerm);
      } else {
        const matches = [];

        for (let i = 0; i < values.length; i++) {
          if (
            ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
          ) {
            matches.push(values[i]);
          }
        }

        renderList(matches, searchTerm);
      }
    },
  },
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
    // ["link", "image", "video"],
  ],
};

const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  // More people...
];

var userEmail;
var JSToken;
var accessToken;
if (localStorage.user) {
  userEmail = localStorage.user_email;
  JSToken = JSON.parse(localStorage.user);
  accessToken = JSToken.access_token;
}
const config = {
  headers: { Authorization: `Bearer ${accessToken}` },
};

const Announcement = ({ eventResponse }) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [editorValue, setEditorValue] = useState("");
  const [previousEditorValue, setPreviousEditorValue] = useState(null);
  const [updateEditorValue, setUpdateEditorValue] = useState("");
  const [allAnnouncements, setAllAnnouncements] = useState([]);
  const [openAnnouncementsDetails, setOPenAnnouncementsDetails] =
    useState(false);
  const [announcementsDetails, setAnnouncementsDetails] = useState({});
  const [createAnnouncementSuccess, setCreateAnnouncementSuccess] =
    useState(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [totalAnnouncements, setTotalAnnouncements] = useState(false);
  const [isNotFound, setIsNotFound] = useState(0);
  const [createEventsImage, setCreateEventsImage] = useState(false);
  
  const [submitImage, setSumbitImage] = useState(false);
  const [flightDocument, setFlightDocument] = useState(false);
  const [loaderFlightFile, setLoaderFlightFile] = useState(false);
  //   const [loaderFlightFile, setSub] = useState(false);
  const [uploadFileResponse, setUploadFileResponse] = useState(null);
  const [removeImage, setRemoveImage]= useState(uploadFileResponse?.message?.file_url);
  const { eventID } = useParams();
  const handleChange = useCallback((value) => {
    const newValue = value.replace(/@/g, "");
    setEditorValue(newValue);
  }, []);
  // const handleChangeupdate = useCallback((value) => {
  //   setUpdateEditorValue(value);
  // }, []);
  const handleCreate = () => {
    setEditorValue("");
    setUploadFileResponse("");
    setOpen(true);
  };
  const handleChangeupdate = (content, editor) => {
    setUpdateEditorValue(content);
    // console.log("Content was updated:", content);
  };
  const handleEditorChange = (content, editor) => {
    setEditorValue(content);
    // console.log("Content was updated:", content);
  };
  const handleDeleteAnnouncement = async (id) =>{
    try {
      await axios
        .delete(
          `${window.$domain}/api/resource/Conference Announcement/${id}`,
          config
        )
        .then((res) => {
          toast.success("Announcement Deleted successfully");
          setCreateAnnouncementSuccess(!createAnnouncementSuccess);
        });
    } catch (e) {
      console.log(e);
      toast.error("Announcement Deletion Failed");
    }
  }
  const handleChangeAnnouncements = (e) => {
    setAnnouncementsDetails(e);
    setUploadFileResponse(null);
    setUpdateEditorValue(e?.announcement_description)
    // console.log("previousEditorValue",e.announcement_description);
    setPreviousEditorValue(e?.announcement_description);
    setCreateEventsImage(false);
    setOPenAnnouncementsDetails(true);
  };
  // console.log(updateEditorValue);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const result = await axios
          .get(
            `${window.$domain}/api/resource/Conference Announcement?fields=["*"]&filters=[["event","=","${eventID}"]]&order_by=creation desc`,
            config
          )
          .then((response) => {
            setAllAnnouncements(response.data.data);
            // console.log("><>>>>>>>>>>>>>>", response.data);
            setTotalAnnouncements(response.data.data.length);
            setLoadingSkeleton(false);
            setIsNotFound(false);
            if (response.data.data == 0) {
              setIsNotFound(true);
              setLoadingSkeleton(true);
              toast.error("No Data Found");
            }
          });
      } catch (e) {
        console.log(e);

        if (e.code == "ERR_NETWORK" || e.response.status == 404) {
          setIsNotFound(true);

          toast.error("No Data Found");
          setLoadingSkeleton(true);
        }
      }
    };
    fetchAnnouncements();
  }, [createAnnouncementSuccess]);

  const postAnnouncement = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      var jsontoPass = {
        announcement_title: values.announcement_title,
        attachment: uploadFileResponse?.message?.file_url,
        announcement_description: editorValue,
        event: eventID,
        send_email: values?.send_email ? 1 : 0
      };
      // console.log(jsontoPass);
      const fetchAmbData = async (jsontoPass) => {
        // console.log(jsontoPass);
        try {
          await axios
            .post(`${window.$domain}/api/resource/Conference Announcement`,jsontoPass, config
            )
            .then((res) => {
              toast.success("Create Announcement successfully");
              setCreateAnnouncementSuccess(!createAnnouncementSuccess);
            });
        } catch (e) {
          console.log(e);
          toast.error("Announcement failed");
        }
      };
      fetchAmbData(jsontoPass);
    },

    enableReinitialize: true,
  });

  function uploadFlightDocument(e) {
    setFlightDocument(e.target.files[0].name);
    const fileFlight = e.target.files[0];
    const formData = new FormData();
    formData.append("file", fileFlight);
    formData.append("is_private", 1);

    const uploadFlightApi = async () => {
      try {
        setLoaderFlightFile(true);
        setSumbitImage(true);
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData,
            config
          )
          .then(async (responseFlight) => {
            
            
            await setUploadFileResponse(responseFlight.data);
            setCreateEventsImage(true);
            setSumbitImage(false);
            // setTimeout(() => {

            //   setCreateEventsImage(false);
            // }, 1000);
          });
        setLoaderFlightFile(false);
      } catch (e) {
        console.log(e);
      }
    };

    uploadFlightApi();
  }

  const UpdateAnnouncement = useFormik({
    initialValues: {
      announcement_title: announcementsDetails?.announcement_title,
      attachment: announcementsDetails?.attachment,
      status: announcementsDetails?.status,
    },
    onSubmit: (values) => {
      var jsontoPass = {
        announcement_title: values.announcement_title,
        status: values.status,
        attachment: uploadFileResponse?.message?.file_url,
        announcement_description: updateEditorValue,
        event: eventID,
      };

      const fetchAmbData = async (jsontoPass) => {
        // console.log(jsontoPass);
        try {
          await axios
            .put(
              `${window.$domain}/api/resource/Conference Announcement/${announcementsDetails.name}`,
              jsontoPass,
              config
            )
            .then((res) => {
              toast.success("Announcement Updated successfully");
              setCreateAnnouncementSuccess(!createAnnouncementSuccess);
            });
        } catch (e) {
          console.log(">>>>>>>>>",e);
          toast.error("Announcement failed");
        }
      };
      fetchAmbData(jsontoPass);
    },

    enableReinitialize: true,
  });

  return (
    <>
      <div className="pt-10">
        <Toaster position="bottom-left" richColors />
        <div className="flex  divide-y flex-col justify-end">
          <div className="mt-4 sm:ml-16 sm:mt-0  flex justify-end  sm:flex-none">
            <button
              type="button"
              onClick={() => handleCreate()}
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Create Announcement
            </button>
          </div>
          <div className="mt-1 py-1 divide-y ">
            <p className="felx justify-end text-right font-semibold text-gray-600">
              {loadingSkeleton ? (
                <Skeleton width={60} height={25} />
              ) : (
                <> Total Announcement: ( {totalAnnouncements} ) </>
              )}
            </p>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 max-h-screen overflow-x-auto ">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              {isNotFound ? (
                <div className="h-screen flex justify-center flex-col items-center ">
                  <svg
                    fill="none"
                    className="w-40 h-40 text-gray-400"
                    stroke="currentColor"
                    strokeWidth={1.5}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
                    />
                  </svg>{" "}
                  <span className=" text-lg text-gray-400"> No Data Found</span>
                </div>
              ) : (
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Announcement Title
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                         Creation

                        </th>
                        {/* <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Email
                      </th> */}
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    {loadingSkeleton ? (
                      <tbody className="divide-y divide-gray-200 bg-white">
                        <tr>
                          <td className="whitespace-nowrap py-4 pl-2 pr-3 text-sm font-medium text-gray-900 ">
                            <div className="flex items-center">
                              <div className="h-12 w-12 flex-shrink-0 flex items-center">
                                <Skeleton
                                  borderRadius={40}
                                  width={50}
                                  height={50}
                                />
                              </div>
                              <div className="ml-2 flex items-center">
                                <div className="font-medium text-gray-900">
                                  <Skeleton width={90} height={20} />
                                </div>
                                {/* <div className="mt-1 text-gray-500">
                                {person.email}
                              </div> */}
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <Skeleton width={90} height={30} />
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {" "}
                            <Skeleton
                              borderRadius={20}
                              width={60}
                              height={30}
                            />
                          </td>

                          <td className="text-indigo-600 hover:text-indigo-900">
                            <Skeleton width={60} height={20} />
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {allAnnouncements?.map((person) => (
                          <tr key={person.name}>
                            <td className="whitespace-nowrap py-4 pl-2 pr-3 text-sm font-medium text-gray-900 ">
                              <div className="flex items-center">
                                {/* <div
                                  className="h-12 cursor-pointer w-12 flex-shrink-0 flex items-center"
                                  onClick={() =>
                                    handleChangeAnnouncements(person)
                                  }
                                >
                                  <PrivateImage
                                    imageUrl={person.attachment}
                                    className={"h-10 w-10 rounded-full"}
                                  />
                                </div> */}
                                <div className="ml-2 flex items-center">
                                  <div className="font-medium text-gray-900">
                                    {person.announcement_title}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          {moment(person?.creation).format('LL')}
                              {/* <p
                                dangerouslySetInnerHTML={{
                                  __html: person.announcement_description,
                                }}
                                className="custom-content"
                              ></p> */}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span className={`inline-flex rounded-full bg-${person.status=="New"?"green":"orange"}-100 px-2 text-xs font-semibold leading-5 text-${person.status=="New"?"green":"orange"}-800`}>
                              {person.status}
                            </span>
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <div className="flex gap-2">
                                
                                <PencilIcon className="h-6 w-6 flex justify-center items-center text-gray-500 cursor-pointer" onClick={() =>{
                                    handleChangeAnnouncements(person)
                                    setPreviousEditorValue(person.announcement_description)
                                }}/>
                                  
                                 

                                <TrashIcon className="h-6 w-6 flex justify-center items-center text-red-500 cursor-pointer" onClick={()=>handleDeleteAnnouncement(person.name)}/>
                                  </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Create Announcement for {eventResponse}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}

                      <div className="divide-y items-center divide-gray-200">
                        {/* <div className="pb-6">
                          <div className="h-32 bg-indigo-500 sm:h-32 lg:h-32" />
                          <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                            <div>
                              <div className="-m-1 flex">
                                {/* <div className="inline-flex overflow-hidden rounded-lg border-4 border-white">
                                  {
                                    <PrivateImage
                                      imageUrl={
                                        uploadFileResponse?.message?.file_url
                                      }
                                      className={
                                        "h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"
                                      }
                                    />
                                  }
                                </div> */}
                           {/*    </div>
                            </div>
                            <div className="mt-3 sm:ml-6 sm:flex-1">
                              <div>
                                <div className="">
                                  <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                    {/* {eventsDetails?.title} */}
                                 {/*  </h3>
                                  <span className=""></span>
                                </div>
                                <p className="text-sm text-gray-500">{}</p>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <form
                          onSubmit={postAnnouncement.handleSubmit}
                          className="flex  flex-col divide-y divide-gray-200 bg-white shadow-xl"
                        >
                            
                          <div className="">
                       

                            <div className="space-y-10 px-4">
                            
                              <div className="border-b border-gray-900/10 pb-2 ">
                             
                                <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-7">
                                  <div className=" flex flex-col gap-4 sm:col-span-7">
                                    <div>
                                      {" "}
                                      <span className="">Attach a File</span>
                                      <div className="w-20 pt-2">
                                        {submitImage ? (
                                          <button
                                            disabled
                                            type="button"
                                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                                          >
                                            <svg
                                              aria-hidden="true"
                                              role="status"
                                              class="inline w-4 h-4 mr-3 text-white animate-spin"
                                              viewBox="0 0 100 101"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"
                                              />
                                              <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                            Loading...
                                          </button>
                                        ) : (
                                          <>
                                            {" "}
                                            <label
                                              htmlFor="formFileSm"
                                              className="flex items-center cursor-pointer justify-center  h-10  rounded-md border border-blue-300 bg-blue-500 px-2 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                            >
                                              Attach
                                            </label>
                                            <input
                                              // accept="image/x-png,image/gif,image/jpg,image/jpeg"
                                              className=" sr-only "
                                              id="formFileSm"
                                              type="file"
                                              onClick={
                                                postAnnouncement.resetForm
                                              }
                                              onChange={(e) => {
                                                uploadFlightDocument(e);
                                              }}
                                            ></input>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    {uploadFileResponse?.message?.file_url==""||uploadFileResponse?.message?.file_url==null||uploadFileResponse?.message?.file_url==undefined? null:<><div className="flex text-green-600 items-center gap-2 pl-2"><p>{uploadFileResponse?.message?.file_name}</p><XMarkIcon onClick={()=>setUploadFileResponse(null)}
                                className="h-6 text-red-500 w-6"
                                aria-hidden="true"
                              /> </div></> } 
                                      
                                  </div>
                                </div>
                                <div className="flex-1 pt-4">
                                  {/* Divider container */}

                                  {/* Project name */}
                                  <div className=" flex flex-col ">
                                    <div>
                                      <label
                                        htmlFor="send_email"
                                        className="block text-sm font-medium leading-6 text-gray-900 "
                                      >
                                        Send Email <span className=" font-thin italic text-gray-500 text-sm">(Select if you want to send email as well)</span>
                                      </label>
                                    </div>
                                    <div className="sm:col-span-3">
                                      <input
                                        type="checkbox"
                                        name="send_email"
                                        id="send_email"
                                        onChange={postAnnouncement.handleChange}
                                        value={ postAnnouncement.values.send_email}
                                        className=" rounded"
                                      />
                                    </div>
                                  </div>

                                  <div className="  sm:grid sm:grid-cols-3 sm:gap-2 ">
                                    <div>
                                      <label
                                        htmlFor="announcement_title"
                                        className="block text-sm font-medium leading-6 text-gray-900 "
                                      >
                                        Announcement Title
                                      </label>
                                    </div>
                                    <div className="sm:col-span-3">
                                      <input
                                        type="text"
                                        name="announcement_title"
                                        id="announcement_title"
                                        required
                                        onChange={postAnnouncement.handleChange}
                                        value={ postAnnouncement.values.announcement_title}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>

                                  {/* Project description */}
                                  <div className="space-y-2   sm:gap-4 sm:space-y-0   sm:py-5">
                                    <Editor
                                      initialValue=""
                                      apiKey="148jqff4vmvlainlcirs1406j5xa3v54yibpjnzm7xh0etg6"
                                      init={{
                                        height: "400",
                                        borderColor: "20px",
                                        branding: false,
                                        menubar: true,
                                        plugins:
                                          "print preview paste searchreplace autolink directionality visualblocks visualchars link template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                        toolbar:
                                          "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                        image_advtab: true,
                                      }}
                                      onEditorChange={handleEditorChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-shrink-0 justify-end px-4 py-4">
                            <button
                              type="button"
                              className="rounded-md w-32 bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={() => setOpen(false)}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="ml-4 w-32 inline-flex justify-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            >
                              Post 
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={openAnnouncementsDetails} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={setOPenAnnouncementsDetails}
        >
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Announcement
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                              onClick={() => setOPenAnnouncementsDetails(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      <div className="divide-y items-center divide-gray-200">
                        <div className="pb-6">
                          {/* <div className="h-24 bg-indigo-500 sm:h-10 lg:h-20" /> */}
                          <div className="h-24 bg-indigo-500 flex items-center pl-5  sm:h-10 lg:h-20">
                            {/* <div>
                              <div className="flex items-center"> */}
                                {/* <div className="inline-flex overflow-hidden rounded-lg border-4 border-white">
                                  {createEventsImage ? (
                                    <PrivateImage
                                      imageUrl={
                                        uploadFileResponse?.message?.file_url
                                      }
                                      className={
                                        "h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"
                                      }
                                    />
                                  ) : (
                                    <PrivateImage
                                      imageUrl={announcementsDetails.attachment}
                                      className={
                                        "h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"
                                      }
                                    />
                                  )}
                                </div> */}
                             
                            <div className=" flex items-center">
                              <div>
                                <div className="">
                                  <h3 className="text-xl font-bold text-white sm:text-2xl">
                                    {announcementsDetails.announcement_title}
                                  </h3>
                                </div>
                                <p className="text-sm text-gray-500">{}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <form
                          onSubmit={UpdateAnnouncement.handleSubmit}
                          className="flex  flex-col divide-y divide-gray-200 bg-white shadow-xl"
                        >
                          <div className="">
                            <div className="space-y-10 px-4">
                              <div className="border-b border-gray-900/10 pb-2 ">
                                <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-7">
                                  <div className=" flex flex-col gap-4 sm:col-span-7">
                                    <div>
                                      {" "}
                                      <span className="">Attach a File</span>
                                      <div className="w-20 pt-2">
                                        {submitImage ? (
                                          <button
                                            disabled
                                            type="button"
                                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                                          >
                                            <svg
                                              aria-hidden="true"
                                              role="status"
                                              class="inline w-4 h-4 mr-3 text-white animate-spin"
                                              viewBox="0 0 100 101"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"
                                              />
                                              <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                            Loading...
                                          </button>
                                        ) : (
                                          <>
                                            {" "}
                                            <label
                                              htmlFor="formFileSm"
                                              className="flex items-center cursor-pointer justify-center  h-10  rounded-md border border-blue-300 bg-blue-500 px-2 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                            >
                                              Attach
                                            </label>
                                            <input
                                              // accept="image/x-png,image/gif,image/jpg,image/jpeg"
                                              className=" sr-only "
                                              id="formFileSm"
                                              type="file"
                                              onClick={
                                                UpdateAnnouncement.resetForm
                                              }
                                              onChange={(e) => {
                                                uploadFlightDocument(e);
                                              }}
                                            ></input>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    {uploadFileResponse?.message?.file_url==""||uploadFileResponse?.message?.file_url==null||uploadFileResponse?.message?.file_url==undefined? null:<><div className="flex text-green-600 items-center gap-2 pl-2"><p>{uploadFileResponse?.message?.file_name}</p><XMarkIcon onClick={()=>setUploadFileResponse(null)}
                                className="h-6 text-red-500 w-6"
                                aria-hidden="true"
                              /> </div></> } 
                                  </div>
                                </div>
                                <div className="flex-1 pt-4">
                                  {/* Divider container */}

                                  {/* Project name */}
                                  <div className="  sm:grid sm:grid-cols-3 sm:gap-2 ">
                                    <div>
                                      <label
                                        htmlFor="announcement_title"
                                        className="block text-sm font-medium leading-6 text-gray-900 "
                                      >
                                        Announcement Title
                                      </label>
                                    </div>
                                    <div className="sm:col-span-3">
                                      <input
                                        type="text"
                                        name="announcement_title"
                                        id="announcement_title"
                                        required
                                        onChange={ UpdateAnnouncement.handleChange}
                                        value={  UpdateAnnouncement.values.announcement_title }
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                  <div className="  sm:grid sm:grid-cols-3 sm:gap-2 ">
                                    <div>
                                      <label
                                        htmlFor="status"
                                        className="block text-sm font-medium leading-6 text-gray-900 "
                                      >
                                        Status
                                      </label>
                                    </div>
                                    <div className="sm:col-span-3">
                                      <select
                                        type="text"
                                        name="status"
                                        id="status"
                                        required
                                        onChange={
                                          UpdateAnnouncement.handleChange
                                        }
                                        value={UpdateAnnouncement.values.status}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                      >
                                        
                                        <option value="">None</option>
                                        <option value="New">New</option>
                                        <option value="Updated">Updated</option>
                                      </select>
                                    </div>
                                  </div>
                                  {/* Project description */}
                                  <div className="space-y-2   sm:gap-4 sm:space-y-0   sm:py-5">
                                    <Editor
                                      initialValue={previousEditorValue}
                                      apiKey="148jqff4vmvlainlcirs1406j5xa3v54yibpjnzm7xh0etg6"
                                      init={{
                                        height: "400",
                                        borderColor: "20px",
                                        branding: false,
                                        menubar: true,
                                        plugins:
                                          "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen link  template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                        toolbar:
                                          "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                        image_advtab: true,
                                      }}
                                      onEditorChange={handleChangeupdate}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-shrink-0 justify-end px-4 py-4">
                            <button
                              type="button"
                              className="rounded-md w-32 bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={() => setOPenAnnouncementsDetails(false)}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="ml-4 w-32 inline-flex justify-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            >
                              Update
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Announcement;
