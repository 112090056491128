import React, { useEffect, useState } from "react";

import FontRoboto from "../../Roboto-Bold.ttf";
import Gold from '../../img/Gold Full Experience.png'
import Blue from '../../img/Blue Accommodation.png'
import Black from '../../img/Black Non Accommodation.png'
import QRCode from "qrcode";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
  PDFDownloadLink
} from "@react-pdf/renderer";

const IdCardAll = (props) => {


  Font.register({
    family:"RobotoBold",
    src: FontRoboto,
  });
  // Create styles
  const styles = StyleSheet.create({
    page: { backgroundColor:'#24579D' },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    viewer: {
      width: 500,
      height: 770,
    },
    images: {
      height: 416,
      position: "absolute",
    },
    imagesProfile: {
      width: 154.1,
      top: 81.7,
      left: 61.7,
      position: "absolute",
      borderRadius: 100,
      overflow:'hidden'
    },
    title: {
      fontFamily:"RobotoBold",
      color: "#4591DC",
      top: 248,
      paddingHorizontal: 5,
      textAlign: "center",
    },
    designation: {
      color: "#000000",
      fontFamily:"RobotoBold",
      paddingHorizontal:10,
      fontSize: 14,
      marginTop: 4,
      textAlign:'center',
      top: 248,
    },
    eventname: {
      color: "#ffffff",
      textAlign: "center",
      position: "absolute",
      fontSize: 13,
      top: 395,
      left: 0,
      right:0,
      margin:"auto",
    },
    qrimage: {
      width: 50,
      top: 248,
      position: "absolute",
      left: 112,
      top: 338,
    },
    representingtwo: {
      color: "#000000",
      fontFamily:"RobotoBold",
      textAlign:'center',
      paddingHorizontal:10,
      marginTop:4,
      fontSize: 14,
      top: 248,
    }
  });

  const [qrUriImageFile, setqrUriImageFile] = useState();
  
  const generateQR = async (text) => {
    try {
      setqrUriImageFile(
        await QRCode.toDataURL(text, {
          color: {
            dark: "#4591DC", // Blue dots
            light: "#0000", // Transparent background
          },
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  generateQR(props.qrText);

  return (
    <>
    {props.payment_package=="Blue"  && <Document>
    <Page size={[277, 420]} style={styles.page}>
      <Image
        style={styles.images}
        source={Blue}
      />
      
      <Image style={styles.imagesProfile} source={props.profileImage} />
      
      <Text style={styles.title}>
        {props.firstName +
          " " +
          props.lastName}
      </Text>
      <Text style={styles.designation}>{""+props.firstLine}</Text>
      
      
      <Text style={styles.representingtwo}>
        {""+props.secondLine}
      </Text>
      
      <Text style={styles.eventname}>{"" + props.eventName}</Text>
      <Image style={styles.qrimage} source={qrUriImageFile} />
    </Page>
  </Document>}
    {props.payment_package=="Black"  && <Document>
    <Page size={[277, 420]} style={styles.page}>
      <Image
        style={styles.images}
        source={Black}
      />
      
      <Image style={styles.imagesProfile} source={props.profileImage} />
      
      <Text style={styles.title}>
        {props.firstName +
          " " +
          props.lastName}
      </Text>
      <Text style={styles.designation}>{""+props.firstLine}</Text>
      
      
      <Text style={styles.representingtwo}>
        {""+props.secondLine}
      </Text>
      
      <Text style={styles.eventname}>{"" + props.eventName}</Text>
      <Image style={styles.qrimage} source={qrUriImageFile} />
    </Page>
  </Document>}
    {props.payment_package=="Gold"  && <Document>
    <Page size={[277, 421]} style={styles.page}>
      <Image
        style={styles.images}
        source={Gold}
      />
      
      <Image style={styles.imagesProfile} source={props.profileImage} />
      
      <Text style={styles.title}>
        {props.firstName +
          " " +
          props.lastName}
      </Text>
      <Text style={styles.designation}>{""+props.firstLine}</Text>
      
      
      <Text style={styles.representingtwo}>
        {""+props.secondLine}
      </Text>
      
      <Text style={styles.eventname}>{"" + props.eventName}</Text>
      <Image style={styles.qrimage} source={qrUriImageFile} />
    </Page>
  </Document>}
    
      </>   
  );
};

export default IdCardAll;
