import {
    ArrowDownIcon,
    ArrowLeftIcon,
    ArrowUpIcon,
    ArrowUpRightIcon,
    CheckCircleIcon,
    NoSymbolIcon,

    ArrowTopRightOnSquareIcon
  } from "@heroicons/react/24/outline";
  import Skeleton from "react-loading-skeleton";
  import { Fragment } from "react";
  import { Disclosure } from "@headlessui/react";
  import { Dialog, Menu, Transition } from "@headlessui/react";
  import { XMarkIcon } from "@heroicons/react/24/outline";
  import { useRef, useState } from "react";
  import { Link, useParams } from "react-router-dom";
  import axios from "axios";
  import { useEffect } from "react";
  import { toast } from "sonner";
  import moment from "moment";
  import { useFormik } from "formik";
  import { CopyToClipboard } from 'react-copy-to-clipboard';
import PrivateImage from "../../PrivateImage/PrivateImage";
import { ArrowUpLeftIcon } from "@heroicons/react/20/solid";
  const people = [
    {
      name: "Lindsay Walton",
      title: "Front-end Developer",
      department: "Optimization",
      email: "lindsay.walton@example.com",
      role: "Member",
      image:
        "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    // More people...
  ];
  const stats = [
    { name: "days ago last activety", value: "405" },
    { name: "Points", value: "3.65", unit: "mins" },
    { name: "Badge", value: "3" },
  ];
  const statss = [
    { name: "Number of deploys", value: "405" },
    { name: "Average deploy time", value: "3.65", unit: "mins" },
  ];
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  var userEmail;
  var Role;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    Role = localStorage.role;
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const LeadsProfile = () => {
    const tableRef = useRef(null);
    const [isNotFoundAmb, setIsNotFoundAmb] = useState(false);
    const [CrProfile, setCrProfile] = useState(null);
    const [leadsData, setLeadsData] = useState([]);
    const [loadingSkeleton, setLoadingSkeleton] = useState(true);
    const { eventID } = useParams();
    const [apiFilters, setApiFilters] = useState(`["event","=","${eventID}"]`);
    const [delegateProfile, setDelegateProfile] = useState(null);
    const [openEvent, setOpenEvent] = useState(false);
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    // console.log(eventID);
    useEffect(() => {
      
          const fetchSingleCrProfile = async () => {
        try {
          await axios
            .get(`${window.$domain}/api/method/cr-profile?id=${eventID}`, config)
            .then((response) => {
              setCrProfile(response.data.data);
              // console.log("totlat ", response.data.data);
            });
        } catch (e) {
          // console.log(e);
          // toast.error("No Data Found");
        }
      };
      fetchSingleCrProfile();
    }, []); 
     useEffect(() => {
      setLoadingSkeleton(true);
      const fetchLeadData = async () => {
        try {
          await axios
            .get(`${window.$domain}/api/method/cr-leads?filters=[${apiFilters}]`, config)
            .then((response) => {
              setLeadsData(response.data.data);
              // console.log("Ferch Leads ", response.data.data);
              setLoadingSkeleton(false);
              setIsNotFoundAmb(false)
              if (response?.data?.data?.length ==0) {
                  // setLoadingSkeleton(true);
                  // toast.error("No Data Found");
                  setIsNotFoundAmb(true);
                }
            });
        } catch (e) {
          console.log(e);
          setLoadingSkeleton(false);
        
          if(e.response?.status==404){
            // toast.error("No Data Found");
          }
        }
      };
      fetchLeadData();
    }, [apiFilters]);
  
  
  
  
  const applyFiltersLead = useFormik({
    initialValues: {event_name:eventID},
    // onSubmit: (values) => {
    //   // console.log("lead_status", values);
    //   setApiFilters(`["representative","is","set"]`);
    //   //  Payment Package
    //   if (values.lead_status) {
    //     if (values.lead_status != "None") {
      
    //       setApiFilters((prevState) => [
    //         prevState + `,["lead_status","=","${values?.lead_status}"]`,
    //       ]);
    //     } else {
    //       setApiFilters(`["event","=","${eventID}"]`);
    //     }
    //   }
    // },
    onSubmit: (values) => {
      // console.log(values);
      if(values?.event_name!="None"){
        setApiFilters(`["event","=","${values?.event_name}"]`);
      }else{
        setApiFilters(`["representative","is","set"]`);
        
      }
  
      if (values.lead_status) {
        if (values.lead_status != "None") {
        
          setApiFilters((prevState) => [
            prevState + `,["lead_status","=","${values?.lead_status}"]`,
          ]);
        }
      }
    },
    enableReinitialize: true,
  });
  const [textToCopy, setTextToCopy] = useState('Hello, world!');
  const [isCopied, setCopied] = useState(false);
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 7000);
    // You can perform additional actions after copying, if needed
    // For example, show a success message, etc.
  };
  const ViewProfiles = (event) => {
      setDelegateProfile(event)
      setOpenEvent(true);
    };
  
    return (
      <> 
     
        <div className="flex  divide-y flex-col justify-end">
           
           <form onSubmit={applyFiltersLead.handleSubmit} className="flex mt-6 mb-1 justify-between items-center">
           <div className=" sm:ml-6  flex justify-start items-center   sm:flex-none">
  </div>
        <div className=" flex justify-end gap-3 pb-1 items-end  sm:mt-0 sm:ml-5 sm:flex-none">
        <div className="flex gap-y-1 flex-col">
        <label htmlFor="event_name" className="">Event</label>
             <select
               id="event_name"
               name="event_name"
               className=" block w-60  rounded-md border-gray-300 py-2 pl-3 pr-6 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
               onChange={applyFiltersLead.handleChange}
               value={applyFiltersLead.values.event_name}
             >
               <option value="None">All Events</option>
               <option value={eventID}>This Event</option>
             </select> 
        </div>
        <div className="flex gap-y-1 flex-col"> <label htmlFor="lead_status" className="">Lead Status</label>
             <select
               id="lead_status"
               name="lead_status"
               className=" block w-60  rounded-md border-gray-300 py-2 pl-3 pr-6 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
               onChange={applyFiltersLead.handleChange}
               value={applyFiltersLead.values.lead_status}
             >
               <option value="None">All</option>
               <option value="Account Created">Account Created</option>
               <option value="Event Registered">Event Registered</option>
               <option value="Event Payment Done">Event Payment Done</option>
               <option value="Closed">Closed</option>
             </select></div>
             <button
               type="submit"
               className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
             >
               Apply Filter
             </button>
           </div>
         </form>
         </div>
        <div className="px-4  sm:px-6 lg:px-4">
          <div className=" flex flex-col">
            <div
              ref={tableRef}
              className="my-0 -mx-4 overflow-x-auto max-h-screen sm:-mx-4 lg:-mx-4"
            >
              <div
                ref={tableRef}
                className="inline-block overflow-auto min-w-full py-1 align-middle md:px-1 lg:px-1"
              >
               
                {isNotFoundAmb ? (
                  <div className="h-screen flex justify-center flex-col items-center ">
                    <svg
                      fill="none"
                      className="w-40 h-40 text-gray-400"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
                      />
                    </svg>{" "}
                    <span className=" text-lg text-gray-400"> No Data Found</span>
                  </div>
                ) : (
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    {" "}
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-stone-100">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                          Representative
                          </th> <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                          Status
                          </th>
  
                          
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Event 
                          </th>  <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Registration Status
                          </th>  <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                          Payment Status
                          </th> <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Country
                          </th>
                          {/* <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                           DOB
                          </th> */}
                        
                        </tr>
                      </thead>
                      {loadingSkeleton ? (
                        <tbody className="divide-y divide-gray-200 bg-white">
                          <tr>
                            <td className="whitespace-nowrap py-3 pl-4  text-sm sm:pl-6">
                              <div className="flex items-center">
                                <div className="h-14 w-14 flex-shrink-0">
                                  <Skeleton
                                    borderRadius={40}
                                    width={50}
                                    height={50}
                                  />
                                </div>
                                <div className="ml-2">
                                  <div className="font-medium cursor-pointer text-gray-900">
                                    <Skeleton width={90} height={10} />
                                  </div>
                                  <div className="text-gray-500">
                                    <Skeleton width={80} height={10} />
                                  </div>
                                </div>
                              </div>
                            </td>
  
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <Skeleton />
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <Skeleton />
                            </td> 
                             <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <Skeleton />
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <Skeleton />
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <Skeleton />
                            </td>
                             {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <Skeleton />
                            </td> */}
                         
                          </tr>
                        </tbody>
                      ) : (
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {leadsData?.map((lead) => (
                            <tr key={lead?.name}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                <div className="flex items-center">
                                  <div
                                    className="h-14 w-14 flex-shrink-0"
                                    onClick={() => {
                                      ViewProfiles(lead);
                                      // setUploadFileResponse("");
                                    }}
                                  >
                                    <PrivateImage
                                      imageUrl={lead?.delegate_profile?.user?.user_image}
                                      className={
                                        "h-14 w-14 cursor-pointer rounded-full"
                                      }
                                    />
                                  </div>
                                  <div className="ml-4">
                                    <div
                                     onClick={() => {
                                      ViewProfiles(lead);
                                      // setUploadFileResponse("");
                                    }}
                                      className="font-medium cursor-pointer text-gray-900"
                                    >
                                    
                                      {lead?.delegate_profile?.user?.first_name +" "+ lead?.delegate_profile?.user?.last_name}
                                    </div>
                                    <div className="text-gray-500">
                                    {lead?.delegate_profile?.user?.email}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {lead?.representative?.fullname == "" ||
                                  lead?.representative?.fullname == null ? (
                                    <div className="mt-1 sm:col-span-2 sm:mt-0 sm:ml-6 inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                    </div>
                                  ) : (
                                    <div className="text-gray-900">
                                      {" "}
                                      <Link className="inline-flex items-center justify-center  px-4 py-2 text-sm font-medium text-gray-500  sm:w-auto" to={`/OperationsDashboard/${eventID}/country-rep/${eventID}/representative/${eventID}/cr-module/${lead?.representative?.name}`}>{lead?.representative?.fullname}<ArrowTopRightOnSquareIcon className="w-5 h-5 ml-1"/> </Link>
                                    </div>
                                  )}{" "}
                                </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {lead.lead_status == null ||
                                  lead.lead_status == "Closed" ? (
                                    <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                    </span>
                                  ) : lead.lead_status == "Account Created" ? (
                                    <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-600">
                                      {lead.lead_status}
                                    </span>
                                  ) :  (
                                    <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                      {lead.lead_status}
                                    </span>
                                  )}
                              </td>
                         
                                
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {lead?.lead_status==="Event Registered"||lead?.lead_status==="Event Payment Done"||lead?.lead_status==="Closed"?
                                    <div className="text-gray-900">
                                    {lead?.registration_request?.event_title}
                                    </div>:"Pending"
                                  }
                                  </td>   
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {lead?.lead_status==="Event Registered"||lead?.lead_status==="Event Payment Done"||lead?.lead_status==="Closed"?
                                    <div className="text-gray-900">
                                    {lead?.registration_request?.registration_status}
                                    </div>
                                   :"Pending"
                                   }
                                  </td> 
                                   <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                   {lead?.lead_status==="Event Registered"||lead?.lead_status==="Event Payment Done"||lead?.lead_status==="Closed"? 
                                    <div className="text-gray-900">
                                     <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">{lead?.registration_request?.payment_status}</span> 
                                    </div>
                                   :"Pending"
                                  } 
                                  </td>
                                
  
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {lead?.delegate_profile?.nationality == "" ||lead?.delegate_profile?.nationality == undefined ||
                                  lead?.delegate_profile?.nationality == null ? (
                                    <div className="mt-1 sm:col-span-2 sm:mt-0 sm:ml-6 inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                    </div>
                                  ) : (
                                    <div className="text-gray-900">
                                      {" "}
                                      {lead?.delegate_profile?.nationality}
                                    </div>
                                  )}{" "}
                                </td>
                              {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {lead?.delegate_profile?.birthday == "" ||
                                  lead?.delegate_profile?.birthday == null ? (
                                    <div className="mt-1 sm:col-span-2 sm:mt-0 sm:ml-6 inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                      Missing
                                    </div>
                                  ) : (
                                    <div className="text-gray-900">
                                      {" "}
                                      {lead?.delegate_profile?.birthday}
                                    </div>
                                  )}{" "}
                                </td> */}
                             
                              
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Transition.Root show={openEvent} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpenEvent}>
            <div className="fixed inset-0" />
  
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full ">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                      <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              Profile
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                                onClick={() => setOpenEvent(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* Main */}
                        <div className="divide-y divide-gray-200">
                          <div className="pb-6">
                            <div className="h-24 banner-back sm:h-20 lg:h-28" />
                            <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                              <div>
                                <div className="-m-1 flex">
                                  <div className="inline-flex overflow-hidden rounded-lg border-4 border-white">
                                  <PrivateImage imageUrl={delegateProfile?.delegate_profile?.user?.user_image} className={"h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"}/> 
                                    
                                  </div>
                                </div>
                              </div>
                              <div className="mt-6 sm:ml-6 sm:flex-1">
                                <div>
                                  <div className="flex items-center ">
                                    <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                      {delegateProfile?.delegate_profile?.user?.first_name}{" "}
                                      {delegateProfile?.delegate_profile?.user?.last_name}
                                    </h3>
                                  </div>
                                  <p className="text-sm  mt-0.5 text-gray-500">
                                    {delegateProfile?.delegate_profile?.user?.email}
                                  </p>  
                                   <p className="text-sm mt-1 text-gray-500">
                                    {delegateProfile?.lead_status==="Event Payment Done"||delegateProfile?.lead_status==="Event Registered"? <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                      {delegateProfile?.registration_request?.registration_status}
                                    </span> :""}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="px-4 py-5 sm:px-0 sm:py-0">
                            <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                            
                            {delegateProfile?.lead_status==="Event Payment Done"||delegateProfile?.lead_status==="Event Registered"? <><div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                               Event 
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                {delegateProfile?.registration_request?.event_title}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Payment Status
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                {delegateProfile?.registration_request?.payment_status}
                                    </span>
                                
                                </dd>
                              </div>   <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Representative 
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                              <Link className="inline-flex items-center justify-center  px-4 py-2 text-sm font-medium text-gray-500 s     sm:w-auto" to={`/OperationsDashboard/${eventID}/country-rep/${eventID}/representative/${eventID}/cr-module/${delegateProfile?.representative?.name}`}>{delegateProfile?.representative?.fullname}<ArrowTopRightOnSquareIcon className="w-5 h-5 ml-1"/></Link>
                                
                                </dd>
                              </div>
                               <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Registration Approval Date
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                {  moment(delegateProfile?.registration_request?.registration_approval_date).format('LL')}
                                    </span>
                                
                                </dd>
                              </div></>:""}
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Address
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                {delegateProfile?.delegate_profile?.address}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Passport Number
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                {delegateProfile?.delegate_profile?.passport_number?.toUpperCase()}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Passport Expiry
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                {delegateProfile?.delegate_profile?.passport_expiry}
                                </dd>
                              </div> 
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Gender
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                {delegateProfile?.delegate_profile?.gender}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Country
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                {delegateProfile?.delegate_profile?.nationality}
                                </dd>
                              </div>
                               <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Marital Status
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2  sm:ml-6 sm:mt-0">
                                {delegateProfile?.delegate_profile?.marital_status}
                                </dd>
                              </div>
  
                              {/* <form
                                onSubmit={Observersdetailupdate.handleSubmit}
                                className="flex flex-col divide-y divide-gray-200 gap-2"
                              >
                                <div className="sm:flex sm:px-6 sm:py-5">
                                  <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                    Payment Package
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 flex justify-center items-center gap-3 sm:mt-0 sm:ml-6">
                                    <label
                                      htmlFor="package"
                                      className="sr-only"
                                    ></label>
                                    <select
                                      type="text"
                                      name="package"
                                      id="package"
                                      value={Observersdetailupdate.values.package}
                                      onChange={
                                        Observersdetailupdate.handleChange
                                      }
                                      required
                                      className="mt-1 block w-60 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    >
                                      <option value="Accommodation">
                                     Accommodation
                                      </option>
                                      <option value="Non-Accommodation">
                                        Non-Accommodation
                                      </option>
                                    </select>
                                  </dd>
                                </div>
  
                                <div className="sm:flex sm:px-6 sm:py-5">
                                  <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                    First Line
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                    <div className="sm:col-span-3">
                                      <textarea
                                        id="first_line"
                                        name="first_line"
                                        rows={3}
                                        className="block w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        value={
                                          Observersdetailupdate.values.first_line
                                        }
                                        onChange={
                                          Observersdetailupdate.handleChange
                                        }
                                      />
                                      <span className=" font-extralight text-gray-600 align-middle content-center text-sm">
                                        Maximum character allowed 35
                                      </span>
                                    </div>
                                  </dd>
                                </div>
  
                                <div className="sm:flex sm:px-6 sm:py-5">
                                  <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                    Second Line
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                    <div className="sm:col-span-3">
                                      <textarea
                                        id="second_line"
                                        name="second_line"
                                        value={
                                          Observersdetailupdate.values.second_line
                                        }
                                        onChange={
                                          Observersdetailupdate.handleChange
                                        }
                                        rows={3}
                                        className="block w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                      />
                                      <span className=" font-extralight text-gray-600 align-middle content-center text-sm">
                                        Maximum character allowed 42
                                      </span>
                                    </div>
                                  </dd>
                                </div>
  
                                <div className="sm:flex flex justify-end sm:px-6 sm:py-5">
                                  <button
                                    type="submit"
                                    className="group relative flex w-24 justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                  >
                                    Update
                                  </button>
                                </div>
                              </form> */}
                            </dl>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  };
  

export default LeadsProfile