import React, { useRef } from 'react'
import { useEffect,useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useParams} from "react-router-dom";
import Papa from "papaparse";
import Skeleton from "react-loading-skeleton";
import { Toaster, toast } from "sonner";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import PrivateImage from '../../PrivateImage/PrivateImage';

const RepresentativeBadge=()=>{
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
    const { eventID } = useParams();
    const [openEvent, setOpenEvent] = useState(false);
    const [eventinvitation, setEventInvitation] = useState(false);
    const [open, setOpen] = useState(false);
    const [isDeleteID, setDeleteID] = useState(null);
    const [isDelete, setDelete] = useState(false);
    const [UpdatePayment, setUpdatePayment] = useState(false);
    const [invitation, setInvitation] = useState(null);
    const [invitationCount, setInvitationCount] = useState(0);
    const [loadingSkeleton, setLoadingSkeleton] = useState(false);
    const [isNotFoundName, setIsNotFoundName] = useState(false);
    const [uploadFileResponse, setUploadFileResponse] = useState(null);
    
  const [createEventsImage, setCreateEventsImage] = useState(false);
    const [loaderFlightFile, setLoaderFlightFile] = useState(false);
     const [FlightFileName, setFlightFileName] = useState(null);

    const [submitImage, setSumbitImage] = useState(false);
    const [inviteFilter, setInviteFilter] = useState(
      `["event","=","${eventID}"]`
    );
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumbers, setpageNumber] = useState([]);
    const tableRef = useRef(null);
    useEffect(() => {
      const geteventdata = async () => {
        setLoadingSkeleton(true)
        const result = await axios
          .get(
            `https://dash.bestdiplomats.org/api/resource/Representative Badges?fields=["*"]`,config
          )
          .then((res) => {
            setInvitationCount(res.data.data.length);
            setpageNumber([]);
            for (
              let i = 1;
              i <= Math.ceil(res.data.data.length / 25);
              i++
            ) {
              setpageNumber((prevState) => [...prevState, i]);
              // console.log(pageNumbers);
            }
            setInvitation(res.data.data);
            setLoadingSkeleton(false)
            if(res.data.data.length==0){
              // toast.error('No Data Found')
              setLoadingSkeleton(true)
              setIsNotFoundName(true);
            }
            // console.log(">>>>> inv",res.data.data)
          })
          .catch((e) => {
            if (e.code == "ERR_NETWORK") {
              
              // toast.error('No Data Found')
              setLoadingSkeleton(true)
              setIsNotFoundName(true);
            }
            console.log("eventinvitation errrrr", e);
          });
      };
      geteventdata();
    }, [inviteFilter, UpdatePayment]);
    const scrollToTop = () => {
      tableRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const handleClick = (number) => {
      setCurrentPage(number);
      scroll();
      scrollToTop()
    };
    const scroll = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const handlePaginationClick = (event) => {
      if (event == "Previous") {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
          scroll();
          scrollToTop()
        }
      } else if (event == "Next") {
        if (currentPage < pageNumbers.length) {
          setCurrentPage(currentPage + 1);
          scroll();
          scrollToTop()
        }
      }
    };
    const handleChangepaymentPackage=()=>{
      setOpen(true)
      
    }
    const BadgeUpdate = useFormik({
      initialValues: {
        badge_type: eventinvitation?.badge_type ,
        badge_name: eventinvitation?.badge_name,
        badge_description: eventinvitation?.badge_description,
        required_points: eventinvitation?.required_points,
        badge_icon: eventinvitation?.badge_icon,
   
      },
      onSubmit: (values) => {
        var paymentPackeg = {
          badge_name: values.badge_name,
          badge_description: values?.badge_description,
          required_points: values.required_points,
          badge_type: values.badge_type,
          badge_icon: uploadFileResponse,
         
        }
        const fetchcategory = async () => {
          // console.log("paymentPackeg");
          try {
            await axios
              .put(
                `${window.$domain}/api/resource/Representative Badges/${eventinvitation?.name}`,
                paymentPackeg,
                config
              )
              .then((res) => {
                // console.log("updatae",res);
                toast.success('Updated successfully')
                setUpdatePayment(!UpdatePayment)
              });
          } catch (e) {
            console.log(e);
            toast.error('Failed to Update')
          }
        };
  
        fetchcategory();
      },
  
      enableReinitialize: true,
    });
    const handleDeleteInvitation=(person)=>{
      setDelete(true);
      setDeleteID(person);
    }
      // Formik for delete
  const InvitationDelete = useFormik({
    initialValues: {},
    onSubmit: async () => {
      try {
        const result = await axios.delete(
          `${window.$domain}/api/resource/Representative Badges/${isDeleteID}`,
          config
        );
        // console.log(result);
        toast.success("Deleted successfully");
        setUpdatePayment(!UpdatePayment)
      } catch (e) {
        console.error(e);
        toast.error("Failed to delete");
      }
    },
    enableReinitialize: true,
  });

  // Uplaods files functions
  function uploadFlightDocument(e) {
    // setFlightDocument(e.target.files[0].name);
    const fileFlight = e.target.files[0];
    const formData = new FormData();
    formData.append("file", fileFlight);
    formData.append("is_private", 1);

    const uploadFlightApi = async () => {
      try {
        setLoaderFlightFile(true);
        setSumbitImage(true);
        await axios
          .post(
            "https://dash.bestdiplomats.org/api/method/upload_file",
            formData,
            config
          )
          .then(async (responseFlight) => {
            // console.log(responseFlight.data);
            setFlightFileName(responseFlight.data.message.file_name)
            await setUploadFileResponse(responseFlight.data.message.file_url);
            setCreateEventsImage(true);
            setSumbitImage(false);
            // setTimeout(() => {

            //   setCreateEventsImage(false);
            // }, 1000);
          });
        setLoaderFlightFile(false);
      } catch (e) {
        console.log(e);
      }
    };

    uploadFlightApi();
  }


    //   create events
    const createEvents = useFormik({
      initialValues: {
        badge_name: "",
        badge_description: "",
        required_points: "",
        badge_type: "",
      },
      onSubmit: (values) => {
        var PassJson = {
          badge_name: values.badge_name,
          badge_description: values.badge_description,
          required_points: values.required_points,
          badge_type: values.badge_type,
           badge_icon: uploadFileResponse,
        };
  
        const createEvents = async (PassJson) => {
          // console.log(PassJson);
          try {
            await axios
              .post(`${window.$domain}/api/resource/Representative Badges`, PassJson, config)
              .then((res) => {
                // console.log("create events", res);
                setUpdatePayment(!UpdatePayment)
                toast.success("Event created successfully");
              });
          } catch (e) {
            console.log(e);
            toast.error("Creation events failed");
          }
        };
  
        createEvents(PassJson);
      },
  
      enableReinitialize: true,
    });
   
  
  return (
  
  <>
  
    <div className="px-2 divide-y mb-3  sm:px-1 lg:px-4">
      <div className="mt-3">
        <div className="mt-4 flex justify-end items-center  sm:mt-0 sm:ml-1 sm:flex-none">
        <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              onClick={() => {
                setOpenEvent(true);
                setUploadFileResponse("");
                setFlightFileName(null)
              }}
            >
              Create Badge
            </button>
        </div>
      </div>
      <div className="mt-1 py-1">
      <p className="felx justify-end text-right font-semibold">
      {loadingSkeleton ? <Skeleton width={40} height={10} /> : <>Badges: {invitationCount}</>}
        </p>
      </div>
      <div className="mt-1 flex flex-col max-h-screen">
        <div ref={tableRef} className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-4 lg:px-4">
            
            { isNotFoundName? <div className="h-screen flex justify-center flex-col items-center ">
                  <svg fill="none" className="w-40 h-40 text-gray-400" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
               <path strokeLinecap="round" strokeLinejoin="round"d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
           </svg> <span className=" text-lg text-gray-400">  No Data Found</span> 
                </div>: <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">  <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-stone-100">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-4"
                    >
                      Name 
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Badge Type
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                  Points
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                {loadingSkeleton?<tbody className="divide-y divide-gray-200 bg-white">
                   
                   <tr >
                     <td className="whitespace-nowrap py-3 pl-4  text-sm sm:pl-6">
                     <div className="flex items-center">
                          <div
                            className="h-14 w-14 flex-shrink-0"
                           
                          >
                              <Skeleton borderRadius={40} width={50}  height={50}/>
                          </div>
                          <div className="ml-2">
                            <div
                            
                              className="font-medium cursor-pointer text-gray-900"
                            >
                               <Skeleton  width={90}  height={15}/>
                            </div>
                            {/* <div className="text-gray-500">
                            <Skeleton  width={80}  height={10}/>
                            </div> */}
                          </div>
                        </div>
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                     <Skeleton  width={50}  height={15}/>
                     </td>
                     <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       <div className="text-gray-900">
                       <Skeleton />
                       </div>
                     </td>
                    
                     {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                       <div className="text-gray-900">
                       <Skeleton />
                       </div>
                     </td> */}
                     
                   </tr>
                 
               </tbody>: <tbody className="divide-y divide-gray-200 bg-white">
                  {invitation?.slice((currentPage - 1) * 25, currentPage * 25).map((person) => (
                    <tr key={person.email}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-4">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                           <PrivateImage imageUrl={person.badge_icon} className={"h-10 w-10 rounded-full"} />
                            
                          </div>
                          <div className="">
                            <div className="font-medium ml-2 text-gray-900">
                              {person.badge_name}
                            </div>
                         
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900">
                          
                        {person.badge_type=="Silver"? <span className= "inline-flex rounded-full bg-slate-400 px-2 text-xs font-semibold leading-5 text-white"
                             
                        >
                          {person.badge_type}
                        </span>:person.badge_type=="Gold"? <span className= "inline-flex rounded-full bg-yellow-400 px-2 text-xs font-semibold leading-5 text-gray-700"
                             
                             >
                               {person.badge_type}
                             </span>:<span className= "inline-flex rounded-full bg-amber-700 px-2 text-xs font-semibold leading-5 text-white"
                             
                             >
                               {person.badge_type}
                             </span>}
                        </div>
                     
                      </td>
                     
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900">
                          
                          <span className= "inline-flex rounded-full banner-back px-2 text-xs font-semibold leading-5 text-white"
                             
                        >
                          {person.required_points}
                        </span>
                        </div>
                     
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <div className="flex justify-end gap-2">
                                
                                <PencilIcon className="h-6 w-6 flex justify-center items-center text-gray-500 cursor-pointer" onClick={() =>{
                                    handleChangepaymentPackage(person)
                                    setEventInvitation(person)
                                    setUploadFileResponse(person.badge_icon)
                                }}/>
                                  
                                 

                                <TrashIcon className="h-6 w-6 flex justify-center items-center text-red-500 cursor-pointer" 
                                onClick={()=>handleDeleteInvitation(person.name)}
                                />
                                  </div>
                      </td>
                    </tr>
                  ))}
                </tbody>}
              </table>  </div>}
          
          </div>
        </div>
      </div>
    </div>

   {isNotFoundName? null: <div className="flex items-center justify-between mt-7 bg-white px-4 py-3 sm:px-6  rounded-lg shadow">
            <div className="flex flex-1 justify-between sm:hidden">
              <button
                onClick={() => {
                  handlePaginationClick("Previous");
                }}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Previous
              </button>
              <button
                onClick={() => {
                  handlePaginationClick("Next");
                }}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">
                    {(currentPage - 1) * 25 + 1}
                  </span>{" "}
                  to <span className="font-medium">{currentPage * (invitationCount % 25)}</span> of{" "}
                  <span className="font-medium">{invitationCount}</span> results
                </p>
              </div>
              <div>
                <nav
                  className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <button
                    onClick={() => {
                      handlePaginationClick("Previous");
                    }}
                    className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                  {pageNumbers?.map((number) => {
                    return (
                      <button
                        key={number}
                        className={
                          currentPage == number
                            ? "relative z-10 inline-flex items-center border border-purple-500 bg-purple-50 px-4 py-2 text-sm font-medium text-purple-600 focus:z-20"
                            : "relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex"
                        }
                        onClick={() => handleClick(number)}
                      >
                        {number}
                      </button>
                    );
                  })}

                  <button
                    onClick={() => {
                      handlePaginationClick("Next");
                    }}
                    className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </nav>
              </div>
            </div>
          </div>}

 {/* creata events slider */}
 <Transition.Root show={openEvent} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpenEvent}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Badge
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                              onClick={() => setOpenEvent(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      <div className="divide-y items-center divide-gray-200">
                        <div className="pb-6">
                          <div className="h-24 bg-indigo-500 sm:h-10 lg:h-20" />
                          <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                            <div>
                              <div className="-m-1 flex">
                                {uploadFileResponse ==
                                  null ||
                                uploadFileResponse ==
                                  "" ? null : (
                                  <div className="inline-flex overflow-hidden rounded-lg border-4 border-white">
                                  <PrivateImage className={ "h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"} imageUrl={uploadFileResponse}/>
                                   
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="mt-3 sm:ml-6 sm:flex-1">
                              <div>
                                <div className="">
                                  <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                    {/* {eventsDetails?.title} */}
                                  </h3>
                                  <span className=""></span>
                                </div>
                                <p className="text-sm text-gray-500">{}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <form
                          onSubmit={createEvents.handleSubmit}
                          className="flex  flex-col divide-y divide-gray-200 bg-white shadow-xl"
                        >
                          <div className="">
                            <div className="space-y-10 px-4">
                              <div className="border-b border-gray-900/10 pb-2 ">
                               

                                <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-7">
                                 

                                  <div className=" flex flex-col gap-4 sm:col-span-7">
                                    
                                    <div>
                                      {" "}
                                      <span className="">Main Picture</span>
                                      <div className="w-20 pt-2">
                                        {submitImage ? (
                                          <button
                                            disabled
                                            type="button"
                                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                                          >
                                            <svg
                                              aria-hidden="true"
                                              role="status"
                                              class="inline w-4 h-4 mr-3 text-white animate-spin"
                                              viewBox="0 0 100 101"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"
                                              />
                                              <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                            Loading...
                                          </button>
                                        ) : (
                                          <>
                                            {" "}
                                            <label
                                              htmlFor="formFileSm"
                                              className="flex items-center justify-center  h-10  rounded-md border border-blue-300 bg-blue-500 px-2 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                            >
                                              Attach
                                            </label>
                                            <input
                                              accept="image/x-png,image/gif,image/jpg,image/jpeg"
                                              className=" sr-only "
                                              id="formFileSm"
                                              type="file"
                                              onClick={createEvents.resetForm}
                                              onChange={(e) => {
                                                uploadFlightDocument(e);
                                              }}
                                            ></input>
                                          </>
                                        )}
                                      </div>
                                      {FlightFileName==""||FlightFileName==null||FlightFileName==undefined? null:<><div className="flex text-green-600 items-center gap-2 pl-2"><p>{FlightFileName}</p><XMarkIcon onClick={()=>{setUploadFileResponse(null)
                                      setFlightFileName(null)}}
                                className="h-6 cursor-pointer text-red-500 w-6"
                                aria-hidden="true"
                              /> </div></> } 
                                    </div>
                                  </div>
                                

                                 
                                </div>
                              </div>

                              <div className="border-b border-gray-900/10 pb-2">
                                

                                <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                  <div className="sm:col-span-3">
                               
                                    <label
                                      htmlFor="badge_name"
                                      className="block text-sm font-medium text leading-6 text-gray-900"
                                    >
                                      Badge Name
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <div className="mt-2">
                                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input
                                          type="text"
                                          name="badge_name"
                                          id="badge_name"
                                          onChange={createEvents.handleChange}
                                          value={createEvents.values.badge_name}
                                          autoComplete="badge_name"
                                          className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="badge_type"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Status
                                    </label>
                                    <div className="mt-2">
                                      <select
                                        id="badge_type"
                                        name="badge_type"
                                        onChange={createEvents.handleChange}
                                        value={createEvents.values.badge_type}
                                        required
                                        className="block w-72 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                      >
                                        <option >Select</option>
                                        <option value="Gold">Gold</option>
                                       <option value="Bronze">Bronze</option>
                                       <option value="Silver">Silver</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="badge_description"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Badge Description
                                    </label>
                                    <div className="mt-1">
                                    <textarea
                                        id="badge_description"
                                        type='text'
                                        name="badge_description"
                                        onChange={createEvents.handleChange}
                                        value={createEvents.values.badge_description}
                                        rows={4}
                                        className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                                        defaultValue={""}
                                      />
                                    </div>
                                    <p className="mt-2 text-sm leading-6 text-gray-600">
                                      Write a few sentences about badge description.
                                    </p>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="required_points"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                     Required Points
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        id="required_points"
                                        name="required_points"
                                        type="number"
                                        min="1" max="30"
                                        onChange={createEvents.handleChange}
                                        value={createEvents.values.required_points }
                                        autoComplete=""
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                 
                                </div>
                              </div>

                            
                            </div>
                          </div>
                          <div className="flex flex-shrink-0 justify-end px-4 py-4">
                            <button
                              type="button"
                              className="rounded-md w-32 bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={() => setOpenEvent(false)}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="ml-4 w-32 inline-flex justify-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            >
                              Post
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
{/* Update Badge */}
<Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Badge 
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      <div className="divide-y items-center divide-gray-200">
                        <div className="pb-6">
                          <div className="h-24 bg-indigo-500 sm:h-10 lg:h-20" />
                          <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                            <div>
                              <div className="-m-1 flex">
                                <div className="inline-flex overflow-hidden rounded-lg border-4 border-white">
                               
                               
                                    { createEventsImage? <PrivateImage imageUrl={ uploadFileResponse} className={"h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"}/> :<PrivateImage imageUrl={ eventinvitation?.badge_icon} className={"h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"}/> 
                                        
                                    }
                                </div>
                              </div>
                            </div>
                            <div className="mt-3 sm:ml-6 sm:flex-1">
                              <div>
                                <div className="">
                                  <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                    {/* {eventsDetails?.title} */}
                                  </h3>
                                  <span className=""></span>
                                </div>
                                <p className="text-sm text-gray-500">{}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <form
                          onSubmit={BadgeUpdate.handleSubmit}
                          className="flex  flex-col divide-y divide-gray-200 bg-white shadow-xl"
                        >
                          <div className="">
                            <div className="space-y-10 px-4">
                              <div className="border-b border-gray-900/10 pb-2 ">
                               

                                <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-7">
                                 

                                  <div className=" flex flex-col gap-4 sm:col-span-7">
                                    
                                    <div>
                                      {" "}
                                      <span className="">Main Picture</span>
                                      <div className="w-20 pt-2">
                                        {submitImage ? (
                                          <button
                                            disabled
                                            type="button"
                                            className="text-white bg-blue-500 hover:bg-blue-500 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-500 dark:hover:bg-blue-500 dark:focus:ring-blue-500 inline-flex items-center"
                                          >
                                            <svg
                                              aria-hidden="true"
                                              role="status"
                                              className="inline w-4 h-4  text-white animate-spin"
                                              viewBox="0 0 100 101"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"
                                              />
                                              <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                        
                                          </button>
                                        ) : (
                                          <>
                                            {" "}
                                            <label
                                              htmlFor="formFileSm"
                                              className="flex items-center justify-center  h-10  rounded-md border border-blue-300 bg-blue-500 px-2 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                            >
                                              Attach
                                            </label>
                                            <input
                                              accept="image/x-png,image/gif,image/jpg,image/jpeg"
                                              className="sr-only "
                                              id="formFileSm"
                                              type="file"
                                             
                                              onChange={(e) => {
                                                uploadFlightDocument(e);
                                              }}
                                            ></input>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                

                                 
                                </div>
                              </div>

                              <div className="border-b border-gray-900/10 pb-2">
                                

                                <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                  <div className="sm:col-span-3">
                               
                                    <label
                                      htmlFor="badge_name"
                                      className="block text-sm font-medium text leading-6 text-gray-900"
                                    >
                                      Badge Name
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <div className="mt-2">
                                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input
                                          type="text"
                                          name="badge_name"
                                          id="badge_name"
                                          onChange={BadgeUpdate.handleChange}
                                          value={BadgeUpdate.values.badge_name}
                                          autoComplete="badge_name"
                                          className="block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="badge_type"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Status
                                    </label>
                                    <div className="mt-2">
                                      <select
                                        id="badge_type"
                                        name="badge_type"
                                        onChange={BadgeUpdate.handleChange}
                                        value={BadgeUpdate.values.badge_type}
                                        required
                                        className="block w-72 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                      >
                                        <option >Select</option>
                                        <option value="Gold">Gold</option>
                                       <option value="Bronze">Bronze</option>
                                       <option value="Silver">Silver</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="badge_description"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Badge Description
                                    </label>
                                    <div className="mt-1">
                                      <textarea
                                        id="badge_description"
                                        name="badge_description"
                                        onChange={BadgeUpdate.handleChange}
                                        value={BadgeUpdate.values.badge_description}
                                       row={4}
                                        className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                                       
                                      />
                                    </div>
                                    <p className="mt-2 text-sm leading-6 text-gray-600">
                                      Write a few sentences about badge description.
                                    </p>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="required_points"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                     Required Points
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        id="required_points"
                                        name="required_points"
                                        type="number"
                                        min="1" max="30"
                                        onChange={BadgeUpdate.handleChange}
                                        value={BadgeUpdate.values.required_points }
                                        autoComplete=""
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                 
                                </div>
                              </div>

                            
                            </div>
                          </div>
                          <div className="flex flex-shrink-0 justify-end px-4 py-4">
                            <button
                              type="button"
                              className="rounded-md w-32 bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={() => setOpenEvent(false)}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="ml-4 w-32 inline-flex justify-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            >
                              Update
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

        
      {/* Delete Popup */}
       <Transition.Root show={isDelete} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setDelete}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                        Are you sure want to DELETE?
                        </p>
                      </div>
                    </div>
                  </div>
                  <form
                    onSubmit={InvitationDelete.handleSubmit}
                    className="mt-5 sm:mt-6"
                  >
                    <button
                      type="submit"
                      className="inline-flex w-full text-lg justify-center rounded-md bg-red-400 px-3 py-2 font-semibold text-white  hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                      onClick={() => setDelete(false)}
                    >
                      Delete
                    </button>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
   </>
  )
}



export default RepresentativeBadge