import { doc, collection, getDocs, deleteDoc, updateDoc } from 'firebase/firestore';
import { Toaster, toast } from "sonner";
<Toaster position="bottom-left" richColors />

export const DeleteAllMessage = async (subcollectionRef) => {
    try {
      const snapshot = await getDocs(subcollectionRef);
      snapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
      toast.success("All messages deleted successfully");
   
    } catch (error) {
        toast.error('Error deleting messages:', error);
    }
  };

  export const OneSMSDelete = async (subcollectionRef, smsId) => {
    try {
      const smsDocRef = doc(subcollectionRef, smsId);
      // console.log(">>>>>>>>",smsDocRef);
       await updateDoc(smsDocRef, {
        is_deleted: true
       });
     
        toast.success('Message deleted successfully');
     } catch (error) {
         toast.error('Error deleting message:', error);
     }
   };
//  export const OneSMSDelete = async (subcollectionRef, smsId) => {
//    try {
//      const smsDocRef = doc(subcollectionRef, smsId);
//     //  console.log(">>>>>>>>",smsDocRef);
//       await deleteDoc(smsDocRef);
    
//        toast.success('Message deleted successfully');
//     } catch (error) {
//         toast.error('Error deleting message:', error);
//     }
//   };


